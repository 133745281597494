import {extendTheme} from '@chakra-ui/react'

export const theme = extendTheme({
  components:{
    Button:{
    }
  },
  shadows: {
    outline: '#FF0080 0px 0px 7px 3px'
  },
  fonts: {
    body: 'Poppins, sans-serif',
    heading: "Nunito, sans-serif",
  },
  textStyles: {
    h1: {
      fontSize: ["48px", "72px"],
      fontWeight: "bold",
      lineHeight: "110%",
      letterSpacing: "-2%",
    },
    h3: {
      fontSize: ["36px", "48px"],
      fontWeight: "semibold",
      lineHeight: "110%",
      letterSpacing: "-1%",
    },
    h5: {
      // you can also use responsive styles
      fontSize: ["1.05rem", "1.2rem"],
      fontWeight: "bold",
      lineHeight: "110%",
    },
  },
  styles: {
    global: {
      ".table": {
        borderSpacing: 0,
        border: '1px solid black',
        display: 'inline-block',
        ".tr": {
          ".last-child": {
            ".td": {
              borderBottom: 0
            }
          }
        },
        ".th,.td": {
          margin: 0,
          padding: '0.5rem',
          borderBottom: '1px solid black',
          borderRight: '1px solid black',
          position: 'relative',
          ".resizer": {
            display: "inline-block",
            background: 'blue',
            width: '10px',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            transform: "translateX(50%)",
            zIndex:1,
            touchAction:'none',
            "&.isResizing": {
              background: "red"
            }
          }
        }
      },
      ".markdown-body h1": {
        mt: '0.67em',
        mb: '0.67em',
        fontSize: '2em',
        fontWeight: 'bold'
      },
      ".markdown-body h2": {
        mt: '0.83em',
        mb: '0.83em',
        fontSize: '1.5em',
        fontWeight: 'bold'
      },
      ".markdown-body h3": {
        mt: '1em',
        mb: '1em',
        fontSize: '1.17em',
        fontWeight: 'bold'
      },
      ".markdown-body h4": {
        fontWeight: 'bold'
      },
      ".markdown-body h5": {
        fontSize: '0.83em',
        fontWeight: 'bold'
      },
      ".markdown-body h6": {
        fontSize: '0.67em',
        fontWeight: 'bold'
      },
      ".markdown-body p": {
        mb: '16px'
      },
      // ".markdown-body":{
      //   pb:'.3em',
      //   fontSize:'1.5em',
      //   borderBottom: '1px solid'
      // },
      "ul": {
        pl: '2em',
        mb: '16px'
      },
      "ol": {
        pl: '2em',
        mb: '16px'
      },
      "a": {
        color: 'brand.400',
        fontWeight: 'medium'
      },
      "a:hover": {
        textDecoration: 'underline'
      }
    }
  },
  initialColorMode: "dark",
  // useSystemColorMode: false,
  colors: {
    brand: {
      100: "#D4DBF4",
      200: "#A8B8E9",
      300: "#7D94DE",
      400: "#5171D3",
      500: "#264DC8",
      600: "#2245B4",
      700: "#1E3EA0", // 15,51,164
      800: "#1B368C",
      900: "#172E78",
    },

    brand2: {

      50: "#ffe2ed",
      100: "#ffa8d2",
      200: "#ff84c0",
      300: "#ff6cb5",
      400: "#ff399b",
      500: "#FF0080",
      600: "#d7046e",
      700: "#c50465",
      800: "#a90256",
      900: "#640031",
    }
  }
})
