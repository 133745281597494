import {FC} from "react";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {Box, Center, Flex, Heading, Text, useColorModeValue} from "@chakra-ui/react";

export const MCQStats: FC<{ questionID: string }> = ({questionID}) => {

  const mcqStatsQuery = useQuery(
    ['mcq-stats', questionID],
    QuestionService.getMCQStats(questionID),
    {
      cacheTime: 24 * 60 * 60 * 1000,
      staleTime: 24 * 60 * 60 * 1000,
    })
  const lighterBar = useColorModeValue("brand.300", "brand.600")
  const darkerBar = useColorModeValue("brand.600", "brand.800")
  const borderColor = useColorModeValue('1px solid gray', '1px solid rgb(60,60,100)')
  return (
    <Box
      px={'1rem'}
      pt={'1rem'}
      pb={'1rem'}
      w={'full'}
      borderRadius={'6px'}
      border={borderColor}>
    {/*<Box w={'100%'} mb={'30px'}>*/}
      {
        mcqStatsQuery.data?.TooLittleData === true ?
          <Text textColor={'gray.500'}>This question has not been answered enough times for us to provide meaningful
            statistics.</Text>
          :
          <Box w={'100%'}>
            <Heading fontSize={'lg'}>How did others answer this question?</Heading>

            <Flex w={'100%'} h={'30px'} my={'10px'} alignItems={'stretch'}>
              <Center w={mcqStatsQuery.data?.Ans1Pct + '%'} bg={lighterBar}><Text fontWeight={'600'} textColor={'white'}>A</Text></Center>
              <Center w={mcqStatsQuery.data?.Ans2Pct + '%'} bg={darkerBar}><Text fontWeight={'600'} textColor={'white'}>B</Text></Center>
              <Center w={mcqStatsQuery.data?.Ans3Pct + '%'} bg={lighterBar}><Text fontWeight={'600'} textColor={'white'}>C</Text></Center>
              <Center w={mcqStatsQuery.data?.Ans4Pct + '%'} bg={darkerBar}><Text fontWeight={'600'} textColor={'white'}>D</Text></Center>
            </Flex>
            <Text >Total Attempts: {mcqStatsQuery.data?.Total}</Text>
            <Text fontSize={'sm'}>A: {mcqStatsQuery.data?.Ans1 + " ("+mcqStatsQuery.data?.Ans1Pct + '%)'}</Text>
            <Text fontSize={'sm'}>B: {mcqStatsQuery.data?.Ans2 + " ("+mcqStatsQuery.data?.Ans2Pct + '%)'}</Text>
            <Text fontSize={'sm'}>C: {mcqStatsQuery.data?.Ans3 + " ("+mcqStatsQuery.data?.Ans3Pct + '%)'}</Text>
            <Text fontSize={'sm'}>D: {mcqStatsQuery.data?.Ans4 + " ("+mcqStatsQuery.data?.Ans4Pct + '%)'}</Text>
          </Box>


      }
    </Box>
  )
}