import React, {FC} from "react";

import {Box, chakra, Container, Text} from "@chakra-ui/react";

export const Commendation: FC = () => {

  return (
    <Container maxW={'container.xl'} px={10}>
      <Box py={'80px'}>
        <chakra.span
          fontSize={['lg', '3xl']}
        >"This is way better than just reading notes...
          I have taken the exam 8 times and I own every practice paper out there, and I can say that
          <chakra.span color={'brand2.400'} fontWeight={700}>
            &nbsp;Propella's questions are better&nbsp;
          </chakra.span>
          than those in the market. If only I found you earlier... The
          <chakra.span color={'brand2.400'} fontWeight={700}>
            &nbsp;explanations are easy to understand&nbsp;
          </chakra.span>
          and I like that there's calculations included."
        </chakra.span>

        <Text
          mt={'20px'}
          fontWeight={'600'}
        >Carolyn Wee</Text>

        <Text
          color={'gray.500'}
          fontSize={'sm'}
        >
          Real Estate Agent that passed the CES exam on the 8th try with Propella.</Text>
      </Box>


    </Container>
  )
}

