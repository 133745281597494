import {FC} from "react";
import {
  Box, Heading,
  SimpleGrid,
  Tag,
  useColorModeValue,
  Wrap
} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import {Topic, UserProblemSetAttempt} from "../../Models/Question";
import {MdOutlineLock} from "react-icons/all";
import {ProblemSetAttemptStatusIndicator} from "./ProblemSetAttemptStatusIndicator";
import {useAuth} from "../../Contexts/AuthContext";

export const TopicPickerView: FC<{ topics?: Topic[], attempts: UserProblemSetAttempt[] }> = ({topics, attempts}) => {
  // const {data} = useQuery('sets', QuestionService.getAllSets)
  // const {data} = useQuery('topics', QuestionService.getTopics)
  return (
    <Box
      w={'full'}
      mt={'10px'}>
      <SimpleGrid
        columns={{base:1, md:2}}
        gap={'20px'}
        w={'full'}
      >
        {
          topics &&
          topics.map(topic => <TopicCard key={topic.ID} topic={topic} attempts={attempts}/>)
        }
      </SimpleGrid>
    </Box>

  )
}



const TopicProgress: FC<{ progress: string[][] }> = ({progress}) => {

  return <Wrap mt={'10px'} ml={'1px'}>
    {
      progress.map(state =>
        <ProblemSetAttemptStatusIndicator key={state[1]} state={state[0]} pSetTitle={state[1]}/>
      )
    }
  </Wrap>
}

const TopicCard: FC<{ topic: Topic, attempts?: UserProblemSetAttempt[] }> = ({topic, attempts}) => {
  const hist = useHistory()
  const auth = useAuth()
  return (

    <Box
      h={'150px'}
      px={'30px'}
      py={'30px'}
      w={'full'}
      borderRadius={'xl'}
      boxShadow={useColorModeValue('rgba(38, 77, 200, 0.3) 2px 2px 10px 0px', 'rgba(38, 77, 200, 0.5) 1px 1px 10px 0px')}
      onClick={() => {
        hist.push(`/topic/${topic.ID}`)

      }}
      _hover={{
        cursor: 'pointer',
        transform: "scale(1.04)",
        transition: "0.2s ease-in-out",

      }}
    >
      {/*<HStack>*/}

      {/*<Box>*/}
      <Heading
        fontSize={'xl'}

      >{topic.Name}
      </Heading>
      {
        // if topic has a free problem set, display FREE tag
        topic.ProblemSets?.find(s => s.ForFree) ?

        <Tag size={'sm'} colorScheme={'brand2'}>FREE</Tag> :

          // if
          auth.testAuth(topic.Paper) ?
            <></> :
            <MdOutlineLock/>
      }
      {
        (auth.testAuth(topic.Paper) ||  topic.ProblemSets?.find(s => s.ForFree) )&&
          <TopicProgress progress={topic.ProblemSets!.map(s => {
            let atts = attempts?.filter(a => a.ProblemSetID === s.ID) ?? []
            if (atts.length === 0) {
              return ["not started", s.Title]
            } else if (atts.length > 0 && atts[0].Score === null) {
              return ["ongoing", s.Title]
            } else {
              return ["done", s.Title]
            }

          })}/>
      }


      {/*<Flex  mt={'4px'} alignItems={'center'}>*/}
      {/*  <Icon as={CgGym} mr={'4px'} boxSize={5}/>*/}

      {/*  <Text>{topic.ProblemSets?.length + " sets"}</Text>*/}

      {/*</Flex>*/}

      {/*</Box>*/}


      {/*</HStack>*/}
    </Box>

  )
}