import {FC, useEffect} from "react";
import {Container, Heading} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import useLocalStorage from "../../Hooks/use-local-storage";
import {TransferGapPeriod} from "./ProfilePage";

const newFeatures =
  `
  ### 15 Jun 2024
  - **PropellaBot V2 🤖** - We've upgraded PropellaBot! It is now updated to October 2023 and is significantly more powerful in understanding and answering your questions.
  - **Question Improvements** - We're actively updating our question sets. Some improvements were made last month, and further refinements will continue over the next two weeks.
  ### 3 Mar 2023
  - **AI Knowledge Base 🤖 (Experimental)** - We have added an AI knowledge base to help you with your revision! Warning: This is powered by chatGPT and only great for simple, common questions related to Singapore real estate. The AI does not know about policy changes after Sept 2021. 
  - **Recently Updated Questions** - You can now easily browse questions updated in the past 14 days from the paper's page.
  - **Classic SAQs Re-Introduced** - We have re-introduced the old SAQ problem sets to our papers. These are no longer in the exams, but they are still great for mastering key definitions.
  ### 30 Jan 2023
  - **New Exam Format** - Our full papers have been updated to match the latest exam format for 2023.
  - **Google Sign-In** - You can now sign in with your Google account! Ensure that your Google account has the same email as your propella account before you sign in.
  ### 22 Jan 2023
  - **New Exam Format** - We have updated our dynamic mock tests and removed SAQs to match the latest exam format for 2023. More MCQs and CSQs will be added later.
  - **Filter Saved Questions** - You may now filter your saved questions by paper without any 💎.
  ### 20 Oct 2022
  - **💎 Feature - Filter Saved Questions** - You can now filter saved questions according to their papers.
  ### 20 Sep 2022
  - **Subscription Transfer ♻️** - Passed your exams and have no more use for propella? You can now transfer your remaining subscription! 
  Access this feature from your profile page.
      1. Get the email of the person you wish to transfer your remaining subscription to.
      2. Ensure that this email is a registered account and does not have an active subscription.
      3. Click on the \`transfer\` button and enter the email.
  - Note: A subscription must be at least 3 months old before it is transferable and it
   can only be transferred once every ${TransferGapPeriod} months.
  Lastly, a subscription can only be transferred if it does not expire in less than 5 days.
  ### 16 Aug 2022
  - **Discuss - New Filters** - You can now filter Discuss posts to see only those that you have started, or posts that have yet been answered.
  ### 15 Aug 2022
  - **Discuss - Voting System 🗳️** - You can now upvote good questions/ answers and downvote low effort posts to help moderate content for other learners!
  - **Discuss - Reputation 💎** - Build your reputation by helping everyone with their questions or by asking quality questions. New experimental 
  features will be made available to reputable users first!
  ### 13 Aug 2022
  - **Progress Reset** - You can now start afresh by resetting your progress from your profile page.
  ### 12 Jul 2022
  - **MCQ Stats** - Unsure if our MCQ answers are correct? You can now see how others have answered by opening the stats panel!
  ### 8 Jul 2022
  - **Discuss: Own posts highlighted** - You can now easily find your posts in the Discuss page: posts that you have started will be highlighted.
  - **Display Name for Everyone** - In order to improve the usability of the Discuss feature, users that still have not set a display name will have one generated for them. You may change this to your liking anytime from the profile page.
  ### 7 Jul 2022
  - **Collapsed Page Selector** - Quick clean-up of our Discuss page selector to hide the far-away pages.
  - **Rich Text in Preview** - You can now click on links in explanations in the question preview on the Discuss page.
  ### 7 Mar 2022
  - **Per-Question Score** - You can now see the score breakdown for each question while reviewing your answers.
  ### 17 Feb 2022
  - **Discussion** - Having doubts after doing a question? You can now discuss them with the propella community! We have 
  received many question feedbacks and we always thought it is a waste to not have these discussions in the open. When we discuss openly,
  we can all learn from each other.
    - **Discussion Panel** - Each question (MCQ, SAQ, CSQ) has a discussion panel for question-related queries.
    You can see this after solving a question in a problem set, at the end of a problem set, or in the saved questions page.
    - **Discuss Page** - See all recent questions that other users have posted, contribute your answers, and learn from others.
    Access the page from the top navigation bar after signing in.
    - **Share** - You may share your discussion posts and get more help from your friends! Click on the share button to copy 
    a share link to your clipboard.
    - **Display Name** - Set a display name in your profile page and be recognised for your contributions!
  - **Discussion Rules:**
      1. **Be kind to one another and help one another** - The goal here is to grow a knowledge base. If you abuse the discussion forum,
      your account may be banned and you will not be refunded.
      2. **Ask quality questions** - If your question can be solved with less than a minute of googling, do not ask it here.
  ### 1 Jan 2022
  - **700+ New Questions** - We have added more than 700 new questions to challenge you further!
  - **Progress Refresh** - Since we have added new questions, we had to reshuffle our questions into new problem sets. This
  means that your old progress will no longer be visible.
  - **Full Mock Papers** - We have finally added full mock papers for both paper 1 and 2! These papers are made as close as possible to the 
  real exam and the questions 
  have never been seen elsewhere and will never show up in dynamic problem sets. We suggest you keep them until you are
  ready for the exam and use that as a final practice or gauge.
  ### 5 Dec 2021
  - **Progress Indicator** - Know how much you have done with the new progress indicator! Available for all problem sets.
  - **Dynamic Problem Sets (Mock Tests/ Exams)** - Make it even harder for yourself by doing a randomly generated problem set in test mode!
  Available for MCQs per topic, and for full mock papers.
    - **Timer** - Time yourself and work on your speed! (Timers are only available in exam mode).
    - **Delayed Answers** - Only see your answers at the very end!
    - **Progress Indicator** allows navigation in exam mode.
  ### 7 Nov 2021
  - **Refer a friend** - You can now refer your friends and collect referrals! 
  Get your own referral link or QR code from your profile page. Do note that your referer has
  to create their account with the same browser they opened your link in.
  - **Add To Home Screen** for mobile - Did you know that propella is a progressive web app?
  When you're on a mobile device, there will now be a reminder in the footer
  to add propella to your mobile home screen for a native app experience.
  - **3- and 12-month price plans** - we now have price plans for 3- and 12-month subscriptions.
  Beta testing discount will be reduced after 15 dec 2021. Subscribe now to secure the biggest discount!
  - **Better Case Study View** - Case studies now display in 2 columns on PCs and in 2 stacked scroll boxes on phone screens.
  `

export const LatestUpdateID = "update-15-jun-2024"
export const NewFeaturesPage: FC = () => {
  const [,setViewedLatestUpdates] = useLocalStorage<boolean | null>(LatestUpdateID, false)

  useEffect(() => {
    setViewedLatestUpdates(true)

  }, [])

  return (
    <Container>
      <Heading mt={'10px'}>Feature Updates</Heading>
      <ReactMarkdown className={'markdown-body'} children={newFeatures} remarkPlugins={[remarkGfm]}/>

    </Container>
  )
}