import {AxiosError} from 'axios'
import React, {createContext, FC, useContext, useEffect} from 'react'
import {useMutation, UseMutationResult, useQuery, useQueryClient} from 'react-query'
import {useHistory} from 'react-router-dom'
import {useCookies} from 'react-cookie'

import {UserInfo} from "../Models/Auth";
import useLocalStorage from "../Hooks/use-local-storage";
import {AuthService, LoginParams} from "../Services/AuthService";
import {ApiClient} from "../Services/APIClient";
import {useToast} from "@chakra-ui/react";

interface AuthContextProps {
  user: UserInfo | null
  logout: () => {}
  verifyOtp: UseMutationResult<void, void, LoginParams>
  refetchUserInfo: () => void
  testAuth: (requiredLevel: number) => boolean
  isAdmin: () => boolean
  isCommunityAdmin: () => boolean
  repCheck: (minRep: number) => boolean
  // isAuthenticated: () => boolean
  // loginForm: FC<{ onLogin: () => void }>
  // onLogin: () => void
  // openLoginForm: (onLogin?: () => void) => void
}

const authContext = createContext<AuthContextProps | undefined>(undefined)

export const useAuth = (): AuthContextProps => {
  const auth = useContext(authContext)
  if (!auth) throw new Error('useAuth must be used within an AuthProvider')
  return auth
}

function getCookie(cookieName: String) {
  return !!document.cookie.match(`${cookieName}`)
}

export const AuthProvider: FC = ({children}) => {
  const toast = useToast()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [user, setUser] = useLocalStorage<UserInfo | null>('user', null)
  const [cookies, setCookie, removeCookie] = useCookies(['prop-session-id'])
  const whoamiQuery = useQuery("whoami", AuthService.whoami, {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
    onSuccess: user => {
      setUser(user)
    },
    enabled: !!cookies["prop-session-id"]
  })

  const verifyOtp = useMutation<void, void, LoginParams>
  (AuthService.login, {
    onSuccess: () => whoamiQuery.refetch(),
  })

  const logout = async () => {
    await AuthService.logout()
      .catch(err => console.log(err))
      .then(() => {
        setUser(null)
        queryClient.removeQueries('attempts')
        // history.push("")
      })
  }

  const refetchUserInfo = () => {
    whoamiQuery.refetch()
  }

  const testAuth = (requiredAuth: number) => {
    if (user?.SubscriptionInfo?.Perm === undefined) {
      return false
    }
    if (requiredAuth === 1 && (user.SubscriptionInfo.Perm === 1 || user.SubscriptionInfo.Perm === 3)) {
      return true
    } else if (requiredAuth === 2 && (user.SubscriptionInfo.Perm === 2 || user.SubscriptionInfo.Perm === 3)) {
      return true
    } else if (requiredAuth === 3 && user.SubscriptionInfo.Perm === 3) {
      return true
    } else if (requiredAuth === 0) {
      return true
    }
    return false
  }

  const isAdmin = () => {
    return user?.TenantAdmin?.find(x => x.TenantID === 'propella') !== undefined
  }

  const isCommunityAdmin = () => {
    return user?.TenantAdmin?.find(x => x.TenantID === 'community') !== undefined
  }

  const repCheck = (minRep: number) => {
    return (user?.Rep ?? 0) >= minRep
  }

  const auth = {
    logout,
    user,
    verifyOtp,
    refetchUserInfo,
    testAuth,
    isAdmin,
    isCommunityAdmin,
    repCheck
  }

  const initialize = () => {
    // Setup axios interceptor to redirect to login on 401
    ApiClient.interceptors.response.use(
      (response) => {
        return response
      },
      async (err: AxiosError) => {
        if (!!user && err.response?.status === 401 &&
          !err.request.responseURL.includes('/api/logout') &&
          !err.request.responseURL.includes('/api/questions')
        ) {
          await logout()
          history.push('/login')
        } else if (err.response?.status === 429) {
          toast({
            position: "top",
            title: "SLOW DOWN!",
            description: "You are moving too fast! We have rate limiters in place to protect the experience of our users." +
              ` Wait a minute and try again! (rate exceeded on: ${err.response?.config.url})`,
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        } else if (err.response?.status === 400) {
          toast({
            position: "top",
            title: "Oops!",
            description: `${err.response.data.Error}`,
            status: "warning",
            duration: 5000,
            isClosable: true,
          })
        } else if (!!user && err.response?.status === 409) {
          toast({
            position: "top",
            title: "Logged in from a different browser.",
            description: `You have recently signed in on a different browser. You may switch browsers once every 5 minutes. Try logging out from the other browser and logging in again.`,
            status: "warning",
            duration: 5000,
            isClosable: true,
          })
          if (!!user) {
            logout().then(()=>{history.push("/")})

          }

        }

        return Promise.reject(err)
      }
    )

    // // Attempt to fetch user object and set isAuthenticated to true if succeeds
    // if (getCookie('prop-session-id')) {
    //   whoamiQuery.refetch()
    //
    // }
  }

  useEffect(initialize, [])

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
