import React, {FC, useLayoutEffect} from 'react';
import './App.css';
import {theme} from "./Theme/theme";
import {ChakraProvider} from '@chakra-ui/react';
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter, useLocation} from "react-router-dom"
import {ReactQueryDevtools} from 'react-query/devtools'
import {Navbar} from "./NavBar";
import {AuthProvider} from "./Contexts/AuthContext";
import {Footer} from "./Footer";
import {MainBox} from "./MainBox";

const queryClient = new QueryClient({
  defaultOptions: {queries: {retry: false}},
})

const App: FC = () => {

  // useEffect(() => {
  //   // Initialize deferredPrompt for use later to show browser install prompt.
  //   let deferredPrompt;
  //
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     // Prevent the mini-infobar from appearing on mobile
  //     e.preventDefault();
  //     // Stash the event so it can be triggered later.
  //     deferredPrompt = e;
  //     // Update UI notify the user they can install the PWA
  //     // @ts-ignore
  //     e.prompt();
  //
  //     // Optionally, send analytics event that PWA install promo was shown.
  //     console.log(`'beforeinstallprompt' event was fired.`);
  //   });
  // },[])
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>

        <AuthProvider>

            <ScrollToTop/>
            <Navbar/>
            <MainBox/>
            <Footer/>

            <ReactQueryDevtools initialIsOpen={false}/>
        </AuthProvider>
        </BrowserRouter>

      </QueryClientProvider>
    </ChakraProvider>

  );
}

function ScrollToTop() {
  const { pathname,  } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
