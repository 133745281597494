import React, {FC} from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  chakra,
  Text,
  Image,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";


export const QnStats: FC<{}> = ({}) => {
  // const bgGrad = useColorModeValue("linear(180deg,rgba(255, 226, 237, 1),rgba(15, 40, 232, 0))"
  //   ,"linear(180deg,rgba(100, 0, 49, 1),rgba(15, 40, 232, 0))")
  const lighterBar = useColorModeValue("brand.300", "brand.600")
  const darkerBar = useColorModeValue("brand.600", "brand.800")
  return (
    <Box
      pt={'80px'}
      pb={'80px'}
      // bg={useColorModeValue('gray.100', 'gray.800')}
      // bgGradient={bgGrad}

    >

      <Container maxW={'container.xl'} px={10}>
        <Heading
          textAlign={'center'}
          fontSize={'4xl'}
          mb={'20px'}
        >
          Data-Driven Learning
        </Heading>
        <Center>
          <Text fontSize={'sm'} textAlign={'center'} mb={'30px'}>
            With
            <chakra.span fontWeight={700} color={'brand.400'}>
              &nbsp;Question Statistics
            </chakra.span>, take a glimpse at how others answered the question.
          </Text>
        </Center>
        <Flex alignItems={['center', 'center', 'center',  'center']} gap={'20px'}
              flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'row']}>
          <Box w={['100%', '100%', '100%', '50%']}>
            {/*<Box*/}
            {/*  my={'30px'}*/}
            {/*  p={'30px'}*/}
            {/*  borderRadius={'xl'}*/}
            {/*  boxShadow={'rgba(79, 114, 205, 0.3) 0px 4px 42px 0px'}*/}
            {/*>*/}
            <Heading fontSize={'lg'}>How did others answer this question?</Heading>

            <Flex w={'100%'} h={'30px'} my={'10px'} alignItems={'stretch'}>
              <Center w={'48.33%'} bg={lighterBar}><Text fontWeight={'600'} textColor={'white'}>A</Text></Center>
              <Center w={'15.38%'} bg={darkerBar}><Text fontWeight={'600'} textColor={'white'}>B</Text></Center>
              <Center w={'28.91%'} bg={lighterBar}><Text fontWeight={'600'} textColor={'white'}>C</Text></Center>
              <Center w={'7.38%'} bg={darkerBar}><Text fontWeight={'600'} textColor={'white'}>D</Text></Center>
            </Flex>
            <Text>Total Attempts: 1287</Text>
            <Text fontSize={'sm'}>A: 622 (48.33%)</Text>
            <Text fontSize={'sm'}>B: 198 (15.38%)</Text>
            <Text fontSize={'sm'}>C: 372 (28.91%)</Text>
            <Text fontSize={'sm'}>D: 95 (7.38%)</Text>

          {/*</Box>*/}
          </Box>

          <Box w={['100%', '100%', '100%', '50%']} p={'15px'}>
            <Text pb={'20px'}>
              After answering each MCQ, you can pop open the Question Statistics panel and see how others answered the
              question.
              This gives you an insight into the collective knowledge of your peers, and helps you gain confidence with
              your answer.
            </Text>
            <Text pb={'20px'}>
              If your answer differs from the model answer, perhaps seeing how others have answered can help you realise where you went wrong!
            </Text>

          </Box>

        </Flex>


      </Container>
    </Box>
  )
}