import {FC} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {
  Button,
  Container,
  Center,
  Flex,
  Heading,
  Text,
  VStack, Table, TableCaption, Thead, Tr, Th, Tbody, Td, Spinner
} from "@chakra-ui/react";
import {UserProblemSetAttempt} from "../../Models/Question";
import {TopicProbSetAttemptHeader} from "./TopicProbSetAttemptHeader";

var dayjs = require('dayjs')
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export const ProblemSetInfoView: FC<{}> = ({}) => {
  // @ts-ignore
  const {setID} = useParams()

  const probSetQuery = useQuery(["set", setID], QuestionService.getSetInfo(setID))
  const topicQuery = useQuery(["set", setID, "topic"],
    QuestionService.getTopicInfo(probSetQuery.data?.ProblemSet.TopicID!),
    {
      enabled: !!probSetQuery.data?.ProblemSet.TopicID,
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 30,
    })


  return (
    <Container maxW={'container.lg'} pt={'20px'}>
      {
        (probSetQuery.isFetched) ?
          probSetQuery.isError ?
            <Text>Sign Up or Login first. We only need your email.</Text>
            :
            <>
              <TopicProbSetAttemptHeader
                topic={topicQuery.data?.Name ? topicQuery.data : undefined}
                pSetWAtt={probSetQuery.data}/>

              <Heading mt={'40px'}>Attempts</Heading>

              <ProblemSetAttemptsView attempts={probSetQuery.data?.Attempts}
                                      maxScore={probSetQuery.data?.ProblemSet.MaxScore ?? 0}/>
            </>
          : <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>

      }
      {

      }

    </Container>)
}

const ProblemSetAttemptsView: FC<{
  attempts?: UserProblemSetAttempt[],
  maxScore: number
}> = ({attempts, maxScore}) => {
  let hist = useHistory()
  const completedAttempts = attempts?.filter(att => att.Score !== null) ?? []
  return (
    <Table variant={'simple'}>
      {
        completedAttempts.length === 0 &&
        <TableCaption>You have not attempted this problem set</TableCaption>

      }
      <Thead>
        <Tr>
          <Th>Attempt</Th>
          <Th isNumeric={true}>Score</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          attempts?.filter(att => att.Score !== null).map((att, i) => {

            const dateDisplay = dayjs(att.CreatedAt).format('DD MMM YY')
            return (
              <Tr>
                <Td>
                  {/*<Tag*/}
                  {/*  colorScheme={att.DelayMarking ? 'brand' : 'brand2'}*/}
                  {/*  variant={'outline'}>*/}
                  {/*  {att.DelayMarking ? "Test" : "Practice"}*/}
                  {/*</Tag>*/}
                  <Text>{`attempt ${i + 1}`}</Text>
                  <Text>{dateDisplay}</Text>
                </Td>
                <Td>
                  <Flex flexDirection={'row-reverse'}>
                    <VStack alignItems={'flex-end'}>
                      <Text>{att.Score + "/" + maxScore}</Text>
                      <Button
                        size={'xs'}
                        onClick={() => {
                          hist.push(`/set/${att.ProblemSetID}/review?attemptID=${att.ID}`)
                        }}>Review</Button>
                    </VStack>
                  </Flex>

                </Td>
              </Tr>


            )
          })

        }
      </Tbody>
    </Table>
  )
}

