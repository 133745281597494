import React, {FC, useState} from "react";
import {useQuery} from "react-query";
import {ChatService} from "../../Services/ChatService";
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  Tooltip,
  chakra,
  useBreakpointValue,
  useColorModeValue, useToast
} from "@chakra-ui/react";
import {AxiosError} from "axios";
import {PageControl} from "../PageControl";
import {DiscussionPageListItem} from "../Discuss/DiscussionPageListItem";
import {useAuth} from "../../Contexts/AuthContext";
import {NoticeBanner, UpdateBanner} from "../../Banner";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {useHistory} from "react-router-dom";

const onlyOwnMsgsKey = "oom"
const onlyMsgsWithNoRepsKey = "nr"

export const DiscussionPage: FC = () => {
  const [page, setPage] = useState(1)
  const auth = useAuth()

  const urlQuery = useURLQuery()
  const [onlyOwnMsgs, setOnlyOwnMsgs] = useState(urlQuery.get(onlyOwnMsgsKey) === "true" ?? false)
  const [onlyMsgsWithNoReps, setOnlyMsgsWithNoReps] = useState(urlQuery.get(onlyMsgsWithNoRepsKey) === "true" ?? false)
  const latestRootMsgsQuery = useQuery(['latest-root-msgs', page, onlyOwnMsgs, onlyMsgsWithNoReps], ChatService.getLatestMessages(page, onlyOwnMsgs, onlyMsgsWithNoReps),
    {
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      onError: (err: AxiosError) => {
        console.log(err)
      }
    }
  )
  const isMobile = useBreakpointValue({base: true, sm: false, md: false})
  const hist = useHistory()
  const toast = useToast()
  const borderColor = useColorModeValue('1px solid gray', '1px solid rgb(60,60,100)')
  return (
    <>
      <UpdateBanner/>
      <NoticeBanner/>

      <Container maxW={'container.lg'} p={{base: '6px', sm: '20px'}}>
        <Heading pt={'20px'} size={'3xl'}>Discuss</Heading>
        <HStack px={'6px'}>
          <Tooltip label={"Only show posts started by you. Min rep required: 3💎"}>
            <Button
              variant={onlyOwnMsgs ? 'solid' : 'outline'}
              colorScheme={'brand'}
              isActive={onlyOwnMsgs}
              size={'sm'}
              onClick={() => {
                if (auth.repCheck(3)) {
                  urlQuery.set(onlyOwnMsgsKey, String(!onlyOwnMsgs))
                  hist.push({
                    pathname: hist.location.pathname,
                    search: urlQuery.toString(),
                  })
                  setPage(1)
                  setOnlyOwnMsgs(!onlyOwnMsgs)
                } else {
                  toast({
                    id: "rep-feature",
                    position: "top-right",
                    title: "Privileged 💎 Feature",
                    description: <Text>This is only available to users with&nbsp;
                      <chakra.span fontWeight={'bold'}>3</chakra.span>
                      or more 💎.
                      Your current rep is <chakra.span fontWeight={'bold'}>{auth.user?.Rep ?? 0}💎</chakra.span>. You get
                      💎 when your posts or replies are upvoted by others. Start helping others and gain rep!</Text>,
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                  })
                }

              }
              }
            >💎 My Posts</Button>
          </Tooltip>
          <Tooltip label={"Only show posts that have zero replies"}>

            <Button
              variant={onlyMsgsWithNoReps ? 'solid' : 'outline'}
              colorScheme={'brand'}
              size={'sm'}
              isActive={onlyMsgsWithNoReps}
              onClick={() => {
                urlQuery.set(onlyMsgsWithNoRepsKey, String(!onlyMsgsWithNoReps))
                hist.push({
                  pathname: hist.location.pathname,
                  search: urlQuery.toString(),
                })
                setPage(1)
                setOnlyMsgsWithNoReps(!onlyMsgsWithNoReps)
              }
              }
            >Unanswered Posts</Button>
          </Tooltip>

        </HStack>

        <PageControl currentPage={page} pageCount={latestRootMsgsQuery.data?.TPages ?? 0} setPageNumber={setPage}/>
        {
          auth.user === null &&
            <Text>Sign Up or Login first. We only need your email.</Text>

        }
        {auth.user !== null &&
        (latestRootMsgsQuery.data?.Response.length ?? 0) < 1
          ? <Text>There are no questions at the moment...</Text>
          : <>

            <Box
              mt={isMobile ? '10px' : '20px'}
              mb={'20px'}
              borderRadius={{base: '0px', sm: '6px'}}
              border={borderColor}>

              {
                latestRootMsgsQuery.data?.Response?.map((msg, i) => {
                  if (msg.CHThread === undefined) {
                    return <Text>Missing Thread info</Text>
                  } else {
                    return <DiscussionPageListItem
                      key={msg.ID}
                      resourceID={msg.CHThread?.ResourceID}
                      hideQnPreview={false}
                      message={msg}
                      onReply={() => {
                        latestRootMsgsQuery.refetch()
                      }}
                      hideBottomBorder={i + 1 === latestRootMsgsQuery.data?.Response.length}
                      responseBoxActive={() => {
                      }}
                    />
                  }

                })

              }
            </Box>
            <PageControl currentPage={page} pageCount={latestRootMsgsQuery.data?.TPages ?? 0} setPageNumber={setPage}/>


          </>
        }

      </Container>
    </>
  )
}


