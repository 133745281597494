import {useHistory} from "react-router-dom";
import {FC, useEffect, useState} from "react";
import {Center, Flex, Box, VStack, Text} from '@chakra-ui/react'
import {OtpForm} from "./OtpForm";
import {LoginForm} from "./LoginForm";
import {useAuth} from "../../Contexts/AuthContext";

export const Login: FC = () => {
  const auth = useAuth()
  const hist = useHistory()

  const [redirectURL, setRedirectURL] = useState<string | undefined>(undefined)

  const handleRedirection = () => {
    if (redirectURL !== undefined) {
      hist.push(redirectURL)
    } else if (auth.user !== null) {
      if (auth.user.SubscriptionInfo?.Perm === 2) {
        hist.push("/paper/2")
      } else {
        hist.push("/paper/1")
      }
    }
  }
  useEffect(() => {
    handleRedirection()
  }, [auth.user])

  useEffect(() => {
    let searchParams = new URLSearchParams(hist.location.search)
    let r = searchParams.get("redirect")
    if (r !== null) {
      setRedirectURL(r)
    }
  }, [hist.location.search])

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    /* global google */
    // @ts-ignore
    if (window.google) {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "765252207106-nuogmarmkl0sehtckkooo7r4f82kpsbl.apps.googleusercontent.com",
        callback: (response: any) => {
          auth.verifyOtp.mutate({googleJWT: response.credential})
        },
      });

      // @ts-ignore
      google.accounts.id.renderButton(document.getElementById("google-signin"), {
        theme: "outline",
        size: "large",
        text: "signin_with",
        shape: "rectangular",
      });

    }
    // @ts-ignore
  }, [window.google]);

  return (
    <Flex direction="column" h="100vh" align="stretch">
      <Flex
        alignItems={{base: 'flex-start', md: 'center'}}
        justifyContent="center"
        flex={2}
        padding={{base: '64px 32px', md: '0px'}}
      >
        <VStack
          w={{base: '100%', md: '460px'}}
          align="stretch"
          spacing="48px"
        >
          {!email ? (
            <OtpForm onSuccess={setEmail} passCode={setCode}/>
          ) : (
            <LoginForm email={email} reEnterEmail={() => setEmail('')} code={code} onLogin={() => {
              handleRedirection()
            }}/>
          )}
        </VStack>
      </Flex>

    </Flex>
  )
}