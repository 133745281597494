import React, {FC, useState} from "react";
import {useParams} from "react-router-dom";
import {QLState, QuestionLoader} from "../Question/QuestionLoader";
import {Button, Container, Heading, HStack, Text} from "@chakra-ui/react";
import {useAuth} from "../../Contexts/AuthContext";
import {QuestionEditor} from "../Question/QuestionEditor";

export const QuestionPage: FC<{}> = ({}) => {
  // @ts-ignore
  const {questionID} = useParams()
  const auth = useAuth()
  const [editMode, setEditMode] = useState(false)
  const [sampleMode, setSampleMode] = useState(false)
  return (
    <>{
      auth.user === null ?
        <><Text>Sign Up or Login first. We only need your email.</Text></> :
        <Container maxW="container.lg" pt={'20px'}>
          <Heading fontSize={'lg'}>Question {questionID}</Heading>
          <QuestionLoader
            key={'1'}
            qnID={questionID}
            nextQuestionIsLoading={false}
            bypassServerGrading={sampleMode}
            defaultState={sampleMode ? QLState.active : QLState.passive}
          />
          {auth.user.TenantAdmin?.find(x => x.TenantID === 'propella') &&
              <HStack mt={'20px'}>
                  <Button colorScheme={editMode ? 'brand' : undefined}
                          onClick={() => setEditMode(!editMode)}>Edit</Button>
                  <Button onClick={() => setSampleMode(!sampleMode)}>Test Mode</Button>
              </HStack>

          }
          {
            editMode &&
              <QuestionEditor qnID={questionID} didEdit={() => {
                setEditMode(!editMode)
              }}/>
          }
        </Container>
    }
    </>
  )
}