import {FC} from "react";
import {
  Box,
  Container,
  Stack,
  useColorModeValue,
  Text,
  Spacer,
  Link,
  useBreakpointValue
} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";
import {ExternalLinkIcon} from "@chakra-ui/icons";

export const Footer: FC = ({}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const currentYear = new Date().getFullYear();
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Box
      pb={'20px'}>

      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{base: 'column', md: 'row'}}
        spacing={4}
        justify={{base: 'center', md: 'space-between'}}
        align={{base: 'center', md: 'center'}}>
        <Text>© {currentYear} Propella. All rights reserved</Text>        <Spacer/>
        {
          isMobile &&
          <Text fontSize={'sm'} textAlign={'center'}>Are you on mobile? <br/>
              <Link
                  isExternal
                  href={"https://www.google.com/search?q=how+to+add+web+app+to+phone+home+screen"}>
                  Add to Home Screen

                  <ExternalLinkIcon mx="2px"/>
              </Link>
              <br/>
              for a native app experience!</Text>
        }
        <Link as={RouterLink} to="/affiliate" fontSize={'sm'} mr='10px'>
          Affiliates
        </Link>
        <Link as={RouterLink} to="/tos" fontSize={'sm'} mr='10px'>
          Terms of Service
        </Link>

        <Link as={RouterLink} to="/privacy" fontSize={'sm'} mr='10px'>
          Privacy Policy

        </Link>
      </Container>
      </Box>
    </Box>
  )
}