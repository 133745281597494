import React, {FC, useEffect, useRef, useState} from "react";
import {useAuth} from "../../Contexts/AuthContext";
import {Link as RouterLink} from "react-router-dom";
import {
  Button,
  Container,
  Heading,
  Tag,
  Text,
  VStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  HStack,
  IconButton,
  Td,
  Table,
  Thead,
  Th,
  TableCaption,
  Tbody,
  Tr,
  useClipboard,
  useColorMode,
  Stat,
  StatLabel,
  StatNumber,
  Divider,
  useBreakpointValue,
  Box,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  ModalFooter,
  useDisclosure,
  Spinner,
  Center,
  SimpleGrid,
  Stack,
  Link,
  Input,
  FormErrorMessage,
  FormControl,
  useToast, useColorModeValue, toast
} from "@chakra-ui/react";
import {
  GetLatestChargeHistory,
  SubscriptionStatus,
  UserSubscriptionInfo
} from "../../Models/Auth";
import dayjs from "dayjs";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {SubscriptionService} from "../../Services/SubscriptionService";
import {BiEditAlt, FiRefreshCw} from "react-icons/all";
import {AuthService} from "../../Services/AuthService";
import {AffiliateInfo} from "../../Models/Referral";
import QRCode from "react-qr-code";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getApiErrorMessage} from "../../Services/APIClient";
import {QuestionService} from "../../Services/QuestionService";
import {useURLQuery} from "../../Hooks/useURLQuery";

export const TransferGapPeriod = 3

const DisplayNameForm: FC<{
  onSuccess: () => void
}> = ({onSuccess}) => {

  const displayNameSchema = yup.object().shape({
    dn: yup.string().matches(/^[\w\d]{6,30}$/),
  }).required()
  const auth = useAuth()
  const {register, handleSubmit, formState: {errors, isValid}, clearErrors} = useForm<{ dn: string }>({
    mode: 'onChange',
    resolver: yupResolver(displayNameSchema)
  })
  const updateDisplayNameMutation = useMutation(AuthService.setDisplayName, {
    onSuccess: data => {
      auth.refetchUserInfo()
      onSuccess()
    }
  })

  const onSubmit = (data: { dn: string }) => {
    updateDisplayNameMutation.mutate({displayName: data.dn})
  }
  const hasError = () => !!errors.dn || updateDisplayNameMutation.isError
  const getErrorMessage = (): string => {
    const {dn} = errors
    console.log(updateDisplayNameMutation.error)
    return dn && dn.type === 'matches'
      ? 'A display name has to be between 6 to 30 alphanumeric characters long.'
      : getApiErrorMessage(updateDisplayNameMutation.error)

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="change-display-name" isInvalid={hasError()}>
        <HStack>
          <Input
            // value={auth.user?.DisplayName === "" ? "" : auth.user?.DisplayName}
            {...register("dn", {required: true})}
            placeholder={auth.user?.DisplayName === "" ? "Anonymous" : auth.user?.DisplayName}
          />
          <IconButton
            aria-label={"edit display name"}
            type={"submit"}
            disabled={!isValid}
            icon={<BiEditAlt/>}
          />
        </HStack>
        {hasError() && (
          <FormErrorMessage>{getErrorMessage()}</FormErrorMessage>
        )}
      </FormControl>
    </form>)
}

const SubscriptionSection: FC<{}> = ({}) => {
  const getSubscriptionType = (sub: UserSubscriptionInfo) => {
    return SubscriptionStatus(sub)
  }

  const getSubscriptionCoverage = (sub: UserSubscriptionInfo) => {
    if (sub.Perm === 3) {
      return "Paper 1 and Paper 2"
    } else if (sub.Perm === 2) {
      return "Paper 2"
    } else if (sub.Perm === 1) {
      return "Paper 1"
    }

  }

  const getSubscriptionPeriod = (sub: UserSubscriptionInfo) => {
    const ch = GetLatestChargeHistory(sub)
    const start = dayjs(ch?.SucceededAt).add(ch!.TrialHours, 'hour')
    const end = start.add(ch!.SubscriptionMonths, 'month')
    return start.format('D MMM YY h:mm a') + ' - ' + end.format('D MMM YY h:mm a')
  }

  const lastTransferredLessThanXMonths = (sub: UserSubscriptionInfo) => {
    const ch = GetLatestChargeHistory(sub)
    return (!!ch?.TransferredAt && dayjs(ch.TransferredAt).add(TransferGapPeriod, 'month').isAfter(dayjs()))
  }
  const [isOpenTransferDialog, setIsOpenTransferDialog] = useState(false)
  const auth = useAuth()
  const cancelTransferDialog = useRef()
  const toast = useToast()
  let [email, setEmail] = useState('')
  const transferAcc = useMutation('transfer-acc', SubscriptionService.transferSubscription(), {
    onSuccess: () => {
      setIsOpenTransferDialog(false)
      toast({
        position: "top",
        title: "Account transfer completed",
        status: "info",
        duration: 5000,
        isClosable: true,
      })
      auth.refetchUserInfo()
    }
  })
  return <>
    <VStack alignItems={'flex-start'} spacing={2}>

      <HStack>
        <Heading>Subscription</Heading>
        <IconButton
          size={'xs'}
          aria-label="reload subscription status"
          onClick={() => {
            auth.refetchUserInfo()
          }
          }
          icon={<FiRefreshCw/>}
        />
        {
          !!auth.user &&
          auth.user.SubscriptionInfo?.Perm !== 0 &&

            <Button
                size={'xs'}
                aria-label="transfer account"
                onClick={() => {
                  if (auth.user?.SubscriptionInfo !== undefined && lastTransferredLessThanXMonths(auth.user.SubscriptionInfo)) {
                    toast({
                      position: "top",
                      title: "Hold up...",
                      description: `A subscription can only be transferred once every ${TransferGapPeriod} months. This subscription was 
                    last transferred on ${dayjs(GetLatestChargeHistory(auth.user!.SubscriptionInfo)?.TransferredAt).format('D MMM YY h:mm a')}.`,
                      status: "info",
                      duration: 5000,
                      isClosable: true,
                    })
                  } else {
                    setIsOpenTransferDialog(true)

                  }
                }}

            >Transfer</Button>
        }


      </HStack>
      {
        auth.user !== null && auth.user.SubscriptionInfo !== undefined &&
        auth.user.SubscriptionInfo.Perm !== 0
          ? <>
            <Tag colorScheme={'brand'}>{getSubscriptionType(auth.user.SubscriptionInfo)}</Tag>
            <Text>{getSubscriptionCoverage(auth.user!.SubscriptionInfo!)}</Text>
            <VStack spacing={0} alignItems={'flex-start'} w={'full'}>
              <Text>Subscribed period</Text>
              <Text>{getSubscriptionPeriod(auth.user!.SubscriptionInfo!)}</Text>
            </VStack>
          </>
          : <Text>You're not subscribed</Text>
      }


    </VStack>
    <AlertDialog
      isOpen={isOpenTransferDialog}
      // @ts-ignore
      leastDestructiveRef={cancelTransferDialog}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Subscription Transfer
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Who would you like to transfer your remaining subscription to?</Text>
            <br/>

            <Text>Enter an email address that already has an account, but does not have an active subscription.</Text>
            <br/>

            <Text>A subscription can be transferred once every {TransferGapPeriod} months.</Text>
            <Input
              my={'20px'}
              placeholder={"email"}
              value={email}
              onChange={(data) => {
                setEmail(data.target.value)
              }}
            />

            <Text fontWeight={700}>WARNING: This is irreversible! Confirm the target email before moving on.</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              // @ts-ignore
              ref={cancelTransferDialog}
              onClick={() => {
                setIsOpenTransferDialog(false)
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isLoading={transferAcc.isLoading}
              onClick={() => {
                transferAcc.mutate(email)
              }} ml={3}
            >
              Transfer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  </>
}

export const ProfilePage: FC<{}> = ({}) => {
  // for reset progress button
  const [isOpenResetDialog, setIsOpenResetDialog] = useState(false)
  const auth = useAuth()
  const [showDisplayNameForm, setShowDisplayNameForm] = useState(false)
  const queryClient = useQueryClient()
  const affiliateEvent = useURLQuery().get('aff_event')
  const affiliateSection = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (affiliateEvent != null) {
      affiliateSection.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [affiliateEvent])

  useEffect(() => {
    auth.refetchUserInfo()
  }, [])
  const toast = useToast()
  const resetProgress = useMutation('reset-progress', SubscriptionService.resetProgress, {
    onSuccess: () => {
      setIsOpenResetDialog(false)
      toast({
        position: "top",
        title: "Progress Reset Completed",
        status: "info",
        duration: 5000,
        isClosable: true,
      })
      queryClient.invalidateQueries(["attempts"])
    }
  })

  const attemptQuery = useQuery(['attempts'], QuestionService.getAttempts(), {
    enabled: !!auth.user?.ID,
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
  })

  // const latestChargeHist = GetLatestChargeHistory(auth.user?.SubscriptionInfo)
  // const handleCancelSubscription = () => {
  //   cancelSubscriptionMutation.mutate()
  // }
  const cancelResetDialog = useRef()


  return (
    <Container maxW="container.lg" pt={'20px'}>
      {
        auth.user === null ?
          <>
            <Text>Sign Up or Login first. We only need your email.</Text>
          </>
          :
          <VStack w={'full'} alignItems={'flex-start'} spacing={'30px'}>
            <Heading size={'3xl'}>Profile</Heading>
            <VStack alignItems={'flex-start'} spacing={0}>
              <Heading>User ID</Heading>
              <Text>{auth.user.ID}</Text>
            </VStack>

            <VStack alignItems={'flex-start'} spacing={0}>
              <Heading>Display Name</Heading>
              <Text>{auth.user.DisplayName === "" ? "Anonymous" : auth.user.DisplayName}</Text>
              <Button size={'sm'} onClick={() => {
                setShowDisplayNameForm(!showDisplayNameForm)
              }}>Edit</Button>
              {showDisplayNameForm && <DisplayNameForm onSuccess={() => {
                setShowDisplayNameForm(false)
              }}/>}


            </VStack>

            <VStack alignItems={'flex-start'} spacing={0}>
              <Heading>Reputation</Heading>
              <Text fontSize={'lg'}>{auth.user.Rep}💎</Text>
            </VStack>
            <VStack alignItems={'flex-start'} spacing={0}>
              <Heading>Email</Heading>
              <Text>{auth.user.Email}</Text>
            </VStack>

            <SubscriptionSection/>

            <VStack alignItems={'flex-start'} spacing={2}>
              <Heading>Progress Reset</Heading>
              <Text> Want to start afresh? Resetting will remove all previous problem set attempts.</Text>
              <Button
                isDisabled={attemptQuery.data?.length === 0}
                onClick={() => {
                  setIsOpenResetDialog(!isOpenResetDialog)
                }}>Reset</Button>
            </VStack>
            {
              // ShouldShowAffiliateProgram(auth.user.SubscriptionInfo) &&
              <Box ref={affiliateSection}>
                <Divider/>
                <ReferralsView/>
              </Box>
            }

          </VStack>
      }

      <AlertDialog
        isOpen={isOpenResetDialog}
        // @ts-ignore
        leastDestructiveRef={cancelResetDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Progress Reset
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to reset your progress?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                // @ts-ignore
                ref={cancelResetDialog}
                onClick={() => {
                  setIsOpenResetDialog(false)
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={resetProgress.isLoading}
                onClick={() => {
                  resetProgress.mutate()
                }} ml={3}
              >
                Reset
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

    </Container>

  )
}

const ReferralsView: FC = () => {
  const auth = useAuth()
  const affiliateEvent = useURLQuery().get('aff_event')
  const toast = useToast()
  const queryClient = useQueryClient()
  const {
    hasCopied,
    onCopy
  } = useClipboard(`${window.location.origin}/?r=${auth.user?.ReferralID ?? auth.user?.ID}`)
  const {
    hasCopied: hasCopiedLinkAndMsg,
    onCopy: onCopyLinkAndMsg
  } = useClipboard(`Propella is a real estate exam question bank with more than 1500 questions! Try it with my referral link for a ${auth.user?.CustDiscRate}% discount: ${window.location.origin}/?r=${auth.user?.ReferralID ?? auth.user?.ID}`)

  const colorModePref = useColorMode()
  const affiliateInfoQuery = useQuery(["affiliateInfo"], AuthService.getAffiliateInfo, {
    enabled: auth.user?.IsAffiliate
  })

  const bumpReferralBonusMutation = useMutation(AuthService.bumpReferralBonus, {
    onSuccess: () => {
      toast({
        title: "Referral Bonus Bumped",
        description: "Your referral commission rate has been bumped for the event!",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      queryClient.invalidateQueries(["affiliateInfo"])
      queryClient.refetchQueries(["whoami"])
    }
  })


  return (

    <VStack alignItems={'flex-start'}>
      <Heading>Affiliate Program</Heading>
      <Link color={'brand2.500'} as={RouterLink} to={'/affiliate'}> Learn More.</Link>
      {
        auth.user?.IsAffiliate ?
          <>
            <Text> Share your referral link or a screenshot of the QR code and help us grow!</Text>

            {/*<Text noOfLines={2}>www.propellasg.com/?referee={auth.user.ID}</Text>*/}
            <Stack direction={['column', 'column', 'row']} spacing={'20px'} pt={'10px'}>
              <VStack maxW={'300px'} px={'15px'}>

                <QRCode
                  value={`${window.location.origin}/?r=${auth.user?.ReferralID ?? auth.user.ID}`}
                  bgColor={'rgba(0,0,0,0)'}
                  fgColor={colorModePref.colorMode === 'dark' ? 'white' : 'black'}
                  level={"L"}
                />
                <Button onClick={onCopy} size={'sm'}>
                  {hasCopied ? "Link copied" : "Copy Referral Link"}
                </Button>
                <Button onClick={onCopyLinkAndMsg} size={'sm'}>
                  {hasCopiedLinkAndMsg ? "Message and link copied" : "Copy Referral Message + Link"}
                </Button>
                {
                  affiliateEvent === "feb2023" && (auth?.user?.CommsRate ?? 15) < 15 &&
                    <Button
                        isLoading={bumpReferralBonusMutation.isLoading}
                        shadow={"rgba(255, 57, 155, 0.7) 0px 0px 10px 0px"}
                        onClick={() => bumpReferralBonusMutation.mutate({event: "feb2023"})}
                        bgGradient="linear(45deg, #264DC8, #FF0080)"
                        color={'white'}
                        sx={{
                          backgroundSize: "400% 400%",
                          WebkitAnimation: "Gradient 3s ease infinite",
                          MozAnimation: "Gradient 3s ease infinite",
                          animation: "Gradient 3s ease infinite"
                        }}
                    >
                        Bump Referral Bonus!</Button>

                }

              </VStack>

              <ReferralStats info={affiliateInfoQuery.data}/>
            </Stack>

            {

              affiliateInfoQuery.isLoading ?
                <Center>
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Center>
                :
                <>

                  <ReferralPayoutsView/>
                  <ReferralsTableView/>
                </>
            }


          </> :
          <>
            <Text>Do you enjoy propella? Join our affiliate program and help us spread the word!</Text>
            <Button
              colorScheme={'brand'}
              onClick={() => {
                AuthService.joinAffiliateProg().then(() => auth.refetchUserInfo())
              }}>Join</Button>
          </>
      }


    </VStack>
  )
}
const RequestPayoutForm: FC<{
  isOpen: boolean,
  onClose: () => void,
  setPaymentNotes: React.Dispatch<React.SetStateAction<string>>,
  paymentNotes: string,
  handleSubmit: (arg0: any) => void
}> = ({isOpen, onClose, setPaymentNotes, paymentNotes, handleSubmit}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Payout Request</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text mb={'20px'} fontWeight={'600'}>
            Please provide us your name and mobile number.
          </Text>
          <Text mb={'20px'} color={'gray.500'} fontSize={'sm'}> NOTE: We currently only pay out with PayNow.
            A payout can take up to <b>5 business days</b> to process.
            Please inform us at <b>propellasg@propellasg.com</b> ASAP if you change your PayNow number while a payout is
            processing.</Text>
          <Textarea
            placeholder={'paynow john goh 90001234'}
            onChange={event => {
              setPaymentNotes(event.target.value.slice(0, 40))
            }
            }
            value={paymentNotes}
          />
          <Text color={'gray.500'}>Max characters: 40</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={paymentNotes.length < 16}
            onClick={
              event => {
                handleSubmit(event)
                onClose()
              }


            }>Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ReferralPayoutsView: FC = () => {
  const affiliateInfoQuery = useQuery(["affiliateInfo"], AuthService.getAffiliateInfo)
  const {onOpen, onClose, isOpen} = useDisclosure()
  const [paymentNotes, setPaymentNotes] = useState('')

  const getPayoutsQuery = useQuery('payouts', AuthService.getPayouts)
  const createPayoutMutation = useMutation(AuthService.createPayout, {
    onSettled: (data, error, variables, context) => {

      affiliateInfoQuery.refetch()
      getPayoutsQuery.refetch()
    }
  })

  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  return (<>
      <Box w={'full'}>
        <HStack>

          <Heading size={'md'}>Payouts</Heading>
          {
            // show request payout button if commission owed is more than min payout
            !!affiliateInfoQuery.data && (affiliateInfoQuery.data.Owed >= affiliateInfoQuery.data.MinPayout) &&
              <>
                  <Button
                      isLoading={createPayoutMutation.isLoading}
                      ml={2}
                      size={'xs'}
                      onClick={onOpen}
                  >

                      Request Payout
                  </Button>
              </>
          }

        </HStack>

        <Table variant="simple">
          <TableCaption>All the payouts you have requested.
            {
              isMobile && " View this on a bigger screen to see more information."

            }

          </TableCaption>
          <Thead>
            <Tr>
              {isMobile === false && <Th>ID</Th>}
              <Th>Date</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              {isMobile === false && <Th>Notes</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {
              getPayoutsQuery.data?.map(payout => (
                <Tr>
                  {
                    isMobile === false && <Td>{payout.ID}</Td>}
                  <Td>{dayjs(payout.CreatedAt).format('D MMM YY h:mm a')}</Td>
                  <Td>{payout.Amount ? '$' + payout.Amount / 100 : '$0'}</Td>
                  <Td><Badge px={'10px'} colorScheme={
                    payout.Status === 'open' ?
                      'yellow' :
                      payout.Status === 'accepted' ?
                        'green' :
                        'red'
                  }>{payout.Status}</Badge></Td>
                  {isMobile === false && <Td>{payout.PaymentNotes}</Td>}
                </Tr>
              ))
            }

          </Tbody>
        </Table>
      </Box>
      <RequestPayoutForm
        isOpen={isOpen}
        onClose={onClose}
        paymentNotes={paymentNotes}
        setPaymentNotes={setPaymentNotes}
        handleSubmit={() => {
          createPayoutMutation.mutate({paymentNotes})
        }}
      />
    </>
  )
}

const ReferralStats: FC<{ info?: AffiliateInfo }> = ({info}) => {
  const auth = useAuth()
  return (
    <SimpleGrid columns={3} spacing={'8px'}>
      <Stat>
        <StatLabel>Total Referrals</StatLabel>
        <StatNumber>{info?.Referrals}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Paid Referrals</StatLabel>
        <StatNumber>{info?.PaidReferrals}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Commission Earned</StatLabel>
        <StatNumber>{info?.Earned ? '$' + info?.Earned / 100 : '$0'}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Commission Paid Out</StatLabel>
        <StatNumber>{info?.PaidOut ? '$' + info?.PaidOut / 100 : '$0'}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Commission Owed</StatLabel>
        <StatNumber>{info?.Owed ? '$' + info?.Owed / 100 : '$0'}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Commission Processing</StatLabel>
        <StatNumber>{info?.Processing ? '$' + info?.Processing / 100 : '$0'}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Minimum Payout</StatLabel>
        <StatNumber>{info?.MinPayout ? '$' + info?.MinPayout / 100 : '$100'}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Commission Rate</StatLabel>
        <StatNumber>{
          (auth.user?.CommsRate ?? 0 )>14
            ? <Text
              bgGradient="linear(45deg, #264DC8, #FF0080)"
              bgClip={'text'}
              sx={{
                backgroundSize: "400% 400%",
                WebkitAnimation: "Gradient 3s ease infinite",
                MozAnimation: "Gradient 3s ease infinite",
                animation: "Gradient 3s ease infinite"
              }}
            >{auth.user?.CommsRate}%</Text>
        : <Text>{auth.user?.CommsRate}%</Text>
        }</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Referral Code Discount</StatLabel>
        <StatNumber>{auth.user?.CustDiscRate}%</StatNumber></Stat>
    </SimpleGrid>
  )
}

const ReferralsTableView: FC = () => {
  const getReferralsQuery = useQuery('referrals', AuthService.getUserReferrals)

  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  return (
    <Box w={'full'}>
      <Heading size={'md'}>Referrals</Heading>

      <Table variant="simple">
        <TableCaption>All the people you have referred.
          {
            isMobile && " View this on a bigger screen to see more information."

          }

        </TableCaption>
        <Thead>
          <Tr>
            <Th>User ID</Th>
            {
              isMobile === false &&
                <>
                    <Th>Account Created On</Th>
                    <Th>Payment Completed</Th>
                </>
            }

            {isMobile === false && <Th>Amount Paid</Th>}
            {isMobile === false && <Th>Commission</Th>}
            {<Th>Amount Earned</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {
            getReferralsQuery.data?.map(referer => (
              <Tr>
                <Td>{referer.ReferrerID}</Td>
                {/*last 6 digits*/}

                {
                  isMobile === false &&
                    <>
                        <Td>{dayjs(referer.CreatedAt).format('D MMM YY h:mm a')}</Td>
                        <Td>{referer.HasPaid ? dayjs(referer.PaidAt).format('D MMM YY h:mm a') : 'pending'}</Td>
                    </>
                }
                {isMobile === false && <Td>{referer.HasPaid ? referer.Amount : " - "}</Td>}
                {isMobile === false && <Td>{referer.HasPaid ? referer.CommsRate : " - "}</Td>}
                <Td>{referer.HasPaid ? referer.Earned : " - "}</Td>
              </Tr>
            ))
          }

        </Tbody>
      </Table>
    </Box>

  )
}