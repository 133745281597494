import {Flex, HStack, Spacer, Stat, StatLabel, StatNumber} from "@chakra-ui/react";
import {QLState} from "./QuestionLoader";

export const QLHeader = (
  {
    qnNumber,
    totalQnCount,
    loaderState,
    showScore,
    score
  }:
    {
      qnNumber: number,
      totalQnCount: number,
      loaderState: QLState,
      showScore: boolean
      score?: number
    }
) => {
  return (
    <HStack w={'full'}>
      <Stat>
        <StatLabel>Question</StatLabel>
        <StatNumber>{qnNumber + '/' + totalQnCount}</StatNumber>
      </Stat>

      {loaderState === QLState.review && showScore &&
          <>
              <Spacer/>
              <Flex w={'80px'} alignItems={'flex-end'}>
                  <Stat>
                      <StatLabel>Score</StatLabel>
                      <StatNumber>{score ?? 0}</StatNumber>
                  </Stat>

              </Flex>

          </>
      }
    </HStack>
  )
}
