import React, {FC, useEffect, useState} from "react";
import {
  Box,
  Button, chakra, Container,
  Flex, HStack, Icon, Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue, useDisclosure, useToast, VStack,
} from "@chakra-ui/react";
import {useMutation, useQuery} from "react-query";
import {SubscriptionService} from "../../Services/SubscriptionService";
import {GET_API_BASE_URL} from "../../Services/APIClient";
import {useAuth} from "../../Contexts/AuthContext";
import {useHistory} from "react-router-dom";
import useLocalStorage from "../../Hooks/use-local-storage";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {AuthService} from "../../Services/AuthService";

export const PricingPage = () => {
  const referralCode = useURLQuery().get("r")
  const [referee, setReferee] = useLocalStorage<string | null>('referee', null)
  const plansQuery = useQuery('plans', SubscriptionService.getPlans(referee ?? referralCode ?? undefined))
  const auth = useAuth()
  const hist = useHistory()
  // const [frequency, setFrequency] = useState("1mth"); //1mth,3mth,12mth
  const [planType, setPlanType] = useState(0)
  const toast = useToast()

  const setReferrerMutation = useMutation(AuthService.setReferrer, {
    onSuccess: ref => {
      onCloseReferralField()
      plansQuery.refetch()
    },
    onError: err => {
      onCloseReferralField()
    }
  })

  useEffect(() => {
    if (referralCode !== null) {
      setReferrer(referralCode)
      hist.push('/pricing')
    }
  }, [])

  const setReferrer = (rc: string) => {
    setReferee(rc)
    if (!!auth.user) {
      setReferrerMutation.mutate({referralID: rc})
    }
  }

  const {onClose: onCloseReferralField, onOpen: onOpenReferralField, isOpen: isOpenReferralField} = useDisclosure()
  const [referralField, setReferralField] = useState('')

  // if ?checkout=xxx, handle checkout for xxx right away. Used for redirecting back from login.
  useEffect(() => {
    let searchParams = new URLSearchParams(hist.location.search)
    const checkoutPriceID = searchParams.get("checkout")
    if (checkoutPriceID !== null) {
      handleCheckout(checkoutPriceID)
    }
  }, [hist.location.search])


  const handleCheckout = (priceID: string) => {

    if (auth.user === null) {
      hist.push(`/login?redirect=/pricing?checkout=${priceID}`)
    } else if (auth.user.SubscriptionInfo?.Perm !== 0) {
      toast({
        title: "Like us so much that you want to pay us twice?",
        description: "You're only allowed to subscribe to 1 plan at a time. " +
          "Contact us at propellasg@propellasg.com if you would like to change your plan.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      })
    } else {
      window.location.href = GET_API_BASE_URL() + `/api/create-checkout-session/${priceID}?origin=${window.location.origin}`
    }
  }

  const getPlan = (idx: number) => {
    return plansQuery.data?.Plans.find(x => x.ID === idx)
  }

  const totalDiscount = (priceDiscount: number) => {
    return (plansQuery.data?.GlobalDisc ?? 0) + (plansQuery.data?.ReferralDisc ?? 0) + priceDiscount
  }

  // const getPricesForPlan = (idx: number) => {
  //   const x = getPlan(idx)?.Prices.map(x => x.Amount * (100 - totalDiscount(x.Discount)) / 100)
  //   return x ?? []
  // }
  //
  // const getCompareAtPricesForPlan = (idx: number) => {
  //   const x = getPlan(idx)?.Prices.map(x => x.Amount)
  //   return x ?? []
  // }


  // const getFreqPrice = () => {
  //   return FreqPrices.find(x => x.freqId === frequency)!
  // }

  const bannerGradient = useColorModeValue('linear-gradient(to right, rgba(20, 255, 20, 0.63), rgba(255, 145, 3, 0.81), rgba(255, 11, 160, 0.93))',
    'linear-gradient(to right, rgba(15, 232, 15, 0.41), rgba(208, 129, 26, 0.66), rgba(198, 34, 134, 0.93))')
  const bannerGradient2 = useColorModeValue('linear-gradient(to right, rgba(200, 20, 200, 0.63), rgba(1, 145, 258, 0.81), rgba(78, 91, 219, 0.93))',
    'linear-gradient(to right, rgba(110, 5, 110, 0.63), rgba(1, 69, 200, 0.81), rgba(40, 91, 160, 0.93))')
  const bannerGradient3 = useColorModeValue('linear-gradient(to right, rgba(65, 189, 200, 0.63), rgba(88, 60, 201, 0.81), rgba(200, 155, 120, 0.93))',
    'linear-gradient(to right, rgba(65, 189, 200, 0.63), rgba(88, 60, 201, 0.81), rgba(200, 155, 120, 0.93))')

  return (
    <Container
      pt={'20px'}
      maxW="container.lg"
      minH={"90vh"}
    >
      <Box
        py="64px"
        px="5"
        bg={useColorModeValue("gray.100", "gray.700")}
        borderRadius={'8px'}>
        <Box w="full">
          <Text mb={2} fontSize="5xl" fontWeight="bold" lineHeight="tight" textAlign="center">
            Plans & Pricing
          </Text>
          {
            (plansQuery.data?.GlobalDisc ?? 0) > 0 &&

              <VStack
                  my={'20px'}
                  py={'15px'}
                  px={'10px'}
                  bg={'brand.600'}
                  bgGradient={bannerGradient}
                  borderRadius={'8px'}>
                  <Text
                      px={"6px"}
                      textAlign={'center'}
                      fontWeight={'extrabold'} color={'white'}>
                      CRUNCH TIME OFFER: {plansQuery.data?.GlobalDisc}% off
                  </Text>

              </VStack>
          }
          {

            <VStack
              my={'20px'}
              py={'15px'}
              px={'10px'}
              bg={'brand.600'}
              bgGradient={bannerGradient2}
              borderRadius={'8px'}>
              {
                (plansQuery.data?.ReferralDisc ?? 0) > 0 ?
                  <Text
                    px={"6px"}
                    textAlign={'center'}
                    fontWeight={'extrabold'} color={'white'}>
                    REFERRAL DISCOUNT: {plansQuery.data?.ReferralDisc}% off
                  </Text> :
                  <>
                    <Text
                      px={"6px"}
                      textAlign={'center'}
                      fontWeight={'extrabold'} color={'white'}>
                      Ask around for a referral link to get an extra 5% off!
                    </Text>

                    {
                      isOpenReferralField ?
                        <HStack>
                          <Input color={'white'} value={referralField} onChange={val=>setReferralField(val.target.value)}/>
                          <Button onClick={()=>{setReferrer(referralField)}}>Submit</Button>
                        </HStack> :
                        <Text as={'u'} color={'white'} fontSize={'xs'} onClick={onOpenReferralField} fontWeight={'bold'} _hover={{cursor:'pointer'}}>
                          Manually enter referral code.</Text>
                    }
                  </>
              }


            </VStack>

          }
          {/*<VStack*/}
          {/*  my={'20px'}*/}
          {/*  py={'15px'}*/}
          {/*  px={'10px'}*/}
          {/*  bg={'brand.600'}*/}
          {/*  bgGradient={bannerGradient3}*/}
          {/*  borderRadius={'8px'}>*/}

          {/*      <Text*/}
          {/*        px={"6px"}*/}
          {/*        textAlign={'center'}*/}
          {/*        fontWeight={'extrabold'} color={'white'}>*/}
          {/*        FINAL LAP OFFER: extra 5% off for 1 month plans (ends after 12 Apr)*/}
          {/*      </Text>*/}

          {/*</VStack>*/}

          <Box mt={8}>
            <Feature>AI RES knowledge base with propellaBot</Feature>
            <Feature>Thousands of practice questions and answers</Feature>
            <Feature>New questions added regularly</Feature>
            <Feature>Latest curriculum</Feature>
            <Feature>Unlimited problem set attempts</Feature>
            <Feature>Bookmark favourite questions</Feature>
            <Feature>Mobile screen friendly</Feature>
            <Feature>Dark mode</Feature>
            <Feature>No hidden fees and no auto-renewal</Feature>
            <Feature>Discussion board access</Feature>
            <Feature>Transferable subscriptions*</Feature>
            {/*<Feature>2h free trial for new users</Feature>*/}
            {/*<Feature>One-click cancellation and full refund during trial</Feature>*/}
          </Box>

        </Box>
        <Text px={'20px'} pb={'20px'} color={'gray.500'} fontSize={'sm'}>
          * The 12 and 24-month subscriptions are transferable after 3 months. Use what you need, sell when you're done.
        </Text>

        <Text px={'20px'} pb={'20px'} color={'gray.500'} fontSize={'sm'}>
          <Text fontWeight={'600'}>Any of the buttons below will bring you to Stripe.</Text> Stripe handles our
          payments. You will be asked to key in your
          credit card information or pay with PayNow and you will be brought back here when you're done. We do not keep
          your card
          information on
          our servers.
        </Text>
        <Flex justify="center" mx={["auto", 0]} mb={'10px'}>
          <Stack
            direction="row"
            justify="space-between"
            p="2"
            textAlign="center"
            rounded="md"
            bg={useColorModeValue("gray.200", "gray.600")}
          >
            <Button
              colorScheme="brand"
              variant={planType === 0 ? "solid" : "ghost"}
              onClick={() => setPlanType(0)}
              px={[2, 4, 6, 8]}
            >
              Combined
            </Button>
            <Button
              colorScheme="brand"
              variant={planType === 1 ? "solid" : "ghost"}
              onClick={() => setPlanType(1)}
              px={[2, 4, 6, 8]}
            >
              Paper 1
            </Button>
            <Button
              colorScheme="brand"
              variant={planType === 2 ? "solid" : "ghost"}
              onClick={() => setPlanType(2)}
              px={[2, 4, 6, 8]}
            >
              Paper 2
            </Button>

          </Stack>

        </Flex>
        <Box py={["5", "5", "5", "5"]} mx="auto">
          <SimpleGrid columns={[1, 2, 2, 4]} gap={[2, 4, 4, 4]}>

            <ProductCard
              price={(plansQuery.data?.Plans[planType].Prices[0].Amount ?? 0) * (100 - totalDiscount(plansQuery.data?.Plans[planType].Prices[0].Discount!)) / 100}
              compareAtPrice={plansQuery.data?.Plans[planType].Prices[0].Amount ?? 0}
              productName={'1 month'}
              month={1}
              handleClick={() => {
                const x = plansQuery.data?.Plans[planType].Prices[0].ID
                if (x === undefined) {
                  console.log("cannot find the right plan and price")
                } else {
                  handleCheckout(x)
                }
              }}
            />

            <ProductCard
              price={(plansQuery.data?.Plans[planType].Prices[1].Amount ?? 0) * (100 - totalDiscount(plansQuery.data?.Plans[planType].Prices[1].Discount!)) / 100}
              compareAtPrice={plansQuery.data?.Plans[planType].Prices[1].Amount ?? 0}
              productName={'3 months'}
              month={3}
              handleClick={() => {
                const x = plansQuery.data?.Plans[planType].Prices[1].ID
                if (x === undefined) {
                  console.log("cannot find the right plan and price")
                } else {
                  handleCheckout(x)
                }
              }}
            />

            <ProductCard
              price={(plansQuery.data?.Plans[planType].Prices[2].Amount ?? 0) * (100 - totalDiscount(plansQuery.data?.Plans[planType].Prices[2].Discount!)) / 100}
              compareAtPrice={plansQuery.data?.Plans[planType].Prices[2].Amount ?? 0}
              productName={'12 months'}
              month={12}
              handleClick={() => {
                const x = plansQuery.data?.Plans[planType].Prices[2].ID
                if (x === undefined) {
                  console.log("cannot find the right plan and price")
                } else {
                  handleCheckout(x)
                }
              }}
            />
            <ProductCard
              price={(plansQuery.data?.Plans[planType].Prices[3].Amount ?? 0) * (100 - totalDiscount(plansQuery.data?.Plans[planType].Prices[3].Discount!)) / 100}
              compareAtPrice={plansQuery.data?.Plans[planType].Prices[3].Amount ?? 0}
              productName={'24 months'}
              month={24}
              handleClick={() => {
                const x = plansQuery.data?.Plans[planType].Prices[3].ID
                if (x === undefined) {
                  console.log("cannot find the right plan and price")
                } else {
                  handleCheckout(x)
                }
              }}
            />

          </SimpleGrid>
        </Box>
      </Box>
    </Container>
  );
}

const ProductCard: FC<{
  price: number
  compareAtPrice: number
  productName: string
  handleClick: () => void
  month:number
}> = ({price, compareAtPrice, productName, handleClick, month}) => {
  return (
    <Box
      rounded={"lg"}
      shadow={"md"}
      bg={useColorModeValue("white", "gray.800")}
    >
      <Flex
        direction="column"
        justify="space-between"
        p="6"
        borderColor={useColorModeValue("gray.200", "gray.600")}
      >
        <chakra.p
          mb={1}
          fontSize="lg"
          fontWeight="semibold"
          color={useColorModeValue("gray.700", "gray.400")}
        >
          {productName}
        </chakra.p>
        <Box>
          <Text

            as={'s'}
            fontSize="lg"
            fontWeight={"bold"}
            color={useColorModeValue("gray.900", "gray.50")}
          >
            SGD${compareAtPrice}
          </Text>
        </Box>


        <Text
          fontSize="3xl"
          fontWeight={"bold"}
          mt={'-5px'}
          pb={'10px'}
          color={useColorModeValue("gray.900", "gray.50")}
        >
          SGD${price}
        </Text>
        <Link
          w={"auto"}
          display="inline-flex"
          alignItems="center"
          justifyContent="center"

          py={3}
          border="solid transparent"
          fontWeight="bold"
          rounded="md"
          shadow="md"
          color={"white"}
          bg={useColorModeValue("brand.600", "brand.500")}
          _hover={{
            bg: useColorModeValue("brand.700", "brand.600"),
          }}
          onClick={() => {
            handleClick()
          }}
        >
          Checkout
        </Link>
      </Flex>

    </Box>
  )
}

const Feature = (props: any) => {
  return (
    <Flex align="center" mb={'10px'}>
      <Flex>
        <Icon
          boxSize={5}
          mt={1}
          mr={1}
          color={useColorModeValue("brand.500", "brand.300")}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </Icon>
      </Flex>
      <Box ml={2}>
        <chakra.span mt={2} color={useColorModeValue("gray.700", "gray.400")}>
          {props.children}
        </chakra.span>
      </Box>
    </Flex>
  );
};
