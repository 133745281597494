import {FC} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {Box, Center, Container, Heading, Spinner, Text} from "@chakra-ui/react";
import {QuestionService} from "../../Services/QuestionService";
import {ProblemSetTable} from "./ProblemSetTable";
import {TopicProbSetAttemptHeader} from "./TopicProbSetAttemptHeader";
import {useAuth} from "../../Contexts/AuthContext";
import {GeneratedProblemSetView} from "./GeneratedProblemSetView";

export const TopicInfoView: FC<{}> = ({}) => {
  const auth = useAuth()

  // @ts-ignore
  const {topicID} = useParams()
  const topicQuery = useQuery(["topic", topicID], QuestionService.getTopicInfo(topicID), {
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
    enabled: !!topicID
  })
  const attemptQuery = useQuery(['attempts'], QuestionService.getAttempts(), {
    enabled: !!auth.user?.ID,
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
  })
  return (
    <Container maxW="container.lg" pt={'20px'}>
      {
        topicQuery.isFetched ?
          <TopicProbSetAttemptHeader topic={topicQuery.data!}/>
          : <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>

      }
        <Box
        mb={'20px'}
        >
          <Heading fontSize={'3xl'}>Mock Test</Heading>
          <Text
            fontSize={'sm'}
            color={'gray.500'}
            my={'6px'}
          >Randomly pull 20 MCQs from the question bank and test yourself with a timer! You can review your last 5 attempts.</Text>
          <GeneratedProblemSetView topicID={topicID}/>
        </Box>

      {
        topicQuery.data?.ProblemSets &&
        <>
            <Heading fontSize={'3xl'}>All Questions</Heading>
            <Text
                fontSize={'sm'}
                color={'gray.500'}
                my={'6px'}
            >
                All our questions from our question bank, grouped into sets of up to 20 questions.
            </Text>
            <ProblemSetTable pSets={topicQuery.data.ProblemSets} attempts={attemptQuery.data ?? []}/>

        </>
      }
    </ Container>
  )
}