import {FC} from "react";
import {useQuery} from "react-query";
import {QnMgrService} from "../../Services/QnMgrService";
import {Button, VStack} from '@chakra-ui/react'

export const PaperSelector: FC<{
  selectedPaper: undefined | string,
  setSelectedPaper: (paperID: string) => void
}> = ({selectedPaper, setSelectedPaper}) => {
  const papersQuery = useQuery(["tenant", "papers"], QnMgrService.GetPapers("propella"))
  return (<VStack alignItems={'stretch'}>{
    papersQuery.data?.map(paper =>
      <Button
        colorScheme={selectedPaper === paper.ID ? 'blue' : undefined}
        onClick={() => {
          setSelectedPaper(paper.ID)
        }}
      >
        {paper.Name}
      </Button>
    )
  }
    <Button
      colorScheme={selectedPaper === '$unassigned$' ? 'blue' : undefined}
      onClick={() => {
        setSelectedPaper("$unassigned$")
      }}

    >Unassigned</Button>
  </VStack>)
}