import {FC} from "react";
import {Container} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const policy = `
# Propella’s Privacy Policy
This policy explains how Propella uses the personal information which you provide to us when using our service, including but not limited to our website (the "Website"). Please read this Privacy Policy and understand it before using our services. By visiting and/or ordering services on the Website, you agree and, where required, consent to the collection, use and transfer of your information as set out in this policy.

## Personal Information Collection
Propella collects your email upon registration so that we can provide an experience that is customised to your needs.

By providing your information during registration or other situations, you are giving us the permission to use, share, and store it in a manner consistent with this Privacy Policy.

## Purpose of Collecting Personal Information
We may use the personal information collected for the following usage:

- To provide product-related services and support, including the provision of administrative support and technical assistance.
- To monitor communications with customers for record-keeping, quality control and training.
- To administer a contest, promotion, survey or other site feature.
- For marketing, communication and feedback.

## Cookies
Cookies are small files placed on your computer by websites that you visit or certain emails you open.

By using Propella, you consent to Propella's use of cookies to improve your online experience which includes understanding and saving your preferences for future visits.
To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit www.allaboutcookies.org.

You may refuse to accept cookies by altering the settings on your internet browser. If you choose not to allow the use of cookies, some areas of the Propella Website may not be accessible or function properly.

## Third Party Disclosure
Propella takes great care to protect the information you provide to us. We do not rent or sell customer's personal information to third parties.

## Right of Access
You have the right to request a copy of your personal information held by us and to request for any inaccurate personal information to be rectified. A request can be submitted by email (Details in the "Contacting Us" section). Kindly note that we reserve the right to charge an administrative fee for providing a copy of your personal information.

## Updates to the Privacy Policy
Propella reserves the right to amend and vary the terms of this Privacy Policy at any time and will provide the updated Policy on our website.

## Contacting Us
Feel free to contact us for any queries regarding this privacy policy as follows:

Propella
support@propellasg.com
`

export const PrivacyPolicy:FC = () => {
  return (
    <Container maxW={'container.lg'}>
      <ReactMarkdown className={'markdown-body'} children={policy} remarkPlugins={[remarkGfm]} />
    </Container>
  )
}