import {useEffect, useState} from "react";
import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {
  Button, HStack, Kbd,
  Spacer, useBreakpointValue, useColorModeValue,
  VStack, Text,
} from "@chakra-ui/react";
import {AnswerHistory, QAttempt, Question2} from "../../Models/Question";
import {AxiosError} from "axios";
import {QLAccessoryBar} from "./QLAccessoryBar";
import {QLQuestionDisplay} from "./QLQuestionDisplay";
import {QLHeader} from "./QLHeader";
import dayjs from "dayjs";
import {UserQuestionService} from "../../Services/UserQuestionService";

export enum QLState {
  active, // question solvable.
  disabled, // question not solvable.
  solved, // question solved.
  review, // question solved. show user answer, correct answer, explanation.
  passive // question not solvable. show correct answer, explanation..
}


export const QuestionLoader = (
  {
    qnNumber,
    question,
    totalQnCount,
    qnID,
    attemptID,
    nextButtonTitle,
    nextQuestion,
    nextQuestionIsLoading,
    prevQuestion,
    defaultState,
    bypassServerGrading = false,
    stayInActiveAfterAnswering = false,
    addNewUserAnswerToQueryData,
    hidePageControls = true,
    hideAccessoryButtons = false,
    showScore = false,
    disableAccessoryButtonHotKeys = false,
    isCommunityQn = false,
  }:
    {
      stayInActiveAfterAnswering?: boolean
      qnNumber?: number,
      totalQnCount?: number
      question?: Question2<any>,
      qnID: string,
      nextQuestionIsLoading: boolean,
      attemptID?: string,
      nextQuestion?: () => void,
      prevQuestion?: () => void,
      nextButtonTitle?: string,
      defaultState: QLState,
      bypassServerGrading?: boolean,
      addNewUserAnswerToQueryData?: (ansHist: AnswerHistory) => void,
      hidePageControls?: boolean,
      hideAccessoryButtons?: boolean,
      disableAccessoryButtonHotKeys?: boolean,
      showScore?: boolean,
      isCommunityQn?: boolean,
    }
) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})

  const [readyForSubmit, setReadyForSubmit] = useState<boolean>(false)
  const [tempUsrAns, setTempUsrAns] = useState<string | undefined>(undefined)
  const [loaderState, setLoaderState] = useState<QLState>(defaultState)
  const [noPerms, setNoPerms] = useState(false)

  // for component internal control. When a panel is open, user should not be able to proceed to another question.
  const [shouldHidePageControls, setShouldHidePageControls] = useState(false)
  const submitButtonColor = useColorModeValue('brand.600', "brand.700")
  const canShowAccessoryButtons = !hideAccessoryButtons && loaderState !== QLState.active
  const qnQuery = useQuery(
    ['qns', qnID],
    QuestionService.getQuestion(qnID),
    {
      staleTime: 1000 * 60 * 1440, // 1 day
      cacheTime: 1000 * 60 * 1440,
      initialData: question,
      enabled: question === undefined,
      onError: (error: AxiosError) => {
        if (error.response?.status === 401) {
          setNoPerms(true)
        }
      }
    })

  const savedAnsQuery = useQuery(
    ['usr-ans', qnID, attemptID],
    QuestionService.getHistoricAnswer(attemptID ?? '', qnID),
    {
      enabled: !!attemptID,
      staleTime: 1000 * 60 * 1440, // 1 day
      cacheTime: 1000 * 60 * 1440,
    })

  useEffect(() => {
    setReadyForSubmit(false)
    setTempUsrAns(undefined)
  }, [qnID])

  useEffect(() => {
    if (defaultState === QLState.passive || (attemptID === undefined && !isCommunityQn)) {
      setLoaderState(defaultState)
    } else if (stayInActiveAfterAnswering) {
      setLoaderState(QLState.active)
    } else if (nextQuestion === undefined) {
      setLoaderState(QLState.review)
    } else if (!!savedAnsQuery.data?.Ans) {
      setLoaderState(QLState.review)
    } else {
      setLoaderState(QLState.active)
    }

  }, [attemptID, qnID, defaultState, nextQuestion, savedAnsQuery.data, stayInActiveAfterAnswering])

  const isLastQn = (totalQnCount ?? 0) === (qnNumber ?? 0)
  const canGoNext = ((loaderState === QLState.solved || loaderState === QLState.review) || stayInActiveAfterAnswering)
  const canGoNextQn = canGoNext && nextQuestion && !isLastQn
  const upHandler = ({key}: { key: string }): void => {

    switch (key) {

      case "ArrowLeft":
        if (prevQuestion) {
          prevQuestion()

        }
        break
      case "ArrowRight":
        if (canGoNextQn) {
          nextQuestion()
        }
        break
      case "Enter":
        if (loaderState === QLState.active && readyForSubmit) {
          submitAnswer(tempUsrAns!)
        }
        break

    }
  }

  // Add event listeners
  useEffect(() => {
    if (!shouldHidePageControls) {
      window.addEventListener("keyup", upHandler);
      return () => {
        window.removeEventListener("keyup", upHandler);
      };
    }
  }, [nextQuestion, readyForSubmit, prevQuestion, savedAnsQuery.data,
    tempUsrAns, loaderState, shouldHidePageControls])


  const queryClient = useQueryClient()
  const markQuestionDone = useMutation(
    QuestionService.markQuestionAsDone,
    {
      onSuccess: data => {
        // console.log(data)
        queryClient.setQueryData(['usr-ans', qnID, attemptID], data)
        addNewUserAnswerToQueryData?.(data)
        if (!stayInActiveAfterAnswering) {

          setLoaderState(QLState.review)
        }
      }
    })

  const upsertQAttempt = useMutation(UserQuestionService.upsertQAttempt,
    {
      onSuccess: data => {
        queryClient.setQueryData(['qAttempts'], (old: QAttempt[] | undefined) => {
          return [...old ?? [], data]
        })
      }
    })


  const submitAnswer = (ans: number | string) => {
    if (bypassServerGrading) {
      setLoaderState(QLState.review)
    }
    if (!stayInActiveAfterAnswering) {
      setLoaderState(QLState.solved)
    }

    if (!!attemptID) {
      markQuestionDone.mutate({attemptID: attemptID, questionID: qnID, answer: {Answer: String(ans)}})

    } else {
      upsertQAttempt.mutate({questionID: qnID, answer: String(ans)})
    }
    setReadyForSubmit(false)

  }

  return (
    <VStack w={'full'}>
      <VStack
        spacing={'10px'}
        alignItems={'flex-start'}
        sx={{
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none'
        }}
        w={'full'}
        py={'10px'}
      >
        {
          qnNumber !== undefined && totalQnCount !== undefined &&
            <QLHeader
                qnNumber={qnNumber} showScore={showScore} loaderState={loaderState}
                totalQnCount={totalQnCount} score={savedAnsQuery.data?.Score}/>
        }
        <QLQuestionDisplay
          key={qnID}
          noPerms={noPerms}
          data={qnQuery.data}
          dataIsLoadingError={qnQuery.isLoadingError}
          bypassServerGrading={bypassServerGrading}
          tempUsrAns={tempUsrAns}
          savedAns={savedAnsQuery.data?.Ans}
          loaderState={loaderState}
          tempUsrAns1={setTempUsrAns}
          readyForSubmit={setReadyForSubmit}/>

        <Spacer/>
        {
          loaderState !== QLState.active &&
          qnQuery.data?.CreatedAt !== qnQuery.data?.UpdatedAt &&
            <Text pt={'10px'} fontSize={'sm'} color={'gray.500'}>Last updated
                on {dayjs(qnQuery.data?.UpdatedAt).format('DD MMM YYYY HH:mm')}</Text>
        }
        <HStack w={'full'}>
          <Spacer/>
          {
            loaderState === QLState.active &&
              <Button
                  isDisabled={!readyForSubmit}
                  bg={!readyForSubmit ? undefined : submitButtonColor}
                  textColor={'white'}
                  onClick={() => {
                    submitAnswer(tempUsrAns!)
                  }}>
                {
                  !isMobile && <Kbd bg={'rgb(255,255,255,0.2)'} mr={'6px'}>Enter</Kbd>
                }
                  Submit</Button>
          }

        </HStack>
        {
          canShowAccessoryButtons && qnQuery.data &&
            <QLAccessoryBar
                data={qnQuery.data}
                disableAccessoryButtonHotKeys={disableAccessoryButtonHotKeys}
                shouldHidePageControls={s => setShouldHidePageControls(s)}
            />
        }
        {
          !hidePageControls && !shouldHidePageControls &&
            <HStack w={'full'}>
              {!!prevQuestion &&
                  <Button onClick={prevQuestion}>
                    {!isMobile && <Kbd mr={'6px'}>←</Kbd>}Previous
                  </Button>
              }
                <Spacer/>
              {
                canGoNext &&
                  <Button
                      onClick={nextQuestion}
                      isLoading={nextQuestionIsLoading}
                  >{nextButtonTitle}{!isMobile && !isLastQn && <Kbd ml={'6px'}>→</Kbd>}
                  </Button>
              }
            </HStack>
        }
      </VStack>

      {
        stayInActiveAfterAnswering && !!savedAnsQuery.data?.QnID &&
          <Text fontSize={'xs'} textColor={'gray.500'}>Your answer will be marked at the end of the test.</Text>
      }


    </VStack>


  )
}

