import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  VStack,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  Input,
  Text,
  chakra,
  Textarea,
  HStack,
  Spacer,
  IconButton, useToast, useDisclosure, useColorModeValue
} from "@chakra-ui/react"
import {useEffect, useState} from "react";
import {ChatBotService, ChatCompletion} from "../../Services/ChatBotService";
import {AiOutlineRobot, FiThumbsDown, FiThumbsUp} from "react-icons/all";
import {useMutation} from "react-query";
import {useAuth} from "../../Contexts/AuthContext";
import {CopyIcon} from "@chakra-ui/icons";
import {useClipboard} from "../../Hooks/useClipboard";
import useLocalStorage from "../../Hooks/use-local-storage";
import ReactMarkdown from "react-markdown";

export const ChatBotButton = () => {
  const auth = useAuth()
  const [userMsg, setUserMsg] = useState<string>('')
  const [chatBotResp, setChatBotResp] = useState<ChatCompletion | null>(null)
  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {onCopy, hasCopied} = useClipboard();
  const {isOpen, onToggle, onClose} = useDisclosure()
  const [seenBotIntro, setSeenBotIntro] = useLocalStorage<boolean | null>("bot-intro", false)
  const introColor = useColorModeValue('brand2.50', 'brand2.900')
  const userIsSubscribedAndHasYetSeenIntro = (!seenBotIntro && auth.user != null && (auth.testAuth(1) || auth.testAuth(2)))


  useEffect(() => {
    // to open up the bot intro if it has not been seen yet
    if (seenBotIntro || auth.user == null || !(auth.testAuth(1) || auth.testAuth(2))) return
    if (!isOpen) onToggle()
  }, [seenBotIntro, auth.user])
  const sendPrompt = () => {
    if (!(auth.testAuth(1) || auth.testAuth(2))) {
      toast({
        title: "Not subscribed!",
        description: "You need an active subscription to use the AI knowledge base.",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,

      })
      return
    }
    setIsLoading(true)
    const resp = ChatBotService.getChatBotResp(userMsg)
    resp.then((r) => {
      setChatBotResp(r)
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const sendFeedback = (feedback: string) => {

    if (!chatBotResp) return

    const resp = ChatBotService.sendChatBotFeedback(chatBotResp.ID, feedback)
    resp.then((r) => {
      toast({
        title: "Feedback sent",
        description: "Thank you for your feedback",
        status: "success",
        duration: 5000,
        position: "top",
        isClosable: true,

      })
    }).catch(err => {
    })
  }
  const prepCopy = () => {
    if (!chatBotResp) return
    onCopy(
      'Question: ' + userMsg +
      '\nAnswer: ' + chatBotResp.Completion +
      `\n--generated by propellaBot`)
    toast({
      title: "Copied!",
      status: "success",
      duration: 1500,
      position: "top",
      isClosable: true,

    })
  }
  return (
    <Popover
      placement={'bottom-start'}
      closeOnBlur={false}
      isOpen={isOpen}

      isLazy>
      <PopoverTrigger>
        <IconButton onClick={() => {

          if (userIsSubscribedAndHasYetSeenIntro) {
            setSeenBotIntro(true)
            return
          }
          onToggle()

        }} icon={<AiOutlineRobot/>} aria-label={'propella bot'}/>
      </PopoverTrigger>
      <Portal>
        {
          userIsSubscribedAndHasYetSeenIntro ?
            <PopoverContent bg={introColor} w={['90vw', '90vw', '600px']} mr={'12px'} zIndex={999} mt={'12px'}>
              <PopoverArrow bg={introColor}/>
              <PopoverHeader>
                <chakra.span fontWeight={700}>{"🤖 propellaBot: "}</chakra.span>
                Hello!</PopoverHeader>

              <PopoverCloseButton onClick={() => {
                setSeenBotIntro(true)
                onClose()
              }}
              />
              <PopoverBody zIndex={999} overflow={'auto'} maxH={'60vh'}>
                Try propellaBot! It's an AI knowledge base that answers any RES related questions!

              </PopoverBody>
            </PopoverContent>
            :

            <PopoverContent w={['90vw', '90vw', '600px']} mr={'12px'} zIndex={999} mt={'12px'}>
              <PopoverArrow/>
              <PopoverHeader>
                <chakra.span fontWeight={700}>{"🤖 propellaBot "}</chakra.span>
                (v2)</PopoverHeader>
              <PopoverCloseButton onClick={() => {
                onClose()
              }}
              />
              <PopoverBody zIndex={999} overflow={'auto'} maxH={'60vh'}>


                <VStack alignItems={'flex-start'}>
                  <Textarea placeholder="Who can buy a HDB? What is the ABSD for a Singaporean?" value={userMsg}
                            onChange={e => setUserMsg(e.target.value.substring(0, 1000))}/>
                  <HStack width={'full'}>
                    <Spacer/>
                    <Button variant={'ghost'} onClick={
                      () => {
                        setUserMsg("")
                        setChatBotResp(null)
                      }}>Clear</Button>
                    {

                    }
                    <Button
                      isLoading={isLoading}
                      colorScheme='brand'
                      onClick={() => sendPrompt()}
                    >
                      Ask
                    </Button>
                  </HStack>
                  {
                    (chatBotResp?.Completion.length ?? 0) > 0 &&
                      <Text fontWeight={'700'}>Response</Text>

                  }

                  {
                    chatBotResp?.Completion.split('\n').map((item, i) => {
                      return <ReactMarkdown linkTarget={'_blank'} className={'markdown-body'} key={i}>{item}</ReactMarkdown>
                    })
                  }
                  {
                    (chatBotResp?.Completion.length ?? 0) > 0 &&
                      <HStack width={'full'}>
                          <Spacer/>
                          <IconButton variant={'outline'}
                                      bg={hasCopied ? 'brand.500' : undefined}
                                      color={hasCopied ? 'white' : undefined}
                                      onClick={() => prepCopy()}
                                      icon={<CopyIcon/>}
                                      aria-label={"copy"}/>
                          <IconButton variant={'outline'} colorScheme='brand' onClick={() => sendFeedback("thumbsup")}
                                      icon={<FiThumbsUp/>}
                                      aria-label={"thumbs up"}/>
                          <IconButton variant={'outline'} colorScheme='brand' onClick={() => sendFeedback("thumbsdown")}
                                      icon={<FiThumbsDown/>}
                                      aria-label={"thumbs down"}/>
                      </HStack>

                  }

                </VStack>

              </PopoverBody>
              <PopoverFooter fontSize={'sm'} color={'gray.500'}>WARNING: information provided can be inaccurate.</PopoverFooter>
              <PopoverFooter fontSize={'sm'} color={'gray.500'}>Knowledge cutoff: Oct 2023</PopoverFooter>
            </PopoverContent>
        }
      </Portal>
    </Popover>
  )
}