import React, {FC, useEffect, useState} from "react";
import {RMessage, UserPublicInfo} from "../../Models/Chat";
import {useQuery} from "react-query";
import {ChatService} from "../../Services/ChatService";
import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Spacer,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue, VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {Icon} from "@chakra-ui/icons";
import {GiRoundStar} from "react-icons/all";
import {useAuth} from "../../Contexts/AuthContext";
import {VoteControl} from "./VoteControl";

export const MessageResponsesBox: FC<{
  messageID: string,
  forceRefetchReplies?: boolean,
  setForceRefetchReplies?: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({messageID, forceRefetchReplies, setForceRefetchReplies}) => {
  const msgsQuery = useQuery(['msg-replies', messageID], ChatService.getRepliesToMessage(messageID))
  const secondaryBorderColor = useColorModeValue('1px solid rgb(200,200,200)', '1px solid rgb(50,50,50)')
  const repliesBoxBgColor = useColorModeValue('gray.100', 'rgba(255,255,255,0.02)')
  useEffect(() => {
    if (forceRefetchReplies) {
      msgsQuery.refetch()
      setForceRefetchReplies?.(false)
    }
  }, [forceRefetchReplies, msgsQuery, setForceRefetchReplies])
  return (
    <Box maxH={'60vh'}
         overflowY={'scroll'}
         backgroundColor={repliesBoxBgColor}
         mt={'15px'}
         borderRadius={'6px'}
    >      {
      msgsQuery.data?.map((msg, i) => (

          <Box
            key={msg.ID}
            borderBottom={(i + 1 !== msgsQuery.data?.length!) ? secondaryBorderColor : 'none'}
            p={'10px'}
          >

            <MessageResponseBox message={msg} onVotingSuccess={msgsQuery.refetch}/>
          </Box>
        )
      )
    }
    </Box>
  )
}
const MessageResponseBox: FC<{
  message: RMessage,
  onVotingSuccess: () => {}
}> = ({message, onVotingSuccess}) => {
  const usrInfoQuery = useQuery(['user', message.UserID], ChatService.getUserPublicInfo, {
    staleTime: 1e3 * 60 * 60 * 24,
    cacheTime: 1e3 * 60 * 60 * 24,
  })
  const dimFontColor = useColorModeValue("gray.400", "gray.600")
  const createdAtDate = dayjs(message.CreatedAt)
  const [isExpanded, setIsExpanded] = useState(false)
  const isExpandable = message.Body.length > 150
  const collapsedText = isExpandable ? message.Body.substr(0, 150) + '...' : ''
  const expandedText = message.Body
  const auth = useAuth()
  return (
    <Box flexGrow={2}>

      {/*{*/}
      {/*  message.Body.split('\n').map(t => <ReactMarkdown className={'markdown-body'} key={t}>{t}</ReactMarkdown>)*/}
      {/*}*/}
      {isExpandable
        ? isExpanded
          ? <>{
            expandedText.split('\n').map(t => <Text>{t}</Text>)
          }
          </>
          : <>{
            <Text>{collapsedText.split('\n')[0]}</Text>
          }

          </>
        : expandedText.split('\n').map(t => <Text>{t}</Text>)
      }

      <Flex mt={'5px'} alignItems={'baseline'} flexDirection={'row'}>
        {
          <VoteControl messageID={message.ID}
                       onSuccess={() => {
                         onVotingSuccess()
                       }}
                       voteCount={message.VoteCount}
                       userVote={message.UserVote ?? 0}
                       isHorizontal={true}
          />
        }

        <Spacer/>
        {
          dayjs(message.UpdatedAt).isAfter(dayjs(message.CreatedAt)) ??
          <chakra.p
              fontSize="xs"
              color={dimFontColor}
          >
              Edited
          </chakra.p>
        }
        <Box flexGrow={4}/>
        {
          isExpandable &&
          <Button size={'xs'} variant={'ghost'} onClick={() => {
            setIsExpanded(!isExpanded)
          }}>{
            isExpanded ? 'Collapse' : 'Expand'
          }</Button>
        }
        {
          usrInfoQuery.data !== undefined &&
          <GetNameDatePlate
              usrInfo={usrInfoQuery.data}
              isLoggedInUser={auth.user?.ID === message.UserID}
              createdAtDate={createdAtDate}
              foldable={true}
          />
        }


      </Flex>
    </Box>

  )
}

export function GetNameDatePlate(
  props:
    {
      usrInfo: UserPublicInfo,
      isLoggedInUser: boolean,
      createdAtDate: dayjs.Dayjs,
      foldable: boolean
    }) {
  const dimFontColor = useColorModeValue("gray.600", "gray.400")
  const isLoggedInUserColor = useColorModeValue("brand2.500", "brand2.200")
  const starColor = useColorModeValue("brand.200", "brand.400")
  const isNotSmall = useBreakpointValue({base: false, sm: true})

  const getContent = () => {
    return (
      <>
        <Text
          fontSize="xs"
          fontWeight={'semibold'}
          color={props.isLoggedInUser ? isLoggedInUserColor : dimFontColor}
        >
          <Tooltip label={"rep: "+(props.usrInfo.Rep ?? "0")+ "💎"}>

          {props.usrInfo.DName}
          </Tooltip>
          {
            props.usrInfo.IsMod &&
            <Tooltip label={"moderator"}>
              <span>
                  <Icon
                      fontSize={'0.7em'}
                      color={starColor}
                      as={GiRoundStar}/>
              </span>
            </Tooltip>
          }
        </Text>
        {
          props.foldable && isNotSmall && <chakra.p>•</chakra.p>
        }
        {
          !props.foldable && <chakra.p>•</chakra.p>
        }

        <Text
          fontSize={'xs'}
          color={dimFontColor}
        >
          {props.createdAtDate.format('D MMM \'YY')} at {props.createdAtDate.format('HH:mm')}
        </Text>
      </>
    )
  }
  return (
    props.foldable ?
      isNotSmall?

        <HStack spacing={'6px'} alignItems={'baseline'} flexShrink={0}>{getContent()}</HStack>:
        <VStack flexShrink={0} spacing={-1} alignItems={'flex-end'}>{getContent()}</VStack>

      :
      <HStack spacing={'6px'} alignItems={'baseline'} flexShrink={0}>{getContent()}</HStack>
  )
}
