import React, {FC} from "react";
import {Box, Center, Container, Flex, Heading, chakra, Text} from "@chakra-ui/react";
import {ActiveAttemptHeader} from "../ProblemSet/TopicProbSetAttemptHeader";
import {QnState} from "../../Models/Question";
import dayjs from "dayjs";

export const MockTest:FC<{}> = ({}) => {
  // const bgGrad = useColorModeValue("linear(180deg,rgba(255, 226, 237, 1),rgba(15, 40, 232, 0))"
  //   ,"linear(180deg,rgba(100, 0, 49, 1),rgba(15, 40, 232, 0))")
return (
  <Box
    pt={'80px'}
    pb={'80px'}
    // bg={useColorModeValue('gray.100', 'gray.800')}
    // bgGradient={bgGrad}

  >

    <Container maxW={'container.xl'} px={10}>
      <Heading
        textAlign={'center'}
        fontSize={'4xl'}
        mb={'20px'}
      >
        Test Flight
      </Heading>
      <Center>
        <Text fontSize={'sm'} textAlign={'center'} mb={'30px'}>
          Soar to greater heights with
          <chakra.span fontWeight={700} color={'brand.400'}>
            &nbsp;dynamic timed mock tests
          </chakra.span>.
        </Text>
      </Center>
      <Flex alignItems={['center', 'center', 'center','flex-start']} flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'row']}>
        <Box w={['100%', '100%', '100%','50%']} p={'15px'}>
          <Text pb={'20px'}>
            During the actual exam, you would have to do both papers in a limited amount of time.
            We have built a test engine that enables you to practise just that.

            </Text>
          <Text>
            Each dynamic mock test pulls a
            <chakra.span fontWeight={700} color={'brand2.400'}>

              &nbsp;fresh set of questions&nbsp;
            </chakra.span>
            from our question bank. There's a timer to help you stay on pace.
            If you cannot do a question, skip it and return to it later. At the end of the test, mark your answers and view our explanations.

          </Text>
        </Box>
        <Box
          // transform={'rotate3d(0.5, -0.866, 0, 15deg) rotateZ(-1deg)'}
          // transform={'rotate3d(0.5,0,0.5 45deg)'}
          mb={'20px'}
        >
          <ActiveAttemptHeader
            attempt={{
              ID: "",
              UserID: "",
              Score: null,
              ProblemSetID: "",
              QuestionsDone: 5,
              DelayMarking: true,
              CreatedAt: dayjs().add(-8, 'minute').format(),
            }}
            page={5}
            setPage={undefined}
            isTest={true}
            qnStates={[
              QnState.answered,
              QnState.answered,
              QnState.answered,
              QnState.answered,
              QnState.answered,
              QnState.unanswered,
              QnState.unanswered,
              QnState.unanswered,
              QnState.unanswered,
              QnState.unanswered,
            ]}
          />
          <Text textAlign={'center'} fontSize={'xs'}>The progress + timer bar is available in every mock test.</Text>
        </Box>
      </Flex>


    </Container>
  </Box>
)
}