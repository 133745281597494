import {CHMessage} from "../Models/Chat";
import {ApiClient} from "./APIClient";

export interface ChatCompletion {
  ID: string
  Prompt: string
  Completion: string
}

const getChatBotResp = async (ppp: string): Promise<ChatCompletion> => {
  return await ApiClient.get(`/api/chatbot`, {
    params: {
      prompt: ppp
    }
  })
    .then(resp => resp.data.Response)
}

const sendChatBotFeedback = async (ccid: string, feedback: string): Promise<void> => {
  // feedback will be ThumpsUp or ThumpsDown
  return await ApiClient.get(`/api/chatbot/${ccid}/feedback`, {
    params: {
      feedback: feedback
    }
  })
}

export const ChatBotService = {
  getChatBotResp,
  sendChatBotFeedback
}