import {ApiClient} from "./APIClient";
import {PaperData, Question2} from "../Models/Question";
import {Paper} from "../Models/Paper";

const GetPaperData = (tenantID: string, paperID: string, hideGenerated: boolean): () => Promise<PaperData> => {
  return async () => ApiClient.get(`/api/tenant/${tenantID}/paper/${paperID}?hideGenerated=${hideGenerated}`).then(resp => resp.data.Response)
}

const GetPapers = (tenantID: string): () => Promise<Paper[]> => {
  return async () => ApiClient.get(`/api/tenant/${tenantID}/paper`).then(resp => resp.data.Response)
}

const GetQuestions = (tenantID: string, searchStr: string, page: number, orderBy?: string): () => Promise<Question2<any>[]> => {
  return async () => await ApiClient
    .get(`/api/tenant/${tenantID}/questions?pageSize=10&page=${page}&sql=${searchStr}&orderBy=${orderBy}`)
    .then(resp => resp.data.Response)
}

const DeleteQuestion = async (props: { tenantID: string, questionID: string }): Promise<DeleteQuestionResp> => {
  return await ApiClient.delete(`/api/tenant/${props.tenantID}/questions/${props.questionID}`).then(resp => resp.data.Response)
}

interface DeleteQuestionResp {
  ProblemSetCount: number
  DeletedProblemSets: string[]
  AnsHistDelCount: number
  SavedQnDelCount: number
}

export const QnMgrService = {
  GetQuestions,
  DeleteQuestion,
  GetPapers,
  GetPaperData
}