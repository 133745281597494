import React, {FC} from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  VStack,
  Text, Center, useColorModeValue, Tag
} from "@chakra-ui/react";

export const PaperContent: FC = () => {

  // const questionCountQuery = useQuery('qn-count', QuestionService.getQuestionCount, {
  //   staleTime: 1000*60*1000,
  //   cacheTime: 1000*60*1000,
  // })
  return (
    <Box
      pt={'80px'}
      pb={'80px'}
      bg={useColorModeValue('brand2.50', 'brand2.900')}
    >
      <Container maxW={'container.xl'} px={10}>
        <Heading
          textAlign={'center'}
          fontSize={'4xl'}
          mb={'20px'}
        >
          Boatload of questions,
        </Heading>
        <Center>
          <Text fontSize={'sm'} textAlign={'center'} mb={'30px'}>
            Our questions are created and maintained by qualified and practising real estate agents that have excelled at the RES exam.</Text>
        </Center>
        <SimpleGrid columns={2} w={'full'} mb={'20px'}>
          <VStack spacing={0} alignItems={'center'}>
            <Text fontWeight={'500'}>MCQs</Text>
            <Text fontSize={'4xl'} fontWeight={'500'}>1446</Text>
            <Text fontSize={'sm'}>questions</Text>
          </VStack>
          <VStack spacing={'0'} alignItems={'center'}>
            <Text fontWeight={'500'}>CSQs</Text>
            <Text fontSize={'4xl'} fontWeight={'500'}>11</Text>
            <Text fontSize={'sm'}>case studies</Text>
          </VStack>
        </SimpleGrid>

        <Heading
          pt={'60px'}
          textAlign={'center'}
          fontSize={'4xl'}
          mb={'20px'}
        >
          and the latest curriculum.
        </Heading>
        <Center>
            <Text mb={'30px'} fontSize={'sm'} textAlign={'center'}>
              Updated with the latest changes as of May 2024! &nbsp;
              <Tag size={'sm'} mr={'10px'} variant={'solid'} colorScheme={'brand'}>NEW</Tag>

            </Text>

        </Center>
      </Container>

      <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>
        <VStack
          pt={'10px'}
          borderRadius={'md'}
          alignItems={{base: 'center', md: 'flex-end'}}
        >
          <Heading fontSize={'md'}>Paper 1</Heading>
          <Topic title={"Basic Land Law Concepts"}/>
          <Topic title={"Interest in Land"}/>
          <Topic title={"Landlord and Tenant Law / Law of Tort"}/>
          <Topic title={"Law of Contract and Agency"}/>
          <Topic title={"Planning and Development of Land"}/>
          <Topic title={"Real Estate Markets"}/>
        </VStack>
        <VStack
          pt={'10px'}
          borderRadius={'md'}
          alignItems={{base: 'center', md: 'flex-start'}}
        >
          <Heading fontSize={'md'}>Paper 2</Heading>
          <Topic title={"Real Estate Marketing"}/>
          <Topic title={"Regulations, Roles and Functions of REA / RES"}/>
          <Topic title={"Sale of Real Properties"}/>
          <Topic title={"Leasing of Real Properties"}/>
          <Topic title={"Real Estate Taxes and Finance"}/>
        </VStack>

      </SimpleGrid>
    </Box>
  )
}

const Topic = ({title}: { title: string }) => {
  return (
    <Heading pb={'10px'} fontSize={{base: 'md', md: '2vw'}}>{title}</Heading>
  );
};
