import {FC, useEffect, useState} from "react"
import {SAQuestion2} from "../../Models/Question";
import {HStack, Icon, Input, Tag, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import {IoClose, IoMdCheckmark,} from "react-icons/all";
import dayjs from "dayjs";
import {QLState} from "./QuestionLoader";

export const SAQView: FC<{
  question: SAQuestion2,
  usrHistoricAns: string | undefined,
  loaderState: QLState,
  // submitAnswer?: (ans: string) => void,
  tempUsrAns?: string,
  setTempUsrAns: React.Dispatch<React.SetStateAction<string | undefined>>,
  setReadyForSubmit: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
        question,
        usrHistoricAns,
        loaderState,
        tempUsrAns,
        setTempUsrAns,
        setReadyForSubmit
      }) => {
  const [body, setBody] = useState("")

  // replace {{b}} with blanks
  useEffect(() => {
    let count = 1
    let x = question.Body.split("{{b}}").reduce((out, part) => {
      out = out + part + "`____A" + count.toString() + "____`"
      count++
      return out
    }, "")
    count--
    x = x.replace("`____A" + count.toString() + "____`", "")
    setBody(x)
  }, [question.Body])

  const [fieldCorrectness, setFieldCorrectness] = useState<boolean[]>([])
  useEffect(() => {
    if (usrHistoricAns === undefined) {
      setReadyForSubmit(false)
      let tempUserAnswers = [] as string[]

      for (let i = 0; i < question.Answer.split("~").length; i++) {
        tempUserAnswers.push("")
      }

      setTempUsrAns(tempUserAnswers.join("~"))
    } else {
      // when the user has answered this question...
      setTempUsrAns(usrHistoricAns)

      // calculate correct or wrong for all fields
      let correctness = [] as boolean[]
      const answers = question.Answer.split("~")
      const usrAnswers = usrHistoricAns?.split("~")

      answers.forEach((answer, g) => {
        const potentialSubAnswers = answer.split("|")
        let correct = false
        for (let i = 0; i < potentialSubAnswers.length; i++) {
          if (usrAnswers[g].toLowerCase().trim() === potentialSubAnswers[i].toLowerCase().trim()) {
            correct = true
            break
          }
        }
        correctness.push(correct)
      })
      setFieldCorrectness(correctness)
    }
  }, [usrHistoricAns])

  const handleInput = (e: any) => {
    let tempUserAnswers: string[]
    tempUserAnswers = (tempUsrAns as String).split("~")
    tempUserAnswers[e.target.id] = e.target.value
    const tus = tempUserAnswers.join("~")
    let tempReadyForSubmit = true
    tempUserAnswers.forEach(ans => {
      if (ans === "") {
        tempReadyForSubmit = false
      }
    })
    setTempUsrAns(tus)
    setReadyForSubmit(tempReadyForSubmit)

  }

  // const [tempUserAnswers, setTempUserAnswers] = useState([])
  // useEffect(()=> {
  //   const x = (tempUsrAns as String).split("~")
  //   setTempUserAnswers((tempUsrAns as String) ?? "").split('~'))
  // }, [tempUsrAns])

  return (
    <VStack alignItems={'flex-start'} w={'full'}>
      {
        loaderState === QLState.review &&
        usrHistoricAns === undefined &&
        <Tag colorScheme={'red'} variant={'outline'}>SKIPPED</Tag>
      }
      {/*<Text>{question.ID}</Text>*/}
      {/*<Text>{question.Answer}</Text>*/}
      <ReactMarkdown>{body}</ReactMarkdown>
      {
        question.Answer.split("~").map((x, i) => (
          <SAQInputField
            key={x + i}
            idx={i}
            handleInput={handleInput}
            isRightAnswer={
              loaderState !== QLState.active ?
                usrHistoricAns === undefined ?
                  undefined :
                  fieldCorrectness[i] :
                undefined}
            answer={loaderState !== QLState.active ? x : undefined}
            value={!!tempUsrAns ? tempUsrAns?.split("~")[i] : ""}
          />
        ))
      }
      {
        loaderState !== QLState.active && !!question.Explanation &&
        <>
            <Text pt={'20px'} pb={'10px'} textStyle={'h5'}>Explanation</Text>
          {
            question.Explanation?.split('\n').map(t =>
              <ReactMarkdown
                linkTarget={'_blank'}
                className={'markdown-body'} key={t}>{t}</ReactMarkdown>)
          }

        </>
      }

    </VStack>
  )
}

const SAQInputField: FC<{
  idx: number,
  handleInput: (e: any) => void,
  isRightAnswer?: boolean,
  value?: string
  answer?: string
}> = ({idx, handleInput, isRightAnswer, value, answer}) => {
  let light = 'gray.100'
  let dark = 'gray.700'
  if (isRightAnswer === true) {
    light = 'green.300'
    dark = 'green.700'
  } else if (isRightAnswer === false) {
    light = 'red.300'
    dark = 'red.700'
  }
  const bgCol = useColorModeValue(light, dark)
  return (
    <HStack w={'full'} pb={'10px'}>
      <Text>{"A" + (idx + 1).toString()}</Text>
      <VStack w={'full'} alignItems={'flex-start'}>
        {
          <Input
            bg={bgCol}
            id={idx.toString()}
            aria-label={"A" + (idx + 1).toString()}
            disabled={!!answer || !!isRightAnswer}
            value={value}
            onChange={handleInput}
          />
        }

        {
          answer && <Text pl={'5px'}>{answer?.replaceAll("|", " or ")}</Text>
        }
      </VStack>

      {
        isRightAnswer !== undefined && (isRightAnswer
          ? <Icon w={'36px'} h={'36px'}><IoMdCheckmark/></Icon>
          : <Icon w={'36px'} h={'36px'}><IoClose/></Icon>)
      }
    </HStack>
  )
}