import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {NoticeBanner, UpdateBanner} from "../../Banner";
import {Box, Button, Container, Heading, Text, VStack} from "@chakra-ui/react";
import {QuestionSetReviewPageView} from "./QuestionSetReviewView";
import React from "react";

export const UpdatedQns = () => {

  // @ts-ignore
  const {paperID} = useParams()
  const hist = useHistory()
  const updatedQnsQuery = useQuery(['paper', 'updated', paperID], QuestionService.getUpdatedQuestions, {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  })
  return (
    <Box>
      <UpdateBanner/>
      <NoticeBanner/>
      <Container maxW="container.lg" pt={'20px'}>
        <VStack width={'full'} alignItems={'flex-start'}>
          <Button variant={'link'} onClick={() => hist.push(`/paper/${paperID}`)}>Back to paper {paperID}</Button>
          <Heading size={'lg'}>Paper {paperID}</Heading>
          <Heading size={'xl'}>Recently updated questions</Heading>
          <Text color={'gray.500'}>{updatedQnsQuery.data?.length} question(s) were updated in the past 14 days</Text>
          {
            <QuestionSetReviewPageView
              idx={0}
              questionIDs={updatedQnsQuery.data?.map(q => q.ID) ?? []}
              totalQnCount={updatedQnsQuery.data?.length ?? 0}
            />
          }
        </VStack>
      </Container>
    </Box>
  )
}