import {FC, useEffect, useState} from "react";
import {Box, Center, Container, Divider, Spinner,} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import _ from "lodash";
import {useHistory, useParams} from "react-router-dom";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {QLState, QuestionLoader} from "../Question/QuestionLoader";
import {PageControl} from "../PageControl";
import {TopicProbSetAttemptHeader} from "../ProblemSet/TopicProbSetAttemptHeader";

const PAGE_SIZE = 10

// Used for users who access this with the url
export const QuestionSetReviewLoader: FC = () => {
  // @ts-ignore
  let {setID} = useParams()
  let tempAttID = useURLQuery().get('attemptID')

  return (
    <QuestionSetReviewView setID={setID} attemptID={tempAttID !== null ? tempAttID : undefined}/>
  )
}


export const QuestionSetReviewView: FC<{
  setID: string,
  attemptID?: string
}> = ({setID, attemptID}) => {
  const hist = useHistory()
  // const [attemptID, setAttemptID] = useState<string | undefined>(undefined)
  useEffect(
    () => {
      !!attemptID && attemptQuery.refetch()
    }, [attemptID]
  )

  const probSetQuery = useQuery(["set", setID], QuestionService.getSetInfo(setID))
  const topicQuery = useQuery(["topic", probSetQuery.data?.ProblemSet.TopicID!],
    QuestionService.getTopicInfo(probSetQuery.data?.ProblemSet.TopicID!),
    {
      enabled: !!probSetQuery.data?.ProblemSet.TopicID,
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 30,
    })
  const attemptQuery = useQuery(['att', attemptID!], QuestionService.getAttemptInfo(attemptID!), {
    enabled: !!attemptID
  })

  const [pagedQuestions, setPagedQuestions] = useState([] as string[][])
  const questionsQuery = useQuery(
    ['set', setID!, 'qn-list'],
    QuestionService.getQuestionsForSet(setID!)
    ,
    {
      onSuccess: (data => {
        setPagedQuestions(_.chunk(data, PAGE_SIZE))

      }),
      cacheTime: 1000 * 60 * 10
    }
  )
  const [page, setPage] = useState(1)
  return (
    <Container maxW={'container.lg'} pt={'20px'}>
      {
        attemptQuery.isFetched ?
          <>
            <TopicProbSetAttemptHeader
              topic={topicQuery.data?.Name ? topicQuery.data : undefined}
              pSetWAtt={probSetQuery.data}
              attempt={attemptQuery.data}
            />

            {
              pagedQuestions.length > 1 &&
                <PageControl currentPage={page} pageCount={pagedQuestions.length} setPageNumber={setPage}/>
            }
            {
              pagedQuestions.length > 0 &&
                <QuestionSetReviewPageView
                    idx={page - 1}
                    questionIDs={pagedQuestions[page - 1]}
                    totalQnCount={questionsQuery.data?.length ?? 0}
                    attemptID={attemptID}
                />
            }
            {
              pagedQuestions.length > 1 &&
                <PageControl currentPage={page} pageCount={pagedQuestions.length} setPageNumber={setPage}/>
            }
          </>
          :
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>

      }

    </Container>
  )
}

export interface QnPageViewProps {
  idx: number;
  questionIDs: string[];
  totalQnCount: number;
  attemptID?: string,
  pageSize?: number,
  nextPage?: () => void,
  prevPage?: () => void,
}

export interface QnPageViewComponent<T extends QnPageViewProps> {
  (props: T): JSX.Element;
}

export const QuestionSetReviewPageView: QnPageViewComponent<QnPageViewProps> =
  ({idx, questionIDs, pageSize = 10, totalQnCount, attemptID}) => {
    return (
      <>
        {
          questionIDs.map((qnID, j) => {
            return (
              <Box key={qnID} w={'full'}>
                <QuestionLoader
                  stayInActiveAfterAnswering={false}
                  nextQuestionIsLoading={false}
                  qnNumber={idx * pageSize + j + 1}
                  totalQnCount={totalQnCount}
                  qnID={qnID}
                  attemptID={attemptID}
                  nextQuestion={undefined}
                  prevQuestion={undefined}
                  nextButtonTitle={undefined}
                  showScore={true}
                  defaultState={attemptID != null ? QLState.review : QLState.passive}
                  disableAccessoryButtonHotKeys={true}
                />
                <Divider mb={'20px'}/>
              </Box>
            )
          })
        }
      </>
    )
  }