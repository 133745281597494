import {ApiClient} from "./APIClient";
import {CHMessage, RMessage, UserPublicInfo} from "../Models/Chat";
import {QueryFunctionContext} from "react-query";

const createNewMessage = (resourceID: string):
  ({ReplyToMsgID, Body}: { ReplyToMsgID: string, Body: string }) => Promise<CHMessage> => {
  return async ({ReplyToMsgID, Body}) => await ApiClient.post(`/api/chat/threads/${resourceID}`, {
    ReplyToMsgID: ReplyToMsgID,
    Body: Body,
  }).then(resp => resp.data.Response)
}

const getMessagesForResource = (resourceID: string): () => Promise<CHMessage[]> => {
  return async () => await ApiClient.get(`/api/chat/threads/${resourceID}`)
    .then(resp => resp.data.Response)
    .then(messages => { // group child messages into root messages
      let out = (messages as CHMessage[] ?? []).filter(x => x.ReplyToMsgID === undefined)
      out.map(rootMsg => {
        rootMsg.Replies = (messages as CHMessage[]).filter(x => x.ReplyToMsgID === rootMsg.ID)
        return rootMsg
      })
      return out
    })
}

const getUserPublicInfo = async ({queryKey:[,userID]}:QueryFunctionContext<string[]>) : Promise<UserPublicInfo> => {
  return await ApiClient.get(`/api/user/${userID}`).then(resp => resp.data.Response)
}

const getLatestMessages = (page: number, onlyOwnMsgs?:boolean, onlyMsgsWithNoReplies?:boolean): () => Promise<PaginatedAPIResponse<RMessage[]>> => {
  let url = `/api/chat/messages?page=` + page
  if (onlyOwnMsgs) {
    url += '&onlyOwnMsgs=true'
  }

  if (onlyMsgsWithNoReplies) {
    url += '&onlyMsgsWithNoReplies=true'
  }

  return async () => await ApiClient.get(url).then(resp => resp.data)
}

const getRepliesToMessage = (replyToID: string): () => Promise<RMessage[]> => {
  return async () => await ApiClient.get(`/api/chat/messages?replyToID=${replyToID}`).then(resp => resp.data.Response)
}
const archiveComment = (messageID: string): () => Promise<undefined> => {
  return async () => await ApiClient.put(`/api/chat/messages/${messageID}/archive`).then(resp => resp.data.Response)
}
const deleteMessage = (messageID: string): () => Promise<undefined> => {
  return async () => await ApiClient.put(`/api/chat/messages/${messageID}/delete`).then(resp => resp.data.Response)
}
const voteOnMessage = (messageID: string): (props:{vote:string}) => Promise<undefined> => {
  return async (props:{vote:string}) => await ApiClient.put(`/api/chat/reaction/${messageID}/${props.vote}`).then(resp => resp.data.Response)
}

export const ChatService = {
  createNewMessage,
  getMessagesForResource,
  getUserPublicInfo,
  getLatestMessages,
  getRepliesToMessage,
  archiveComment,
  deleteMessage,
  voteOnMessage,
}

interface PaginatedAPIResponse<Response> {
  Page: number
  TPages: number
  PageSize: number
  Status: number
  Response: Response
  Error: string
}