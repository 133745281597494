import {
  AnswerHistory, MCQStat, PaperData,
  ProblemSetWithAttempt,
  Question2, SetAnswerResponse, Topic,
  UserAnswer,
  UserProblemSetAttempt
} from "../Models/Question";
import {ApiClient} from "./APIClient";
import {QueryFunctionContext} from "react-query";

const getQuestion = (qnID?: string): () => Promise<Question2<any>> => {
  return async () => await ApiClient.get("/api/questions/" + qnID ?? "").then(resp => resp.data.Response)
}

const getMCQStats = (qnID?: string): () => Promise<MCQStat> => {
  return async () => await ApiClient.get("/api/questions/mcq-stats/" + qnID ?? "").then(resp => resp.data.Response)
}

const getSampleQuestions = async (): Promise<Question2<any>[]> => {
  return await ApiClient.get("/api/questions/samples").then(resp => resp.data.Response)
}

// const getUserAnswerHistory = (qnID: string, attemptID: string): () => Promise<AnswerHistory | undefined> => {
//   return async () => await ApiClient.get('').then(resp => resp.data.Response)
// }

const getTopics = async (): Promise<Topic[]> => {
  return await ApiClient.get("/api/topic").then(resp => resp.data.Response)
}

const favouriteQuestion = async (qnID: string): Promise<string> => {
  return await ApiClient.put(`/api/questions/${qnID}/fav`).then(resp => resp.data.Response)
}

const unFavouriteQuestion = async (qnID: string): Promise<string> => {
  return await ApiClient.put(`/api/questions/${qnID}/un-fav`).then(resp => resp.data.Response)
}

const getAllFavQuestions = (paper: string): () => Promise<string[]> => {
  return async () => await ApiClient.get(`/api/questions/fav?paper=${paper}`).then(resp => resp.data.Response)
}

const getAllSets = async (): Promise<ProblemSetWithAttempt[]> => {
  return await ApiClient.get("/api/sets").then(resp => resp.data.Response)
}

const getQuestionsForSet = (setID: string): () => Promise<string[]> => {
  return async () => await ApiClient.get(`/api/sets/${setID}/questions`).then(resp => resp.data.Response)
}


const getAnswersForSet = (setID: string): () => Promise<SetAnswerResponse[]> => {
  return async () => await ApiClient.get(`/api/sets/${setID}/answers`).then(resp => resp.data.Response)
}

const getLatestAttemptForSet = (setID: string): () => Promise<UserProblemSetAttempt | undefined> => {
  return async () => await ApiClient.get(`/api/sets/${setID}/attempt/last`).then(resp => {
    if (resp.status === 200) {
      return resp.data.Response
    }
    return undefined
  })
}

const getUpdatedQuestions = async ({queryKey: [y, x, paperID]}: QueryFunctionContext<string[]>): Promise<Question2<any>[]> => {
  return await ApiClient.get(`/api/questions/updated/${paperID}`).then(resp => resp.data.Response)
}

const getDynamicProblemSetAttempts = (topicID?: string, paperID?: string): () => Promise<UserProblemSetAttempt[]> => {
  return async () => await ApiClient.get(`/api/dynamic-sets/attempts`,
    {params: {topic_id: topicID, paper_id: paperID}}).then(resp => resp.data.Response)
}

const getOrCreateDynamicProblemSetAttempt = async (props: { topicID?: string, paperID?: string }): Promise<UserProblemSetAttempt> => {
  return await ApiClient.get(`/api/dynamic-sets/attempts/new`,
    {params: {topic_id: props.topicID, paper_id: props.paperID}}).then(resp => resp.data.Response)
}

const getQuestionCount = async (): Promise<number[]> => {
  return await ApiClient.get('/api/questions/count').then(resp => resp.data.Response)
}

const getAttempts = (setID?: string): () => Promise<UserProblemSetAttempt[]> => {
  return async () => await ApiClient.get(`/api/attempts`, {params: {pset: setID}}).then(resp => resp.data.Response)
}

const getSetInfo = (setID: string): () => Promise<ProblemSetWithAttempt> => {
  return async () => await ApiClient.get(`/api/sets/${setID}`).then(resp => resp.data.Response)
}

const getHistoricAnswer = (attemptID: string, qnID: string): () => Promise<AnswerHistory | undefined> => {
  return async () => await ApiClient.get(`/api/attempts/${attemptID}/questions/${qnID}/answer`).then(resp => resp.data.Response)
}

const getAttemptInfo = (attemptID: string): () => Promise<UserProblemSetAttempt> => {
  return async () => await ApiClient.get(`/api/attempts/${attemptID}`).then(resp => resp.data.Response)
}

const getPaperData = (paperID: number): () => Promise<PaperData> => {
  return async () => ApiClient.get(`/api/paperv2/${paperID}`).then(resp => resp.data.Response)
}
const getTopicInfo = (topicID: string): () => Promise<Topic> => {
  return async () => ApiClient.get(`/api/topic/${topicID}`).then(resp => resp.data.Response)
}

const getUserAnswersForAttempt = (attemptID: string): () => Promise<AnswerHistory[]> => {
  return async () => await ApiClient.get(`/api/attempts/${attemptID}/answers`).then(resp => {
      return resp.data.Response
    }
  )
}

const markQuestionAsDone = async (props: { attemptID: string, questionID: string, answer: UserAnswer }): Promise<AnswerHistory> => {
  return await ApiClient.post(`/api/attempts/${props.attemptID}/questions/${props.questionID}/done`, props.answer).then(resp => {
      return resp.data.Response
    }
  )
}

const startNewAttempt = async (props: { setID: string, delayMarking: boolean }): Promise<UserProblemSetAttempt> => {
  return await ApiClient.put(`/api/sets/${props.setID}/attempt?delayMarking=${props.delayMarking}`).then(resp => resp.data.Response)
}

const markSetAsDone = async (setID: string): Promise<UserProblemSetAttempt> => {
  return await ApiClient.put(`/api/sets/${setID}/done`).then(resp => resp.data.Response)
}

const deleteOngoingAttempt = async (attemptID: string): Promise<undefined> => {
  return await ApiClient.delete(`/api/attempts/${attemptID}`).then(resp => resp.data.Response)
}

export const QuestionService = {
  getQuestion,
  getSampleQuestions,
  getMCQStats,
  getAllSets,
  getTopics,
  getTopicInfo,
  getAttemptInfo,
  getSetInfo,
  getPaperData,
  getQuestionsForSet,
  getAnswersForSet,
  getAttempts,
  getLatestAttemptForSet,
  getHistoricAnswer,
  markQuestionAsDone,
  markSetAsDone,
  getUserAnswersForAttempt,
  startNewAttempt,
  favouriteQuestion,
  unFavouriteQuestion,
  getUpdatedQuestions,
  getAllFavQuestions,
  getQuestionCount,
  getDynamicProblemSetAttempts,
  getOrCreateDynamicProblemSetAttempt,
  deleteOngoingAttempt
}