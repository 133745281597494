import {FC} from "react";
import {Box, Center, Tooltip, useColorModeValue} from "@chakra-ui/react";

export const ProblemSetAttemptStatusIndicator:FC<{state:string, pSetTitle:string}> = ({state, pSetTitle}) => {
  const ringBorderColor = useColorModeValue(
    'inset rgba(38, 77, 200, 0.2) 2px 2px 10px 0px',
    'inset rgba(38, 77, 200, 0.5) 1px 1px 10px 0px')
  const ringColorDone = useColorModeValue(
    'brand.200',
    'brand.600')
  return (
    <Tooltip label={pSetTitle + " : "+state} fontSize="md">
      <Center
        mr='4px'
        w={'20px'}
        h={'20px'}
        // border={"1px solid"}
        boxShadow={ringBorderColor}

        borderRadius={'full'}>
        <Box
          w={state !== 'ongoing' ?'20px': '10px'}
          h={state !== 'ongoing' ?'20px': '10px'}
          // border={"1px solid"}
          bg={state === 'done' ?
            ringColorDone :
            state === 'not started' ?
              undefined
              : ringColorDone
          }
          borderRadius={'full'}>

        </Box>
      </Center>
    </Tooltip>
  )
}