import React, {FC} from 'react'
import {useForm} from 'react-hook-form'
import {useMutation} from 'react-query'
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text, Center,
} from '@chakra-ui/react'
import {AuthService} from "../../Services/AuthService";
import {getApiErrorMessage} from "../../Services/APIClient";


interface OtpFormProps {
  onSuccess: (email: string) => void
  passCode: (code: string) => void
}

export const OtpForm: FC<OtpFormProps> = ({onSuccess, passCode}) => {
  const {register, handleSubmit, formState: {errors}} = useForm<{ email: string }>()
  const sendOtp = useMutation(AuthService.getOTP, {
    onSuccess: (code, email, xxx) => {
      onSuccess(email)
      passCode(code)
    },
  })

  const onSubmit = (data: { email: string }) => {
    sendOtp.reset()
    const {email} = data
    sendOtp.mutate(email)
  }

  const hasError = () => !!errors.email || sendOtp.isError

  const getErrorMessage = (): string => {
    const {email} = errors
    return email && email.type === 'required'
      ? 'Please provide a valid email address'
      : getApiErrorMessage(sendOtp.error)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="32px" align="stretch">
        <FormControl id="email" isInvalid={hasError()}>
          <FormLabel color="#11263C" title={"Email"}/>
          <Text color="gray.500" mb="24px">
            You will receive an OTP in your inbox for logging in or signing up.
          </Text>
          <Input
            h="48px"
            type="email"
            {...register("email",
              {
                required: true,
              })}
            placeholder="hello@example.com"
          />
          {hasError() && (
            <FormErrorMessage>{getErrorMessage()}</FormErrorMessage>
          )}
        </FormControl>
        <Box>
          <Button
            size="lg"
            isLoading={sendOtp.isLoading}
            type="submit"
            color={'white'}
            bgColor={'brand.700'}
          >
            Submit
          </Button>
        </Box>
        <Center>OR</Center>
        <Box id={'google-signin'}/>
        <Text color="gray.500">*If you already have a propella account, ensure that you're signing in with a Google account of the same
          email.</Text>
      </VStack>
    </form>
  )
}
