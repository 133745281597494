import {FC} from "react";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {MCQEditView} from "./MCQEditView";
import {Box} from "@chakra-ui/react";
import {SAQEditView} from "./SAQEditView";
import {CSQEditView} from "./CSQEditView";

export const QuestionEditor:FC<{
  qnID: string
  didEdit: ()=>void
}> = ({qnID, didEdit}) => {

  const qnQuery = useQuery(
    ['qns', qnID],
    QuestionService.getQuestion(qnID),
    {
      staleTime: 1000 * 60 * 1440, // 1 day
      cacheTime: 1000 * 60 * 1440,
    })

 return (
   <Box>
     {
       qnQuery.data && qnQuery.data.QnType === "mcq" &&
       <MCQEditView
           key={qnID}
           question={qnQuery.data!}
           didSave={()=>{
             qnQuery.refetch()
             didEdit()
           }}
           onDelete={()=>{qnQuery.refetch()}}
       />
     }
     {
       qnQuery.data && qnQuery.data.QnType === "saq" &&
       <SAQEditView
           key={qnID}
           question={qnQuery.data}
           didSave={()=>{
             qnQuery.refetch()
             didEdit()
           }}
           onDelete={()=>{
           qnQuery.refetch()
           }
           }
       />
     }
     {
       qnQuery.data && qnQuery.data.QnType === "csq" &&
       <CSQEditView
           key={qnID}
           question={qnQuery.data}
           didSave={()=>{
             qnQuery.refetch()
             didEdit()
           }}
       />
     }


   </Box>
 )
}