import React, {FC, useState} from "react";
import {
  Button,
  HStack,
  IconButton,
  Modal, ModalBody, ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, Tooltip, useDisclosure,
  VStack
} from "@chakra-ui/react";
import {BsArrowDown, BsArrowUp} from "react-icons/all";
import {useMutation} from "react-query";
import {ChatService} from "../../Services/ChatService";
import {useAuth} from "../../Contexts/AuthContext";

enum VoteDirection {
  Down = -1,
  Zero = 0,
  Up,

}

export const VoteControl: FC<{
  messageID: string
  onSuccess: () => void
  voteCount: number
  userVote: number
  isHorizontal?: boolean
}> = ({messageID, onSuccess, voteCount, userVote, isHorizontal = true}) => {
  const voteMutation = useMutation(ChatService.voteOnMessage(messageID), {
    onSuccess: data => {
      onSuccess()
    }
  })
  const auth = useAuth()
  const {onOpen, onClose, isOpen} = useDisclosure()
  const [voteDirection, setVoteDirection] = useState<VoteDirection | undefined>(undefined)
  const getHorizontalButtons = () => {
    return (
      <>
        <Tooltip label={'This post shows effort; it is useful and clear'}>
          <IconButton
            color={userVote === 1 ? 'brand2.500' : userVote === -1 ? 'gray.500' : 'text'}
            variant={"ghost"}
            disabled={auth.user?.SubscriptionInfo?.Perm === 0}
            aria-label='upvote'
            size={'xs'}
            px={'10px'}
            mx={'2px'}
            icon={<BsArrowUp
              strokeWidth={userVote === 1 ? 2 : 0.3}
            />}
            onClick={() => {
              if (userVote !== 0) {
                setVoteDirection(VoteDirection.Up)
                onOpen()
              } else {
                voteMutation.mutate({vote: "1"})
              }
            }}
          />
        </Tooltip>
        <Text color={'gray.500'} fontSize={'xs'}>{voteCount}</Text>
        <Tooltip label={'This post does not show any effort; it is unclear and not useful'}>
          <IconButton
            color={userVote === -1 ? 'brand2.500' : userVote === 1 ? 'gray.500' : 'text'}
            variant={"ghost"}
            disabled={auth.user?.SubscriptionInfo?.Perm === 0}

            aria-label='downvote'
            size={'xs'}
            px={'10px'}
            mx={'2px'}
            icon={<BsArrowDown strokeWidth={userVote === -1 ? 2 : 0.3}/>}
            onClick={() => {

              if (userVote !== 0) {
                setVoteDirection(VoteDirection.Down)
                onOpen()
              } else {
                voteMutation.mutate({vote: "-1"})
              }
            }}
          />
        </Tooltip>
      </>
    )
  }
  const getButtons = () => {
    return (
      <>
        <Tooltip label={'This post shows effort; it is useful and clear'}>
          <IconButton
            color={userVote === 1 ? 'brand2.500' : userVote === -1 ? 'gray.500' : 'text'}
            variant={"ghost"}
            disabled={auth.user?.SubscriptionInfo?.Perm === 0}

            aria-label='upvote'
            size={'sm'}
            icon={<BsArrowUp strokeWidth={userVote === 1 ? 2 : 0.3}/>}
            onClick={() => {
              if (userVote !== 0) {
                setVoteDirection(VoteDirection.Up)
                onOpen()
              } else {
                voteMutation.mutate({vote: "1"})
              }
            }}
          />
        </Tooltip>
        <Text color={'gray.500'}>{voteCount}</Text>
        <Tooltip label={'This post does not show any effort; it is unclear and not useful'}>
          <IconButton
            color={userVote === -1 ? 'brand2.500' : userVote === 1 ? 'gray.500' : 'text'}
            variant={"ghost"}
            disabled={auth.user?.SubscriptionInfo?.Perm === 0}

            aria-label='downvote'
            size={'sm'}
            icon={<BsArrowDown strokeWidth={userVote === -1 ? 2 : 0.3}/>}
            onClick={() => {

              if (userVote !== 0) {
                setVoteDirection(VoteDirection.Down)
                onOpen()
              } else {
                voteMutation.mutate({vote: "-1"})
              }
            }}
          />
        </Tooltip>
      </>
    )
  }
  return (
    <>
      {
        isHorizontal ? <HStack>{getHorizontalButtons()}</HStack> : <VStack>{getButtons()}</VStack>
      }

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Updating Vote</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <Text mb={'20px'} color={'gray'} fontSize={'sm'}> Are you sure? You may only update your vote once.</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose()
                voteMutation.mutate({vote: voteDirection === userVote? "0":
                    voteDirection === VoteDirection.Up ? "1" : "-1"})
                setVoteDirection(undefined)
              }
              }>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}