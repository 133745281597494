import React, {FC} from "react";
import {UseQueryResult} from "react-query";
import {CSQuestion2, MCQuestion2, Question2, SAQuestion2} from "../../Models/Question";
import {AxiosError} from "axios";
import {Box, Skeleton, Text, useColorModeValue} from "@chakra-ui/react";
import {MCQPreview} from "../Question/MCQPreview";
import {SAQPreview} from "../Question/SAQPreview";
import {CSQPreview} from "../Question/CSQPreview";
import {Watermark} from '@hirohe/react-watermark';

export const QuestionPreview: FC<{ qnQuery: UseQueryResult<Question2<any>, AxiosError> }> = ({qnQuery}) => {

  const secondaryBorderColor = useColorModeValue('1px solid rgb(200,200,200)', '1px solid rgb(50,50,50)')
  return (
    <Watermark text={'propella'} textColor={'gray.500'} opacity={0.08} rotate={-20} textSize={25} gutter={10}
               fontFamily={"Nunito, sans-serif"}>
      <Box
        p={'15px'}
        my={'10px'}
        border={secondaryBorderColor}
        borderRadius={'6px'}
        sx={{
          WebkitTouchCallout: 'none',
          WebkitUserSelect: 'none',
          KhtmlUserSelect: 'none',
          MozUserSelect: 'none',
          userSelect: 'none',
          msUserSelect: 'none'
        }}
      >

        {
          qnQuery.isLoading &&
          <Skeleton height='20px'/>
        }
        {
          qnQuery.isError &&
          <Text
              color={'rgba(200,20,20)'}
          >{qnQuery.error.response?.status === 401 && 'You do not have the right subscription to view this question.'}</Text>
        }

        {
          qnQuery.data && qnQuery.data!.QnType === 'mcq' &&
          <MCQPreview question={qnQuery.data as MCQuestion2}/>
        }
        {
          qnQuery.data && qnQuery.data!.QnType === 'saq' &&
          <SAQPreview question={qnQuery.data as SAQuestion2}/>
        }
        {
          qnQuery.data && qnQuery.data!.QnType === 'csq' &&
          <CSQPreview question={qnQuery.data as CSQuestion2}/>
        }

      </Box>
    </Watermark>


    // </Link>


  )
}