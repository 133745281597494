import {FC} from "react";
import {useMutation, UseMutationResult, useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {UserProblemSetAttempt} from "../../Models/Question";
import {useHistory} from "react-router-dom";
import {
  Box,
  Button, Center,
  Flex,
  HStack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import {useAuth} from "../../Contexts/AuthContext";
import {MdOutlineLock} from "react-icons/all";

export const GeneratedProblemSetView: FC<{
  topicID?: string,
  paperID?: string
}> = ({paperID, topicID}) => {
  const auth = useAuth()
  const dynamicProblemSetQuery = useQuery(
    ["dynamic-pset", topicID ? topicID : paperID],
    QuestionService.getDynamicProblemSetAttempts(topicID, paperID),
    {
      enabled: auth.user !== null,
    })

  const topicQuery = useQuery(["topic", topicID], QuestionService.getTopicInfo(topicID ?? ''), {
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
    enabled: !!topicID
  })

  const deleteOngoingAttempt = useMutation(QuestionService.deleteOngoingAttempt, {
    onSuccess: () => {
      dynamicProblemSetQuery.refetch()
    }
  })

  return (
    <Box pb={'40px'} w={'full'}>{
      auth.testAuth(topicQuery?.data?.Paper ?? parseInt(paperID ?? "3"))
        ?
        <>
          <GeneratedProblemSetBanner
            paperID={paperID}
            topicID={topicID}
            attempts={dynamicProblemSetQuery.data}
            deleteOngoingAttempt={deleteOngoingAttempt}/>
          <GeneratedProblemSetAttemptsView attempts={dynamicProblemSetQuery.data}/>
        </> :
        <>
          <Center><MdOutlineLock/><Text>subscribe to view</Text></Center>
        </>
    }
    </Box>
  )
}

const GeneratedProblemSetBanner: FC<{
  topicID?: string,
  paperID?: string,
  attempts?: UserProblemSetAttempt[],
  deleteOngoingAttempt: UseMutationResult<undefined, unknown, string>
}> = ({
        topicID,
        paperID,
        attempts,
        deleteOngoingAttempt
      }) => {
  const hist = useHistory()

  const getOrCreateDPSetMutation = useMutation(QuestionService.getOrCreateDynamicProblemSetAttempt,
    {
      onSuccess: data => {
        hist.push(`/set/${data.ProblemSetID}/do`)
      }
    })

  const handleContinueOrStart = () => {
    getOrCreateDPSetMutation.mutate({
      topicID: topicID,
      paperID: paperID
    })
  }
  const incompleteAttempt = (attempts?.filter(x => x.Score === null))?.[0]
  const hasIncompleteAttempt = (attempts?.filter(x => x.Score === null).length ?? 0) > 0

  return (
    <Box w={'full'} mb={'10px'}>
      {
        hasIncompleteAttempt &&
        <Text mb={'10px'}>Ongoing Attempt - Started on: {
          dayjs(incompleteAttempt?.CreatedAt).format('DD MMM YY h:mm a')
        }
        </Text>
      }

      {
        hasIncompleteAttempt ?
          <>


            <HStack>
              <Button
                isLoading={deleteOngoingAttempt.isLoading}
                onClick={handleContinueOrStart}
              >Continue</Button>
              <Button
                isLoading={deleteOngoingAttempt.isLoading}
                onClick={() => {
                  deleteOngoingAttempt.mutate(incompleteAttempt?.ID ?? "")
                }
                }>Reset</Button>

            </HStack>

          </>
          :
          <Button onClick={handleContinueOrStart}>Start Test</Button>
      }

    </Box>
  )
}

const GeneratedProblemSetAttemptsView: FC<{
  attempts?: UserProblemSetAttempt[],

}> = ({attempts}) => {
  const hist = useHistory()
  const completedAttempts = attempts?.filter(x => x.Score !== null)
  return (
    <Table variant={'simple'}>
      {
        completedAttempts?.length === 0 &&
        <TableCaption>You have not done any tests yet</TableCaption>

      }
      <Thead>
        <Tr>
          <Th>Attempt</Th>
          <Th>Time Taken</Th>
          <Th isNumeric={true}>Score</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          completedAttempts?.sort((a, b) => a.CreatedAt < b.CreatedAt ? 1 : 0)
            .map((att, i) => {

              const dateDisplay = dayjs(att.CreatedAt).format('DD MMM YY h:mm a')
              const timeTakenDisplay = new Date(dayjs(att.MarkedAt)
                .diff(att.CreatedAt, 's') * 1000).toISOString().substr(11, 8)

              return (
                <Tr>
                  <Td>
                    {/*<Tag*/}
                    {/*  colorScheme={att.DelayMarking ? 'brand' : 'brand2'}*/}
                    {/*  variant={'outline'}>*/}
                    {/*  {att.DelayMarking ? "Test" : "Practice"}*/}
                    {/*</Tag>*/}
                    {/*<Text>{`attempt ${i + 1}`}</Text>*/}
                    <Text>{dateDisplay}</Text>
                  </Td>
                  <Td>
                    <Text>{timeTakenDisplay}</Text>
                  </Td>
                  <Td>
                    <Flex flexDirection={'row-reverse'}>
                      <VStack alignItems={'flex-end'}>
                        <Text>{att.Score}/{att.ProblemSet?.MaxScore}</Text>
                        <Button
                          size={'xs'}
                          onClick={() => {
                            hist.push(`/set/${att.ProblemSetID}/review?attemptID=${att.ID}`)
                          }}>Review</Button>
                      </VStack>

                    </Flex>

                  </Td>

                </Tr>


              )
            })

        }
      </Tbody>
    </Table>
  )
}