import {Dispatch, FC, SetStateAction, useEffect} from "react";
import {CSQuestion2, CSSQuestion2} from "../../Models/Question";
import {Divider, SimpleGrid, Tag, Text, useBreakpointValue, VStack} from "@chakra-ui/react";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import {MCQuestionOptionView} from "./MCQView";
import dayjs from "dayjs";
import {QLState} from "./QuestionLoader";

export const CSQView: FC<{
  question: CSQuestion2,
  usrHistoricAns?: string,
  tempUsrAns?: string,
  loaderState: QLState,
  setTempUsrAns: Dispatch<SetStateAction<string | undefined>>,
  setReadyForSubmit: Dispatch<SetStateAction<boolean>>,
}> = ({question, loaderState, tempUsrAns, setTempUsrAns, usrHistoricAns, setReadyForSubmit}) => {
  useEffect(() => {
    if (usrHistoricAns !== undefined) {
      setTempUsrAns(usrHistoricAns)
    } else {
      const x = question.Params?.map(() => '')
      if (!!x) setTempUsrAns(x.join(','))
    }

  }, [question, usrHistoricAns])
  const usrHistoricAnsSplit = (usrHistoricAns as string | undefined)?.split(',')
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  return (
    <SimpleGrid w={'full'} columns={{base: 1, sm: 1, md: 2}} spacing={'20px'}>

      <VStack maxH={isMobile ? '60vh' : '70vh'} pr={'12px'} key={'body'} overflowY={'scroll'} alignItems={'flex-start'}>
        {
          question.Body.split('\n').map(t => <Text key={t}>{t}</Text>)
        }

      </VStack>
      {
        isMobile && <Divider bg={'brand2.500'}/>
      }

      <VStack maxH={isMobile ? '60vh' : '70vh'} pr={'12px'} key={'questions'} overflowY={'scroll'} spacing={isMobile ? '10px' : '20px'}>
        {
          question.Params?.map((subQn, idx) =>
            <>
              <CSSQView
                key={idx}
                question={subQn}
                idx={idx}
                usrSvrAns={usrHistoricAns !== undefined ? usrHistoricAnsSplit![idx] : undefined}
                tempUsrAns={tempUsrAns !== undefined ? (tempUsrAns as string).split(',')[idx] : undefined}
                loaderState={loaderState}
                onAnswer={selection => {
                  const answers = (tempUsrAns as string).split(',')
                  answers[idx] = selection.toString()

                  let ready = true
                  for (let i = 0; i < answers.length; i++) {
                    if (answers[i] === '') {
                      ready = false
                      break
                    }
                  }
                  setReadyForSubmit(ready)
                  setTempUsrAns(answers.join(','))
                }
                }
                setReadyForSubmit={setReadyForSubmit}
              />
              <Divider pt={'20px'}/>
            </>
          )
        }
      </VStack>


    </SimpleGrid>
  )
}

const CSSQView: FC<{
  question: CSSQuestion2,
  idx: number,
  usrSvrAns?: string,
  tempUsrAns: string | number | undefined,
  loaderState: QLState,
  onAnswer: (selection: number) => void,
  setReadyForSubmit: Dispatch<SetStateAction<boolean>>,
}> = ({question, idx, loaderState, tempUsrAns, usrSvrAns, onAnswer, setReadyForSubmit}) => {
  return (
    <VStack alignItems={'flex-start'} w={'full'}>
      <Text>Qn {idx+1}</Text>
      {
        loaderState === QLState.review &&
        usrSvrAns === undefined &&
        <Tag colorScheme={'red'} variant={'outline'}>SKIPPED</Tag>
      }
      {/*<Text>{question.ID}</Text>*/}
      {
        question.B.split('\n').map(t => <Text key={t}>{t}</Text>)
      }
      <VStack alignItems={'flex-start'}>
        {
          question.A.map((ans, i) =>

          {
            return (
              <MCQuestionOptionView
                idx={i}
                key={i}
                isSelected={
                  loaderState === QLState.active ?
                    (i+1).toString() === tempUsrAns :
                    (i+1).toString() === usrSvrAns
                }
                isRightAnswer={
                  loaderState === QLState.active
                    ? undefined
                    : question.R === i+1
                }
                optionText={ans}
                onClick={() => {
                  onAnswer(i + 1)
                }
                }
                showKeyboardKey={false}
              />

            )
          }
)
        }
      </VStack>
      {loaderState !== QLState.active && !!question.E &&
      <>
          <Text pt={'20px'} pb={'10px'} textStyle={'h5'}>Explanation</Text>
          <ReactMarkdown linkTarget={'_blank'} >{question.E}</ReactMarkdown>

      </>
      }

    </VStack>
  )
}