import {FC} from "react";
import useLocalStorage from "./Hooks/use-local-storage";
import {Box, Container, HStack, IconButton, Link, Spacer, Text, useColorModeValue} from "@chakra-ui/react";
import {AiOutlineClose} from "react-icons/all";
import {useHistory} from "react-router-dom";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import dayjs from "dayjs";
import {useAuth} from "./Contexts/AuthContext";
import {LatestUpdateID} from "./Components/Pages/NewFeaturesPage";

export const UpdateBanner: FC = ({}) => {
  const [viewedLatestUpdates, setViewedLatestUpdates] = useLocalStorage<boolean | null>(LatestUpdateID, false)
  const noticeBannerColor = useColorModeValue('brand2.400', 'brand2.600')
  const hist = useHistory()

  return (<>{
    viewedLatestUpdates !== true &&

    <Box bg={noticeBannerColor}>
        <Container maxW="container.lg">
            <HStack maxW="full">
                <Text color={'white'} fontWeight={700} textAlign={'center'} py={'5px'}>
                    Propella has been updated! Read about new changes here: <Link onClick={() => {
                  hist.push('/updates')
                }}>
                    &nbsp;Updates</Link>
                </Text>
                <Spacer/>
                <IconButton
                    color={'white'}
                    variant={'ghost'}
                    onClick={() => {
                      setViewedLatestUpdates(true)

                    }}
                    icon={<AiOutlineClose/>} aria-label={'close notice'}/>

            </HStack>

        </Container>
    </Box>
  }
  </>)

}
export const NoticeBanner: FC = ({}) => {
  const [viewedLatestUpdates, setViewedLatestUpdates] = useLocalStorage<boolean | null>("tele-shoutout", false)
  const noticeBannerColor = useColorModeValue('brand.400', 'brand.600')
  const hist = useHistory()

  return (<>{
    viewedLatestUpdates !== true &&

    <Box bg={noticeBannerColor}>
        <Container maxW="container.lg">
            <HStack maxW="full">
                <Text color={'white'} fontWeight={700} textAlign={'center'} py={'5px'}>
                    Follow our telegram channels/ groups:
                    <Link
                        isExternal
                        href={"https://t.me/propellasgupdates"}>
                        &nbsp;Updates

                        <ExternalLinkIcon mx="2px"/>
                    </Link>
                    <Link
                        isExternal
                        href={"https://t.me/propellasg"}>
                        &nbsp;Chat

                        <ExternalLinkIcon mx="2px"/>
                    </Link>

                </Text>
                <Spacer/>
                <IconButton
                    color={'white'}
                    variant={'ghost'}
                    onClick={() => {
                      setViewedLatestUpdates(true)

                    }}
                    icon={<AiOutlineClose/>} aria-label={'close notice'}/>

            </HStack>

        </Container>
    </Box>
  }
  </>)

}
export const DiscountEndingBanner: FC = ({}) => {
  const [viewed, setViewed] = useLocalStorage<boolean | null>("discount-ending", false)
  const noticeBannerColor = useColorModeValue('gray.400', 'gray.600')
  const auth = useAuth()
  return (<>{
    viewed !== true &&
    dayjs().isBefore(dayjs('2022-09-19 01:00:00.000+08:00')) &&
    (auth.user?.SubscriptionInfo?.Perm === undefined || auth.user?.SubscriptionInfo.Perm === 0) &&
    <Box bg={noticeBannerColor}>
        <Container maxW="container.lg">
            <HStack maxW="full">
                <Text color={'white'} fontWeight={700} textAlign={'center'} py={'5px'}>
                    Prices will be permanently increased on Monday, 19 Sep. Subscribe early to save!

                </Text>
                <Spacer/>
                <IconButton
                    color={'white'}
                    variant={'ghost'}
                    onClick={() => {
                      setViewed(true)

                    }}
                    icon={<AiOutlineClose/>} aria-label={'close notice'}/>

            </HStack>

        </Container>
    </Box>
  }
  </>)

}