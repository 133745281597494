import React, {ChangeEvent, FC, useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  Textarea, useColorModeValue, useToast
} from "@chakra-ui/react";
import {useMutation, useQuery} from "react-query";
import {ChatService} from "../../Services/ChatService";
import {DiscussionPageListItem} from "./DiscussionPageListItem";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {AxiosError} from "axios";
import {useAuth} from "../../Contexts/AuthContext";

export const QuestionDiscussion: FC<{
  resourceID: string,
  responseBoxActive: (isActive: boolean) => void
}> = ({resourceID, responseBoxActive}) => {

  const toast = useToast()
  const urlQuery = useURLQuery()
  const highlightedMsg = useRef<HTMLDivElement>(null)
  const [scrolledHighlightedMsgIntoView, setShmiv] = useState(false)
  const postCommentMutation = useMutation(ChatService.createNewMessage(resourceID), {

    onSuccess: data => {
      setValue('')
      discussionQuery.refetch()
    },
    onError: (error: AxiosError) => {
      toast({
        position: "top",
        title: "Failed to send message.",
        description: error.response?.data.Error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  })
  const discussionQuery = useQuery(['thread', resourceID], ChatService.getMessagesForResource(resourceID),
    {
      staleTime: 1e3 * 60 * 5,
      cacheTime: 1e3 * 60 * 5,
      onSuccess: data => {
        highlightedMsg.current?.scrollIntoView()
      }
    })

  useEffect(() => {
    if (!scrolledHighlightedMsgIntoView && highlightedMsg.current !== null) {
      highlightedMsg.current.scrollIntoView()
      setShmiv(true)
    }
  })
  const auth = useAuth()
  let [value, setValue] = useState('')
  const borderColor = useColorModeValue('1px solid gray', '1px solid rgb(60,60,100)')
  let handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value
    setValue(inputValue)
  }
  return (
    <Box
      px={'10px'}
      pt={'10px'}
      pb={'10px'}
      w={'full'}
      borderRadius={'6px'}
      border={borderColor}>
      {
        (auth.testAuth(1) || auth.testAuth(2)) ?
          <>
            <Text color={'gray.500'} p={'1px'} fontSize={'sm'}> - Report dead links with the feedback button</Text>
            <Text color={'gray.500'} p={'1px'} fontSize={'sm'}> - Ask quality questions: if your question can be solved
              with less than a minute of googling, do not ask it here.</Text>
            <Textarea
              mt={'5px'}
              w={'full'}
              value={value}
              isDisabled={postCommentMutation.isLoading}
              onFocus={() => {
                responseBoxActive(true)
              }}
              onBlur={() => {
                responseBoxActive(false)
              }}
              onChange={handleInputChange}
              placeholder={'I am wondering if...'}
            />

            <Flex
              mt={'10px'}
              flexDirection={'row-reverse'}>
              <Button
                isDisabled={value.length < 10}
                isLoading={postCommentMutation.isLoading}
                onClick={() => {
                  postCommentMutation.mutate({ReplyToMsgID: "", Body: value})
                }}
              >{'Post Question'}</Button>
            </Flex>
          </>
          :
          <Text color={'gray.500'} p={'1px'} fontSize={'sm'}> You need to be subscribed to participate in
            discussions.</Text>
      }

      {
        discussionQuery.isLoading &&
          <Spinner/>
      }

      {

        (discussionQuery.data === undefined || discussionQuery.data?.length < 1) &&
          <Center py={'20px'}><Text color={"gray.500"}>There are no comments for this question yet.</Text></Center>
      }
      {
        (discussionQuery.data?.length ?? 0) > 0 &&
          <Box
              mt={'20px'}
              mb={'20px'}
              maxH={'40vh'}
              overflowY={'scroll'}
              borderRadius={'6px'}
              border={borderColor}>
            {
              discussionQuery.data?.map((msg, i) => (
                //@ts-ignore
                <div ref={urlQuery.get('msgID') === msg.ID ? highlightedMsg : undefined}>
                  <DiscussionPageListItem
                    key={msg.ID}
                    message={msg}
                    resourceID={resourceID}
                    onReply={() => {
                      discussionQuery.refetch()
                    }}
                    highlight={urlQuery.get('msgID') === msg.ID ? 'inset rgba(79, 114, 205, 0.5) 0 0 40px 0px' : undefined}
                    hideQnPreview={true}
                    hideBottomBorder={i + 1 === discussionQuery.data?.length}
                    hideOpenLink={true}
                    responseBoxActive={responseBoxActive}
                  />
                </div>
              ))
            }
          </Box>
      }
    </Box>

  )
}
