import React, {FC, useState} from "react";
import {Box, Button, Center, Container, Flex, Heading, Kbd, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {QLState, QuestionLoader} from "../Question/QuestionLoader";
import {QuestionType} from "../../Models/Question";

export const TestQuestion: FC = () => {
  // const sampleQuestionsQuery = useQuery('samples', QuestionService.getSampleQuestions, {
  //   staleTime: 1000 * 60 * 1000,
  //   cacheTime: 1000 * 60 * 1000,
  // })
  const mcq = {
    "ID": "sample-1",
    "Score": 1,
    "QnType": "mcq",
    "Body": "Which of the following statement(s) is/are correct?\n(a) The state is empowered to acquire private properties for public interest under the land titles act\n(b) Agents are required to conduct four due diligence checks on their tenant's particulars before the tenancy\n(c) Foreigners are to get approval from LDAU before purchasing a private property under the Restricted Property Act\n(d) Agents are not required to make a statutory declaration under the Oaths and Declaration Act",
    "TenantID": "propella",
    "Answer": "4",
    "Explanation": "(a) it is under the land acquisition act\n(b) three due diligence checks\n(c) Residential Property act instead of restricted property act\n(d) Agents are required to make a statutory declaration under the Oaths and Declaration Act",
    "Params": ["(a), (b) and (c)", "(b), (c) and (d)", "(b) and (c)", "None of the above"],
  }
  return (
    <Box
      pt={'80px'}
      pb={'80px'}
      bg={useColorModeValue('gray.100', 'gray.800')}
      // bgGradient={bgGrad}

    >
      <Container maxW={'container.xl'} px={10}>
        <Box>
          <Heading
            textAlign={'center'}
            fontSize={'4xl'}
            mb={'20px'}
          >
            Practice makes perfect
          </Heading>

          <Center>
            <Text fontSize={'sm'} textAlign={'center'} mb={'40px'}>
              Practise even faster with hotkeys on a keyboard! Use
              <Kbd mx={'2px'}>1</Kbd>,
              <Kbd mx={'2px'}>2</Kbd>,
              <Kbd mx={'2px'}>3</Kbd>,
              <Kbd mx={'2px'}>4</Kbd>
              to select an option and <Kbd>Enter</Kbd> to submit.
            </Text>
          </Center>
          <QuestionLoader
            key={'1'}
            qnID={"mcq_1zs7Z4OGQ08fqGfTH23KiVoRulf"}
            question={mcq}
            nextQuestionIsLoading={false}
            bypassServerGrading={true}
            defaultState={QLState.active}
            hidePageControls={true}
            hideAccessoryButtons={true}
          />


        </Box>


      </Container>
    </Box>
  )
}

