import React, {FC} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  Center,
  Container,
  Heading,
  Spacer,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

export const Hero: FC = () => {
  const hist = useHistory()

  return (
    <Container maxW={'container.xl'} h={{base: '500px', lg: '85vh'}} maxH={'600px'} as={Center}>

        <Stack

          alignItems={'center'}
          textAlign={'center'}
          pt={{base: 20, md: '20px'}}
          pb={{base: 10, md: '20px'}}
          spacing={'15px'}
        >
          <VStack spacing={0}>
            <Text
              fontSize={['lg', '3xl']}
              fontWeight={'700'}
              lineHeight={'100%'}
            >
              Let your real estate career
            </Text>
            <Heading
              bgGradient="linear(45deg, #264DC8, #FF0080)"
              sx={{
                backgroundSize: "400% 400%",
                WebkitAnimation: "Gradient 3s ease infinite",
                MozAnimation: "Gradient 3s ease infinite",
                animation: "Gradient 3s ease infinite"
              }}
              bgClip="text"
              fontSize={['5xl', '8xl']}
              fontWeight={'400'}
              // color={'brand.400'}
              lineHeight={'110%'}
            >
              take flight
            </Heading>

          </VStack>

          <Text
            fontSize={['md', 'lg']}
            maxW={'30rem'}
          >
            Propella is an online question bank for the Singapore real estate exam.
            Study the smart way and gain confidence with our unique collection of 1500+ questions.
          </Text>
          <Spacer/>

          <Button
            size={'lg'}
            color={'white'}
            bgGradient="linear(180deg, brand.300, brand.600)"
            rounded={'full'}
            px={6}
            _hover={{
              bg: 'brand.700',
            }}
            boxShadow={'0 4px 7px rgba(79,114,205,.5)'}
            onClick={() => {
              hist.push('/paper/1')
            }}
          >

            Try now
          </Button>
        </Stack>
      </Container>
    // </Box>

  )
}