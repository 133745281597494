import {FC} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {
  Container,
  Heading,
  VStack,
  Text,
  Box, HStack, Button, Link, Tag,


} from "@chakra-ui/react";
import {TopicPickerView} from "../ProblemSet/TopicPickerView";
import {ProblemSetTable} from "../ProblemSet/ProblemSetTable";
import {useAuth} from "../../Contexts/AuthContext";
import {GeneratedProblemSetView} from "../ProblemSet/GeneratedProblemSetView";
import {NoticeBanner, UpdateBanner} from "../../Banner";


export const PaperPage: FC<{}> = ({}) => {
  const auth = useAuth()
  // @ts-ignore
  const {paperID} = useParams()
  const hist = useHistory()

  const paperDataQuery = useQuery(['paper', paperID], QuestionService.getPaperData(paperID), {
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
  })

  const attemptQuery = useQuery(['attempts'], QuestionService.getAttempts(), {
    enabled: !!auth.user?.ID,
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
  })

  const updatedQnsQuery = useQuery(['paper', 'updated', paperID], QuestionService.getUpdatedQuestions, {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  })
  return (
    <Box>
      <UpdateBanner/>
      <NoticeBanner/>
      <Container maxW="container.lg" pt={'20px'}>
        <VStack w={'full'} alignItems={'flex-start'} spacing={['20px', '40px']}>
          <Heading pt={'20px'}  size={'3xl'}>Paper {paperID}</Heading>
          {
            auth.user != null &&

              <Button size={'sm'} variant={'outline'} isLoading={updatedQnsQuery.isLoading}
                      onClick={() => hist.push(`/paper/${paperID}/updated`)}>
                  Recently updated Questions
                  <Tag ml={'6px'}>{updatedQnsQuery.data?.length}</Tag>
              </Button>
          }
          <VStack w={'full'} alignItems={'flex-start'}>
            <Heading pb={'20px'}>MCQs</Heading>
            <TopicPickerView topics={paperDataQuery.data?.Topics} attempts={attemptQuery.data ?? []}/>
          </VStack>

          <VStack w={'full'} alignItems={'flex-start'}>

            <Heading pt={'40px'}>Case Study Questions</Heading>
            {
              paperDataQuery.data?.ProblemSets &&
                <ProblemSetTable pSets={paperDataQuery.data.ProblemSets.filter(p => p.Type === "CSQ")}
                                 attempts={attemptQuery.data ?? []}/>
            }
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'}>

            <Heading pt={'40px'}>Dynamic Mock Tests</Heading>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              my={'6px'}
            >
              Randomly pull 30 MCQs and 1 case study from the question bank and test yourself with a
              timer! You can review your last 5 attempts.</Text>
            <GeneratedProblemSetView paperID={paperID}/>
            {/*{*/}
            {/*  paperDataQuery.data?.ProblemSets &&*/}
            {/*  <ProblemSetTable pSets={paperDataQuery.data.ProblemSets.filter(p => p.ProblemSet.Type === "Full")}/>*/}
            {/*}*/}
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'}>

            <Heading pt={'40px'}>Full Mock Papers</Heading>
            <Text
              fontSize={'sm'}
              color={'gray.500'}
              my={'6px'}
            >
              This mock exam is as close as it gets. Questions here are not displayed in normal problem sets or dynamic
              sets.
              Do this only at the end to get a gauge on your final performance.
            </Text>
            {
              paperDataQuery.data?.ProblemSets &&
              paperDataQuery.data?.ProblemSets.filter(p => p.Type === "Full").length > 0
                ? <ProblemSetTable pSets={paperDataQuery.data.ProblemSets.filter(p => p.Type === "Full")}
                                   attempts={attemptQuery.data ?? []}/>
                : <Text
                  fontSize={'sm'}
                  color={'gray.500'}
                  my={'6px'}
                  pb={'80px'}
                >
                  Coming soon!</Text>
            }
          </VStack>
          <VStack w={'full'} alignItems={'flex-start'}>

            <Heading pt={'40px'}>Short Answer Questions (Old exam format)</Heading>

            <Text
              fontSize={'sm'}
              color={'gray.500'}
              my={'6px'}
            >
              These questions are from the old exam format. These are still great for testing your knowledge on key
              definitions.
            </Text>
            {
              paperDataQuery.data?.ProblemSets &&
                <ProblemSetTable pSets={paperDataQuery.data.ProblemSets.filter(p => p.Type === "SAQ")}
                                 attempts={attemptQuery.data ?? []}/>
            }
          </VStack>

          <Box h={'200px'}/>

        </VStack>
      </Container>
    </Box>

  )
}

