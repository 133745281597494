import {ApiClient} from "./APIClient";
import {UserInfo} from "../Models/Auth";
import {AffiliateBountyPayout, AffiliateInfo, Referral} from "../Models/Referral";

const getOTP = async (email: string): Promise<string> => {
  return await ApiClient.post('/api/otp', {
    'Email': email,
  }).then(resp => resp.data.Response);
}

export interface LoginParams {
  email?: string
  otp?: string
  referee?: string
  googleJWT?: string
}

const login = async ({email, otp, referee, googleJWT}: LoginParams): Promise<void> => {
  await ApiClient.post('/api/login', {
    'Email': email,
    'Otp': otp,
    'RefereeUserID': referee,
    'GoogleJWT': googleJWT
  })
}

const logout = async () => {
  await ApiClient.get('/api/logout')
}

const whoami = async (): Promise<UserInfo | null> => {
  return await ApiClient.get('/api/whoami').then(resp => {
    return resp.data.Response

  }).catch(err => {
    return null
  })
}

const getUserReferrals = async (): Promise<Referral[]> => {
  return await ApiClient.get("/api/referrals").then(resp => {
    return resp.data.Response
  })
}

const joinAffiliateProg = async () => {
  return await ApiClient.put('/api/affiliate/join').then(resp => resp.data.status)
}

const getAffiliateInfo = async (): Promise<AffiliateInfo> => {
  return await ApiClient.get('/api/affiliate/info').then(resp => resp.data.Response)
}

const getPayouts = async (): Promise<AffiliateBountyPayout[]> => {
  return await ApiClient.get('/api/affiliate/payout').then(resp => resp.data.Response)
}

const createPayout = async (props: { paymentNotes: string }) => {
  return await ApiClient.post('/api/affiliate/payout', {PayoutNotes: props.paymentNotes})
    .then(resp => resp.data.Response).catch(err => err.response.data.Error)
}

const bumpReferralBonus = async (props: { event: string }) => {
  return await ApiClient.post('/api/affiliate/bump-referral?event=' + props.event)
    .then(resp => resp.data.Response)
}

const setReferrer = async (props: { referralID: string }) => {
  return await ApiClient.post('/api/affiliate/set-referrer', {ReferralID: props.referralID})
    .then(resp => resp.data.Response)
}

const setDisplayName = async (props: { displayName: string }) => {
  return await ApiClient.post(`/api/user?DisplayName=${props.displayName}`)
    .then(resp => resp.data.Response)
}

export const AuthService = {
  getOTP,
  login,
  logout,
  whoami,
  getUserReferrals,
  setReferrer,
  joinAffiliateProg,
  getAffiliateInfo,
  getPayouts,
  createPayout,
  setDisplayName,
  bumpReferralBonus

}