import React, {FC} from "react";
import {
  Box, Button,
  Center,
  Container,
  Heading,
  HStack,
  SimpleGrid,
  Text, useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {FiDollarSign, FiPercent, FiStopCircle} from "react-icons/all";
import {EmailIcon, Icon} from "@chakra-ui/icons";
import {IconType} from "react-icons";
import {useHistory} from "react-router-dom";

const PerkCard: FC<{
  icon: IconType
  title: string,
  body: string
}> = ({icon, title, body}) => {
  return (
    <VStack alignItems={'flex-start'} spacing={'20px'}>

      <Icon as={icon} h={'32px'} w={'32px'}
            color={'brand2.500'}
      />
      <Heading size={'md'}>{title}</Heading>
      <Text>{body}</Text>
    </VStack>
  )
}

const BigPerkCard: FC<{
  icon: IconType
  title: string,
  body: string
}> = ({icon, title, body}) => {
  return (
    <VStack alignItems={'flex-start'} spacing={'20px'}>
      <HStack spacing={0}>
        <Icon as={icon} h={'32px'} w={'32px'}
              color={'brand2.500'}
        />
        <Icon as={icon} h={'32px'} w={'32px'}
              color={'brand2.500'}
        />
        <Icon as={icon} h={'32px'} w={'32px'}
              color={'brand2.500'}
        />

      </HStack>


      <Heading size={'md'}>{title}</Heading>
      <Text>{body}</Text>
    </VStack>
  )
}

export const AffiliatePage: FC<{}> = ({}) => {
  const hist = useHistory()
  const silverGradient = useColorModeValue("linear(100deg, #8B939A, #2F4353)", "linear(100deg, #E8DBFC, #F8F9D2)")
  const isMobile = useBreakpointValue({base: true, sm: false, md: false})
  return (
    <Box>
      <Box
        pt={{base: '10px', md: '80px'}}
        pb={'120px'}
        bgGradient="linear(180deg, rgba(255, 255, 255, 0), rgba(15, 40, 232, 0.1))"
      >

        <Container maxW={'container.lg'} px={10}>

          <Center>
            <Heading
              fontSize={'6xl'}
              bgGradient="linear(45deg, #264DC8, #FF0080)"
              // sx={{
              //   backgroundSize: "400% 400%",
              //   WebkitAnimation: "Gradient 5s ease infinite",
              //   MozAnimation: "Gradient 5s ease infinite",
              //   animation: "Gradient 5s ease infinite"
              // }}
              bgClip="text"
            >Propella Affiliates</Heading>
          </Center>
          <Center mt={'30px'} mb={'30px'}>
            <Heading fontSize={'2xl'}>Help spread the word about us and get paid</Heading>

          </Center>
          <SimpleGrid columns={{base: 1, md: 3}} spacing={10} my={'50px'}>
            <PerkCard
              icon={FiDollarSign}
              title={"Revenue share"}
              body={'Receive at least 10% commissions* from anyone who signs up and purchase a plan using your link.'}/>
            <PerkCard
              icon={FiPercent}
              title={"Customer discount"}
              body={'Users that purchase through your referral link get at least 5% off.'}/>
            <PerkCard
              icon={FiStopCircle}
              title={"Low minimum payout"}
              body={'Start requesting for payout from as low as $4 in accrued referral earnings.'}/>
          </SimpleGrid>
          <Center>
            <Button
              bgColor={'brand.700'}
              color={'white'}
              size={'lg'}
              my={'40px'}
              onClick={() => {
                hist.push('/profile')
              }}
            >Join Now</Button>
          </Center>
          <Center>
            <Text fontSize={'xs'} maxW={'600px'}>* The amount you receive is a percentage of what the user was
              ultimately charged.
              For example, a $100 plan sold at $80 after discount will earn you $8 if your commission rate is
              10%.</Text>
          </Center>
        </Container>
      </Box>
      <Box
        pt={{base: '10px', md: '80px'}}
        pb={'80px'}
        // bgGradient="linear(180deg, rgba(15, 40, 232, 0.1), rgba(255, 255, 255, 0))"
      >

        <Container maxW={'container.lg'} px={10}>
          <Center>
            <Heading
              fontSize={'5xl'}
              bgGradient={silverGradient}
              // sx={{
              //   backgroundSize: "400% 400%",
              //   WebkitAnimation: "Gradient 5s ease infinite",
              //   MozAnimation: "Gradient 5s ease infinite",
              //   animation: "Gradient 5s ease infinite"
              // }}
              bgClip="text"
            >Exclusive partnership</Heading>
          </Center>
          <Center mt={'30px'} mb={'30px'}>
            <Heading fontSize={'2xl'}>Join our exclusive affiliate program if you have a large audience</Heading>

          </Center>
          <SimpleGrid columns={{base: 1, md: 3}} spacing={10} my={'50px'}>
            <BigPerkCard
              icon={FiDollarSign}
              title={"Larger revenue share"}
              body={'Get up to 25% commissions if you have an annual reach of at least 500 students.'}/>
            <BigPerkCard
              icon={FiPercent}
              title={"Bigger customer discount"}
              body={'Users that purchase through your referral link get up to 10% off.'}/>
            <PerkCard
              icon={FiStopCircle}
              title={"One-on-one support"}
              body={'We provide one-on-one support to help you get the most out of our partnership with you.'}/>
          </SimpleGrid>

          <Center>
            <Button leftIcon={<EmailIcon/>}
                    size={'lg'}
                    variant={'outline'}
                    mt={'40px'}
                    onClick={e => {
                      // @ts-ignore
                      window.location = "mailto:propellasg@propellasg.com"
                      e.preventDefault()
                    }}>
              Email us {!isMobile && <>at propellasg@propellasg.com</>}

            </Button>
          </Center>
        </Container>
      </Box>

    </Box>
  )
}