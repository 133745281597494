import {FC, useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {Box, Container} from "@chakra-ui/react";
import {QnState} from "../../Models/Question";
import {QLState, QuestionLoader} from "../Question/QuestionLoader";
import {useHistory, useParams} from "react-router-dom";
import {ActiveAttemptHeader} from "../ProblemSet/TopicProbSetAttemptHeader";

export const DoProblemSetPage = () => {
  const hist = useHistory()

  const [page, setPage] = useState(0)

  // @ts-ignore
  let {setID} = useParams()

  const questionsQuery = useQuery(
    ["set", setID!, 'qn-list'],
    QuestionService.getQuestionsForSet(setID!),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60

    }
  )

  const attemptQuery = useQuery(
    ["set", setID!, 'latest-att'],
    QuestionService.getLatestAttemptForSet(setID!),
    {
      staleTime: 1000 * 60,
      cacheTime: 1000 * 60
    }
  )
  const attemptID = attemptQuery.data?.ID

  const userAnswersQuery = useQuery(["set", setID, 'latest-att', 'ans'],
    QuestionService.getUserAnswersForAttempt(attemptID!), {
      enabled: !!attemptID
    }
  )

  const markSetDone = useMutation(
    QuestionService.markSetAsDone,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['usr-ans'])
        hist.push(`/set/${setID}/review?attemptID=${attemptQuery.data?.ID}`)

      }
    })

  const [hasSetPage, setHasSetPage] = useState(false)

  // this sets the question to show
  useEffect(() => {
    if (!hasSetPage && questionsQuery.isFetched && attemptQuery.isFetched && userAnswersQuery.isFetched) {

      // find the first unanswered question and set the page to that
      let tempPage = questionsQuery.data!.length - 1
      for (let i = 0; i < questionsQuery.data!.length; i++) {
        if (userAnswersQuery.data!.find(y => y.QnID === questionsQuery.data![i]) === undefined) {
          tempPage = Math.min(tempPage, i)
          break
        }
      }
      if (page === 0) {
        setPage(tempPage)
        setHasSetPage(true)

      }
    }

  }, [questionsQuery.data, attemptQuery.data, userAnswersQuery.data])
  const queryClient = useQueryClient()
  return (
    <Container maxW="container.lg" pt={'20px'}>

      <Box mb={'20px'}>
        {
          (questionsQuery.isFetched && attemptQuery.isFetched &&
            userAnswersQuery.isFetched) &&
          <>
              <ActiveAttemptHeader
                  attempt={attemptQuery.data}
                  page={page}
                  setPage={setPage}
                  isTest={!!attemptQuery.data?.DelayMarking}
                  qnStates={questionsQuery.data?.map(qnID => {
                    const ans = userAnswersQuery.data?.find(y => y.QnID === qnID)
                    if (!!ans) {
                      return QnState.answered
                    } else {
                      return QnState.unanswered
                    }
                  }) ?? []}
              />
              <QuestionLoader
                  defaultState={QLState.active}
                  stayInActiveAfterAnswering={attemptQuery.data?.DelayMarking}
                  qnNumber={Math.min(page, questionsQuery.data!.length - 1) + 1}
                  totalQnCount={questionsQuery.data!.length}
                  qnID={questionsQuery.data![Math.min(page, questionsQuery.data!.length - 1)]}
                  nextButtonTitle={page + 1 >= questionsQuery.data!.length ? "Finish" : "Next"}
                  attemptID={attemptQuery.data!.ID}
                  hidePageControls={false}
                  prevQuestion={page > 0 ? () => setPage(page - 1) : undefined}
                  nextQuestion={() => {
                    if (page + 1 >= questionsQuery.data!.length) {
                      markSetDone.mutate(setID!)
                    } else {
                      setPage(page + 1)
                    }
                  }
                  }

                  nextQuestionIsLoading={markSetDone.isLoading}
                  addNewUserAnswerToQueryData={(newAnsHist) => {
                    queryClient.setQueryData(["set", setID, 'latest-att', 'ans'],
                      (userAnswersQuery.data ?? []).concat(newAnsHist))
                  }}
              />

          </>


        }
      </Box>
    </Container>
  )
}
