import React, {FC, useEffect} from "react";
import {
  Box,
  Center,
  useColorModeValue
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import {Hero} from "./Hero";
import {TestQuestion} from "./TestQuestion";
import {Features} from "./Features";
import {PaperContent} from "./PaperContent";
import {useURLQuery} from "../../Hooks/useURLQuery";
import useLocalStorage from "../../Hooks/use-local-storage";
import {MockTest} from "./MockTest";
import {useAuth} from "../../Contexts/AuthContext";
import {AuthService} from "../../Services/AuthService";
import { Commendation } from "./Commendation";
import {Commendation2} from "./Commendation2";
import {Compare} from "./Compare";
import {QnStats} from "./QnStats";

export const LandingPage: FC<{}> = ({}) => {
  const auth = useAuth()
  const hist = useHistory()
  const referee = useURLQuery().get("r")
  const setReferrerMutation = useMutation(AuthService.setReferrer, {
    onSuccess: ref => {
      console.log("referrer set")
      console.log(ref)
    },
  })
  const bg = useColorModeValue(
    "linear-gradient(rgba(255,255,255, 1), rgba(215,220,246, 0.9)), url('hero_img.webp')",
    "linear-gradient(rgba(4,24,96, 1), rgba(4,24,96, 0.8)), url('hero_img.webp')"
  )
  const [_, setReferee] = useLocalStorage<string | null>('referee', null)
  useEffect(()=>{
    if (referee !== null) {
      setReferee(referee)
      if (!!auth.user) {
        setReferrerMutation.mutate({referralID: referee})
      }
      hist.push('')
    }
  },[])
  return (
    <Box>
      <Box

        backgroundImage={bg}
        bgSize={'cover'}
        bgPos={'center'}
        bgRepeat={'no-repeat'}
        pb={'80px'}
      >
      <Hero/>
      <Features/>
      </Box>
      <Commendation/>

      <Center>
        <ChevronDownIcon
          fontSize={'4xl'}
          color={useColorModeValue('blackAlpha.300', 'whiteAlpha.200')}
          mt={'-40px'}
        />
      </Center>
      <PaperContent/>

      <TestQuestion/>
      <Commendation2/>

      <MockTest/>
      <QnStats/>
      <Compare/>
    </Box>)
}


