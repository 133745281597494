import {ChangeEvent, ChangeEventHandler, FC, useState} from "react";
import {useMutation} from "react-query";
import {AdminService} from "../../Services/AdminService";
import {
  Button, HStack, Input,
  Spinner, Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  VStack
} from "@chakra-ui/react";
import {CSQuestion2, CSSQuestion2} from "../../Models/Question";

export const CSQEditView: FC<{
  question: CSQuestion2
  didSave: () => void
}> = ({question, didSave}) => {
  const updateQnMutation = useMutation(AdminService.updateQuestion, {
    onSuccess: data => {
      didSave()
    }
  })

  const [qn, setQn] = useState(question)

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name === 'Body') {

      setQn(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))

    }
  }


  const handleSubQnChange = (qnID: number) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

    setQn(prevState => ({
      ...prevState,
      Questions: prevState.Params?.map((subQn, j) => {
        if (j !== qnID) {
          return subQn
        } else {
          let newSubQn = subQn

          if (event.target.name === 'R') {
            newSubQn.R = Number(event.target.value)

          } else if (event.target.name === "ans") {

            newSubQn.A = newSubQn.A.map((ans, i) => {
              return i.toString() === event.target.id
                ? event.target.value
                : ans
            })
          } else if (event.target.name === 'B' ||
            event.target.name === 'E') {
            newSubQn[event.target.name] = event.target.value

          }

          return newSubQn
        }
      })
    }))

  }
  const deleteCSSQ = (qnID: number) => () => {

    setQn(prevState => ({
      ...prevState,
      Questions: prevState.Params?.filter((subQn, j) => {
        return j !== qnID;
      })
    }))

  }

  return (
    <VStack alignItems={'flex-start'}>{
      updateQnMutation.isLoading ?
        <Spinner/> :
        <>
          <Text fontWeight={'bold'}>CSQ Body</Text>
          <Textarea mb={'20px'} name={'Body'} onChange={handleChange} height={'500px'} value={qn.Body}/>
          <Text fontWeight={'bold'}>CSSQs</Text>

          <Tabs variant='soft-rounded' colorScheme='green' width='full' isLazy>
            <TabList>
              {
                qn.Params?.map((qn, i) => {
                  return <Tab>{i + 1}</Tab>
                })
              }
            </TabList>
            <TabPanels>
              {
                qn.Params?.map((qn, i) =>
                    // <Box width={'full'} border={'1px solid black'} p={'15px'} borderRadius={'8px'}>
                    <TabPanel>
                      <CSSQEditBody qn={qn} handleChange={handleSubQnChange(i)} deleteCSSQ={deleteCSSQ(i)}/>

                    </TabPanel>
                  // </Box>
                )
              }
            </TabPanels>

          </Tabs>
          <Button onClick={() => {
            updateQnMutation.mutate({question: qn})
          }}>Save</Button>
        </>
    }

    </VStack>
  )
}

export const CSSQEditBody = (props: { qn: CSSQuestion2, handleChange: ChangeEventHandler, deleteCSSQ?: () => void }) => {
  return (
    <>
      {
        props.deleteCSSQ === undefined ?
          <Text fontWeight={'bold'}>MCQ Body</Text>

          :
          <>
            {/*<Button onClick={props.deleteCSSQ}>Delete CSSQ</Button>*/}
            <Text fontWeight={'bold'}>CSSQ Body</Text>
          </>
      }

      <Textarea mb={'20px'} name={'B'} onChange={props.handleChange} value={props.qn.B}/>
      {
        props.qn.A.map((ans, i) =>
          <HStack w={'full'}>
            <Text fontWeight={'bold'}>{i + 1}</Text>
            <Textarea
              name={"ans"}
              id={i.toString()}
              onChange={props.handleChange}
              backgroundColor={i + 1 === props.qn.R ? 'green.500' : undefined}
              value={ans}/>
          </HStack>
        )
      }
      <HStack mb={'20px'}>
        <Text fontWeight={'bold'}>Answer</Text>
        <Input type={'number'} name={"R"} onChange={props.handleChange} value={props.qn.R}/>
      </HStack>
      <Text fontWeight={'bold'}>Explanation</Text>
      <Textarea name={'E'} onChange={props.handleChange} value={props.qn.E}/>

    </>
  )
}