import {FC, useEffect, useState} from "react";
import {
  ProblemSetWithAttempt,
  QnState,
  Topic,
  UserProblemSetAttempt
} from "../../Models/Question";
import {
  Box,
  Button, Heading,
  HStack,
  SimpleGrid,
  Spacer, Stack,
  Tag,
  Text, useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {useAuth} from "../../Contexts/AuthContext";
import {useMutation} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {useHistory} from "react-router-dom";
import {Icon} from "@chakra-ui/icons";
import {IoReturnDownBack} from "react-icons/all";
import dayjs from "dayjs";


const TopicHeader: FC<{
  topic?: Topic
  pSetWAtt?: ProblemSetWithAttempt,
  attempt?: UserProblemSetAttempt,
}> = ({topic, pSetWAtt, attempt}) => {
  const hist = useHistory()
  const btnColor = useColorModeValue("gray.200", "gray.700")

  return (
    <>{
      topic !== undefined ?
        <>

          {
            pSetWAtt !== undefined
              ? <Heading
                onClick={() => {
                  hist.push(`/topic/${topic.ID}`)
                }}

                _hover={{
                  cursor: 'pointer',
                  bg: btnColor,
                  transform: "scale(1.02)",
                  borderRadius: 'lg'
                }}
              >
                <Icon as={IoReturnDownBack}/>
                {topic.Name}
              </Heading>
              :
              <Heading>{topic.Name}</Heading>

          }</> :
        <></>
    }
    </>

  )
}
const PSetHeader: FC<{
  topic?: Topic
  pSetWAtt?: ProblemSetWithAttempt,
  attempt?: UserProblemSetAttempt,
}> = ({topic, pSetWAtt, attempt}) => {
  const createNewAttempt = useMutation(QuestionService.startNewAttempt, {
    onSuccess: () => {
      hist.push(`/set/${pSetWAtt?.ProblemSet.ID}/do`)

    }
  })


  const inProgress = () => {
    return pSetWAtt?.Attempts.find(att => att.Score === null) !== undefined
  }

  const incompleteAttempt = () => {
    return pSetWAtt?.Attempts.find(att => att.Score === null)

  }
  const auth = useAuth()

  const hist = useHistory()
  const btnColor = useColorModeValue("gray.200", "gray.700")

  return (
    <>
      {
        pSetWAtt !== undefined && !pSetWAtt.ProblemSet.IsGenerated &&
        <Box>
          {
            attempt === undefined
              ? <Heading fontSize={'xl'}>{'Set ' + pSetWAtt.ProblemSet.Title}</Heading>
              : <Heading
                fontSize={'xl'}
                px={'5px'}
                _hover={{
                  cursor: 'pointer',
                  bg: btnColor,
                  transform: "scale(1.02)",
                  borderRadius: 'lg'
                }}
                onClick={() => {
                  hist.push(`/set/${pSetWAtt?.ProblemSet.ID}`)
                }
                }
              ><Icon as={IoReturnDownBack}/>{'Set ' + pSetWAtt.ProblemSet.Title}</Heading>

          }

          {
            auth.testAuth(pSetWAtt.ProblemSet.ForFree ?
              0 :
              pSetWAtt.ProblemSet.Paper ?? 3) ?
              <VStack alignItems={'flex-start'}>

                {
                  inProgress() &&
                  <Tag variant='outline' mt={'6px'} mb={'15px'}>In Progress</Tag>
                }
                {
                  attempt === undefined &&
                  <>
                    {
                      inProgress() ?

                        <Button
                          my={"10px"}
                          isLoading={createNewAttempt.isLoading}
                          size={'lg'}
                          onClick={() => {
                            createNewAttempt.mutate({
                              setID: pSetWAtt.ProblemSet.ID,
                              delayMarking: !!incompleteAttempt()?.DelayMarking
                            })
                          }}>
                          Continue {
                          incompleteAttempt()?.DelayMarking ? 'Test' : "Practice"
                        }
                        </Button> :
                        <HStack>
                          <Button
                            my={"10px"}
                            isLoading={createNewAttempt.isLoading}
                            size={'lg'}
                            onClick={() => {
                              createNewAttempt.mutate({setID: pSetWAtt.ProblemSet.ID,
                                delayMarking: pSetWAtt.ProblemSet.Type === 'Full'})
                            }}> {
                              pSetWAtt.ProblemSet.Type === 'Full' ? 'Start Test' : "Start Practising"
                          }
                          </Button>
                          {/*<Button*/}
                          {/*  my={"10px"}*/}
                          {/*  isLoading={createNewAttempt.isLoading}*/}
                          {/*  size={'lg'}*/}
                          {/*  onClick={() => {*/}
                          {/*    createNewAttempt.mutate({setID: pSetWAtt.ProblemSet.ID, delayMarking: true})*/}
                          {/*  }}>*/}
                          {/*  Test*/}
                          {/*</Button>*/}
                        </HStack>
                    }
                  </>

                }
              </VStack>
              :
              <>
                <Text mt={'10px'}>You do not have the right subscription to view this problem set.</Text>
                <Button size={'sm'} onClick={() => {
                  hist.push('/pricing')
                }}>See Pricing</Button>
              </>
          }
        </Box>
      }
    </>

  )
}

const ScoreCard: FC<{
  topic?: Topic,
  pSetWAtt?: ProblemSetWithAttempt,
  attempt?: UserProblemSetAttempt,
}> = ({pSetWAtt, attempt}) => {
  return (
    <>
      {
        attempt !== undefined &&
        <>
            <Spacer/>

            <VStack alignItems={'flex-start'} spacing={0}>
                <Text>
                    Score
                </Text>
                <Text
                    fontSize={'3xl'}
                    fontWeight={'medium'}
                >
                  {attempt.Score + "/" + pSetWAtt?.ProblemSet.MaxScore}
                </Text>
            </VStack>
        </>

      }</>
  )
}

const AttemptHeader: FC<{
  topic?: Topic,
  pSetWAtt?: ProblemSetWithAttempt,
  attempt?: UserProblemSetAttempt,
}> = ({topic, pSetWAtt, attempt}) => {
  return (
    <>
      {
        attempt !== undefined &&
        <Box>
            <Heading fontSize={'md'}>
              {'Attempt ' + ((pSetWAtt?.Attempts.findIndex(x => x.ID === attempt?.ID) ?? 0) + 1)}
            </Heading>

        </Box>
      }
    </>
  )
}

export const PaperHeader: FC<{
  paperID: number
}> = ({paperID}) => {
  const hist = useHistory()
  const btnColor = useColorModeValue("gray.200", "gray.700")
  return (
    <Heading
      fontSize={'2xl'}
      onClick={() => {
        hist.push(`/paper/${paperID}`)
      }}

      _hover={{
        cursor: 'pointer',
        bg: btnColor,
        transform: "scale(1.02)",
        borderRadius: 'lg'
      }}
    >
      <Icon as={IoReturnDownBack}/>
      {`Paper ${paperID}`}
    </Heading>

  )
}

export const TopicProbSetAttemptHeader: FC<{
  topic?: Topic,
  pSetWAtt?: ProblemSetWithAttempt,
  attempt?: UserProblemSetAttempt,
}> = ({
        topic,
        pSetWAtt,
        attempt,
      }) => {

  return (
    <Box
      my={'30px'}
      p={'30px'}
      borderRadius={'xl'}
      boxShadow={'rgba(79, 114, 205, 0.3) 0px 4px 42px 0px'}
    >{
      <HStack alignItems={'center'}>
        <Box>
          <PaperHeader paperID={topic?.Paper ?? pSetWAtt?.ProblemSet.Paper ?? 0}/>
          <TopicHeader topic={topic} pSetWAtt={pSetWAtt} attempt={attempt}/>
          <PSetHeader topic={topic} pSetWAtt={pSetWAtt} attempt={attempt}/>
          <AttemptHeader topic={topic} pSetWAtt={pSetWAtt} attempt={attempt}/>
        </Box>
        <ScoreCard topic={topic} pSetWAtt={pSetWAtt} attempt={attempt}/>
      </HStack>
    }

    </Box>
  )
}

export const ActiveAttemptHeader: FC<{
  attempt?: UserProblemSetAttempt,
  page?: number,
  isTest: boolean,
  setPage?: React.Dispatch<React.SetStateAction<number>>,
  qnStates: QnState[], // unanswered, answered, correct, wrong
}> = ({
        attempt,
        page,
        setPage,
        isTest,
        qnStates
      }) => {

  return (
    <Box
      my={'30px'}
      p={'30px'}
      borderRadius={'xl'}
      boxShadow={'rgba(79, 114, 205, 0.3) 0px 4px 42px 0px'}
    >{
      <Stack flexDirection={['column', 'row']} alignItems={'flex-start'}>
        <GridQuestionProgressView page={page} setPage={setPage} qnStates={qnStates} isTest={isTest}/>
        {
          isTest && <CountUpTimer startTime={dayjs(attempt?.CreatedAt)}/>

        }
      </Stack>
    }

    </Box>
  )
}


const CountUpTimer: FC<{ startTime: dayjs.Dayjs }> = ({startTime}) => {
  const [timeDiffInSeconds, setTimeDiffInSeconds] = useState(0)
  useEffect(() => {
    let timerInterval = setInterval(() => {
      setTimeDiffInSeconds(dayjs().diff(startTime, 'second'))
    }, 1000)
    return () => {
      clearInterval(timerInterval)
    }
  }, [startTime])
  return (<>
      <Spacer/>
      <VStack alignItems={'flex-end'}>

        <Text
          fontSize={'2xl'}
          fontFamily={'monospace'}
        >{new Date(timeDiffInSeconds * 1000).toISOString().substr(11, 8)}</Text>

      </VStack>

    </>
  )
}

const GridQuestionProgressView: FC<{
  isTest: boolean,
  page?: number,
  setPage?: React.Dispatch<React.SetStateAction<number>>
  qnStates: QnState[], // unanswered, answered, correct, wrong

}> = ({isTest, setPage, qnStates, page}) => {
  // console.log(page)
  const isMobile = useBreakpointValue({base: true, sm: false, md: false})

  return (
    <Box pr={'20px'} mr='10px' borderRight={isMobile ? '' : 'solid rgba(79, 114, 205, 0.3) 1px'}>
      <VStack alignItems={'flex-start'}>
        <Heading fontSize={'xl'}>Progress</Heading>
        <SimpleGrid columns={10} spacing={'8px'}>
          {
            qnStates.map((s, i) => <QnBox
              key={i}
              isCurrent={i === page}
              onClick={() => {
                if (isTest && setPage) {
                  setPage(i)
                }
              }
              } qnState={s}/>)
          }

        </SimpleGrid>
      </VStack>
    </Box>


  )
}


const QnBox: FC<{
  isCurrent: boolean
  qnState: QnState
  onClick: () => void
}> = ({isCurrent, onClick, qnState}) => {
  const unvisitedQnColor = useColorModeValue('rgba(0,0,100,0.1)', 'rgba(150,150,255,0.1)')
  const visitedColor = useColorModeValue('rgb(55,101,255)', 'rgba(150,150,255,0.3)')
  const selectedColor = useColorModeValue('brand2.400', 'brand2.700')
  const selectedShadow = useColorModeValue('rgba(38, 77, 200, 0.3) 2px 2px 10px 0px',
    'rgba(38, 77, 200, 0.5) 1px 1px 10px 0px')
  return (
    <Box
      h={'20px'}
      w={'20px'}
      sx={isCurrent ? {
        WebkitAnimation: "QuestionProgressIndicatorSizeAnimation 1s ease infinite",
        MozAnimation: "QuestionProgressIndicatorSizeAnimation 1s ease infinite",
        animation: "QuestionProgressIndicatorSizeAnimation 1s ease infinite"
      } : {}}
      // transform={isCurrent ? 'scale(0.8)' : 'scale(1)'}
      bg={isCurrent ?
        selectedColor :
        qnState === QnState.unanswered ?
          unvisitedQnColor :
          visitedColor
      }
      onClick={onClick}
      _hover={
        {cursor: 'pointer'}
      }
      borderRadius={'4'}
    />

  )
}