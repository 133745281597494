import {ApiClient} from "./APIClient";
import {Pricing} from "../Models/Price";
import {ChargeHistory} from "../Models/Auth";

const createCheckoutSession = async (): Promise<any> => {
  return await ApiClient.post('/api/create-checkout-session')
}

const getPlans = (referreeCode?: string): () => Promise<Pricing> => {
  return async () => await ApiClient.get(`/api/plans?referee=${referreeCode ?? ""}`).then(resp => resp.data.Response)
}

const cancelSubscription = async () => {
  return await ApiClient.post("/api/subscription/cancel")
}

const unsubPromoNewsletter = (email: string): () => Promise<void> => {
  return async () => await ApiClient.put(`/api/user/unsubscribe-promo/${email}`).then(resp => resp.data.Response)
}

const resetProgress = async () => {
  return await ApiClient.put(`/api/user/reset-progress`).then(resp => resp.data.Response)
}

const transferSubscription = (): (email: string) => Promise<ChargeHistory> => {
  return async (email) => await ApiClient.post(`/api/transfer-acc/${email}`).then(resp => resp.data.Response)
}


export const SubscriptionService = {
  createCheckoutSession,
  getPlans,
  cancelSubscription,
  unsubPromoNewsletter,
  resetProgress,
  transferSubscription
}