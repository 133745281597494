import {FC} from "react";
import {Container} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {TransferGapPeriod} from "./ProfilePage";

const terms = `
# Terms & Conditions
## Agreement to Terms
These Terms of Use govern your use of Propella website owned or operated by Propella(the "Website"). Please read these Terms of Use carefully before using the Website.

Propella may change these Terms and Conditions at any time and in its sole discretion. The modified Terms and Conditions will be effective immediately upon posting and you agree to the newly posted Terms and Conditions by continuing your use of the Website. You are responsible for staying informed of any changes.

## Accounts
You are required to create an account and a login code will be emailed to you to use the features on the Website. You agree to provide, maintain and update true and accurate information about yourself as prompted by the registration process.

Account(s) are non-transferable and you may not impersonate any person or entity or misrepresent your identity or affiliation with any person or entity, including using another person's username, password or other account information. Please note that any attempt to share and/or sell your account(s) will result in termination of your account(s) without prior notice.

You are entirely responsible for maintaining the confidentiality of your account. You agree to safeguard your account from access by others. If you believe that your account(s) have been compromised, you must immediately contact us by emailing to support@propellasg.com. You may be liable for losses incurred by Propella or others due to any unauthorised use of your account(s).

## Subscriptions
Your active subscription is transferable. You may pass your active subscription to someone else provided that the subscription is not ending is less than 5 days, 
and that you have purchased the subscription yourself or you have received the subscription through a transfer, and have held it through the "Transfer Gap Period".

In order to receive a transfer, the target user must have a registered email with Propella and no active subscription.

The current "Transfer Gap Period" is ${TransferGapPeriod} months and Propella has the right to change this at any time without any notice. 

There is no need to cancel your subscription since Propella does not autobill you. 

All fees paid or accrued for any services are non-refundable. Propella will not prorate and refund any unused portion of any subscription.

## Use of Website
Propella authorises you to view and use the materials at the Website provided you retain all copyright and other proprietary notices contained in the original materials on any copies of the materials.

You may not modify the materials at the Website in any way or reproduce or publicly display, perform, or distribute or otherwise use them for any public or commercial purpose. For purposes of these Terms, any use of these materials on any other Website for any purpose is prohibited. The materials at the Website are copyrighted and any unauthorised use of any materials at this Website may violate copyright, trademark, and other laws.

The materials at this Website are designed based on the CEA syllabus structure, with the intent of providing practice questions to people trying to become a Real Estate Salesperson (RES) in Singapore. The questions, case studies and explanations are not from previous CEA examinations.

Stories and situations in our questions and case studies are fictitious. Any similarity to actual persons, living or dead, or actual events, is purely coincidental.

## Testimonial Program
Propella reserves the right to review all testimonials prior to use, which includes but not limited to correction of grammatical and writing errors.

By submitting your testimonial herein, you agree and acknowledge that Propella may use your testimonial for marketing purposes.

We reserve the right, in our sole discretion, to start, suspend or cancel the Testimonial Program at any time.

## Termination
Propella has the right to terminate your account and access to the Websites for any reason, including, without limitation, if Propella, in its sole discretion, considers your use to be unacceptable or in the event of any breach by you of the Terms & Conditions.

`

export const TOS:FC = () => {
 return (
   <Container maxW={'container.lg'}>
       <ReactMarkdown linkTarget={'_blank'} className={'markdown-body'} children={terms} remarkPlugins={[remarkGfm]} />
   </Container>
 )
}