import {FC, useState} from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Text,
  Textarea, useToast
} from "@chakra-ui/react";
import {useMutation, useQuery} from "react-query";
import {QnMgrService} from "../../Services/QnMgrService";
import {MCQuestion2, Question2, QuestionType, SAQuestion2} from "../../Models/Question";
import {SAQEditView} from "../Question/SAQEditView";
import {MCQEditView} from "../Question/MCQEditView";

export const QuestionViewer: FC<{}> = ({}) => {
  const [page, setPage] = useState(1)
  const [selectedQn, setSelectedQn] = useState<Question2<any> | undefined>(undefined)
  const [searchStr, setSearchStr] = useState('')

  const questionsQuery = useQuery(['qnMgr', page, searchStr],
    QnMgrService.GetQuestions('propella', searchStr, page, ''),
    {
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    })

  return (
    <Container maxW={'container.xl'}>
      <TopPane onSearch={() => questionsQuery.refetch()} searchStr={searchStr} setSearchStr={setSearchStr}/>
      <Flex>
        <LeftPane questions={questionsQuery.data} page={page} setPage={setPage} setSelectedQn={setSelectedQn}/>
        <RightPane selectedQn={selectedQn} setSelectedQn={setSelectedQn}/>
      </Flex>

    </Container>
  )
}

const TopPane: FC<{
  searchStr: string,
  setSearchStr: React.Dispatch<React.SetStateAction<string>>,
  onSearch: () => void,
}> = ({searchStr, setSearchStr, onSearch}) => {
  return (
    <HStack>
      <Input value={searchStr} onChange={v => setSearchStr(v.target.value)}/>
      <Button onClick={() => onSearch()}>Search</Button>
    </HStack>
  )
}
const LeftPane: FC<{
  questions?: Question2<any>[],
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setSelectedQn: React.Dispatch<React.SetStateAction<Question2<any> | undefined>>,
}> = ({questions, page, setPage, setSelectedQn}) => {
  return (
    <>
      <Box maxW={'400px'}>
        <HStack>
          <Button onClick={() => setPage(p => Math.max(p - 1, 1))}>{"<"}</Button>
          <Text>{page}</Text>
          <Button onClick={() => setPage(p => p + 1)}>{">"}</Button>
        </HStack>

        <Box maxH={'80vh'} overflowY={'scroll'}>

          {
            questions?.map(qn =>
              <QuestionCell key={qn.ID} qn={qn} onClick={() => setSelectedQn(qn)}/>
            )
          }
        </Box>
      </Box>
    </>
  )
}


const QuestionCell: FC<{
  qn: Question2<any>,
  isSelected?: boolean,
  onClick?: () => void,
}> = ({qn, isSelected, onClick}) => {
  return (
    <Box
      onClick={onClick}
      border={'1px solid black'}
      borderRadius={'5px'}
      p={'4px'}
      my={'4px'}
      h={'5rem'}
    >
      {
        <Box>
            <Text fontSize={'sm'} noOfLines={2}>{qn.Body}</Text>

        </Box>
      }

    </Box>
  )
}
const RightPane: FC<{
  selectedQn: Question2<any> | undefined
  setSelectedQn:  React.Dispatch<React.SetStateAction<Question2<any> | undefined>>
}> = ({selectedQn, setSelectedQn}) => {
  return (
    <Box w={'full'}>
      {
        selectedQn?.QnType === QuestionType.mcq &&
        <Box w={'full'}>
            {/*<MCQViewer mcq={selectedQn}/>*/}
            <MCQEditView question={selectedQn} didSave={()=>{}} onDelete={()=>{setSelectedQn(undefined)}}/>
        </Box>
      }
      {
        selectedQn?.QnType === QuestionType.saq &&
        <Box w={'full'}>
            <SAQEditView question={selectedQn} didSave={()=>{}} onDelete={()=>{setSelectedQn(undefined)}}/>

        </Box>
      }

    </Box>
  )
}

// const MCQViewer: FC<{ mcq: MCQuestion2 }> = ({mcq}) => {
//   const toast = useToast()
//

//
//   const deleteQnMutation = useMutation(QnMgrService.DeleteQuestion, {
//     onSuccess: data => {
//       console.log(data)
//       toast({
//         position: "top",
//         title: "question deleted",
//         status: "success",
//         duration: 3000,
//         isClosable: true,
//       })
//     }
//   })
//   return (
//     <Box w={'full'}>
//
//       {miniHeader('ID')}
//       <Text>{mcq.ID}</Text>
//
//       {miniHeader('Created at')}
//       <Text>{mcq.CreatedAt}</Text>
//
//       {miniHeader('Updated at')}
//       <Text>{mcq.UpdatedAt}</Text>
//
//       {miniHeader('Body')}
//       <Textarea fontSize={'sm'} key={mcq.ID + 'body'} minH={'20rem'} w={'full'}>{mcq.Body}</Textarea>

//       <RadioGroup onChange={() => {
//       }} value={mcq.Answer}>
//         {
//           mcq.Params?.map((ans, i) => {
//             return <>
//               <HStack>
//                 {miniHeader('Option ' + (i + 1))}
//                 {
//                   <Radio value={i + 1}/>
//                 }
//               </HStack>
//
//               <Textarea fontSize={'sm'} key={mcq.ID + i.toFixed()}>{ans}</Textarea>
//             </>
//           })
//         }
//       </RadioGroup>
//       {miniHeader('Explanation')}
//       <Textarea fontSize={'sm'} key={mcq.ID + 'exp'}>{mcq.Explanation}</Textarea>
//       <Button onClick={()=>{deleteQnMutation.mutate({tenantID:"propella", questionID:mcq.ID})}}>Delete</Button>
//     </Box>
//   )
// }
// const SAQViewer: FC<{ saq: SAQuestion2 }> = ({saq}) => {
//   const toast = useToast()
//
//   const miniHeader = (title: string) => {
//     return (
//       <Heading mt={'10px'} ml={'5px'} size={'sm'}>{title}</Heading>
//     )
//   }
//
//   const deleteQnMutation = useMutation(QnMgrService.DeleteQuestion, {
//     onSuccess: data => {
//       console.log(data)
//       toast({
//         position: "top",
//         title: "question deleted",
//         status: "success",
//         duration: 3000,
//         isClosable: true,
//       })
//     }
//   })
//   return (
//     <Box w={'full'}>
//
//       {miniHeader('ID')}
//       <Text>{saq.ID}</Text>
//
//       {miniHeader('Created at')}
//       <Text>{saq.CreatedAt}</Text>
//
//       {miniHeader('Updated at')}
//       <Text>{saq.UpdatedAt}</Text>
//
//       {miniHeader('Body')}
//       <Textarea fontSize={'sm'} key={saq.ID + 'body'} minH={'20rem'} w={'full'}>{saq.Body}</Textarea>
//       <RadioGroup onChange={() => {
//       }} value={mcq.Answer}>
//         {
//           mcq.Params?.map((ans, i) => {
//             return <>
//               <HStack>
//                 {miniHeader('Option ' + (i + 1))}
//                 {
//                   <Radio value={i + 1}/>
//                 }
//               </HStack>
//
//               <Textarea fontSize={'sm'} key={mcq.ID + i.toFixed()}>{ans}</Textarea>
//             </>
//           })
//         }
//       </RadioGroup>
//       {miniHeader('Explanation')}
//       <Textarea fontSize={'sm'} key={mcq.ID + 'exp'}>{mcq.Explanation}</Textarea>
//       <Button onClick={()=>{deleteQnMutation.mutate({tenantID:"propella", questionID:mcq.ID})}}>Delete</Button>
//     </Box>
//   )
// }