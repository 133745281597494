import {MCQuestion2,} from "../../Models/Question";
import {FC, useEffect} from "react";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import {
  Box,
  HStack, Kbd,
  Spacer, Tag,
  Text, useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {IoCheckmarkSharp, IoCloseSharp} from "react-icons/all";
import {Icon} from "@chakra-ui/icons";
import dayjs from "dayjs";
import {QLState} from "./QuestionLoader";

export const MCQView: FC<{
  question: MCQuestion2,
  usrHistoricAns?: string,
  tempUsrAns?: string,
  loaderState: QLState,
  setTempUsrAns: React.Dispatch<React.SetStateAction<string | undefined>>,
  setReadyForSubmit: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({
        question,
        usrHistoricAns,
        tempUsrAns,
        loaderState,
        setTempUsrAns,
        setReadyForSubmit
      }) => {

  useEffect(() => {
    setTempUsrAns(usrHistoricAns)
  }, [usrHistoricAns])

  const upHandler = ({key}: { key: string }): void => {
    switch (key) {
      case "1":
      case "2":
      case "3":
      case "4":
        if (loaderState === QLState.active) {
          setTempUsrAns(key)
          setReadyForSubmit(true)
        }
        break

    }
  }
  useEffect(() => {
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [tempUsrAns, loaderState]);

  return (
    <VStack alignItems={'flex-start'} w={'full'}>
      {/*<Text>{question.ID}</Text>*/}
      {
        loaderState === QLState.review &&
        usrHistoricAns === undefined &&
          <Tag colorScheme={'red'} variant={'outline'}>SKIPPED</Tag>
      }
      {
        question.Body.split('\n').map(t => <Text key={t}>{t}</Text>)
      }
      <VStack alignItems={'flex-start'}>
        {
          question.Params?.map((ans, i) =>
            <MCQuestionOptionView
              idx={i}
              key={ans}
              isSelected={
                loaderState === QLState.active
                  ? String(i + 1) === tempUsrAns
                  : String(i + 1) === usrHistoricAns
              }
              isRightAnswer={
                loaderState === QLState.active
                  ? undefined
                  : question.Answer === String(i + 1)
              }
              optionText={ans}
              onClick={() => {
                setTempUsrAns(String(i + 1))
                setReadyForSubmit(true)
              }
              }/>)
        }
      </VStack>
      {loaderState !== QLState.active && !!question.Explanation  &&
          <>
              <Text pt={'20px'} pb={'10px'} textStyle={'h5'}>Explanation</Text>
            {
              question.Explanation?.split('\n').map(t =>
                <ReactMarkdown
                  linkTarget={'_blank'}
                  className={'markdown-body'} key={t}>{t}</ReactMarkdown>)
            }

          </>
      }


    </VStack>

  )

}

export const MCQuestionOptionView: FC<{
  idx: number,
  isSelected: boolean,
  optionText: string,
  onClick: () => void
  isRightAnswer?: boolean,
  showKeyboardKey?: boolean,
}> = ({idx, optionText, isSelected, onClick, isRightAnswer, showKeyboardKey = true}) => {
  let light = 'gray.100'
  let dark = 'gray.700'
  if (isRightAnswer === true) {
    light = 'green.300'
    dark = 'green.700'
  } else if (isSelected && isRightAnswer === false) {
    light = 'red.300'
    dark = 'red.700'
  } else if (isSelected) {
    light = 'brand.200'
    dark = 'brand.700'
  }

  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const hoverColor = useColorModeValue("brand2.100", "brand2.900")
  const clickColor = useColorModeValue("blue.300", "blue.600")
  return (
    <Box
      _active={isRightAnswer === undefined ? {
        bg: clickColor,
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      } : {}}
      onClick={() => {
        isRightAnswer === undefined && onClick()
      }}

      shadow={isRightAnswer === undefined ?
        isSelected ? "" : "md"
        : ""
      }
      rounded={"md"}
      bg={useColorModeValue(light, dark)}
      w={"auto"}
      px={'10px'}
      py={'5px'}

      _hover={isRightAnswer === undefined ? {
          bg: hoverColor,
          cursor: "pointer",
        }
        : {}}>
      <HStack alignItems={'center'}>
        {/*{*/}
        {/*  isRightAnswer === undefined && <Kbd>{idx + 1}</Kbd>*/}
        {/*}*/}


        <Text
          // sx={{
          //   '-webkit-touch-callout':'none',
          //   '-webkit-user-select':'none',
          //   '-khtml-user-select':'none',
          //   '-moz-user-select':'none',
          //   '-ms-user-select':'none',
          //   'user-select':'none'
          // }}
        >{
          showKeyboardKey && !isMobile && isRightAnswer === undefined && <Kbd mr={'6px'}>{idx + 1}</Kbd>
        }

          {optionText}
        </Text>
        <Spacer/>
        {
          isRightAnswer === true &&
            <Icon as={IoCheckmarkSharp} w={'24px'} h={'24px'}/>
        }
        {
          isRightAnswer === false && isSelected &&
            <Icon as={IoCloseSharp} w={'24px'} h={'24px'}/>
        }
      </HStack>

    </Box>
  )
}