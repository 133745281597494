import {useColorModeValue, Button, Flex} from "@chakra-ui/react";
import Pagination from "@choc-ui/paginator";
import {FC} from "react";

export const PageControl: FC<{
  pageCount: number,
  currentPage: number,
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
}> = ({pageCount, currentPage, setPageNumber}) => {
  const PageButton = (props: any) => {
    const activeStyle = {
      bg: useColorModeValue("brand.600", "brand.500"),
      color: useColorModeValue("white", "gray.200"),
    };
    return (
      <Button
        px={2}
        py={2}
        rounded="md"
        bg={useColorModeValue("gray.100", "gray.800")}
        color={useColorModeValue("gray.700", "gray.200")}
        opacity={props.disabled && 0.6}
        _hover={!props.disabled && activeStyle}
        cursor={props.disabled && "not-allowed"}
        onClick={props.onClick}
        {...(props.active && activeStyle)}
      >
        {props.children}
      </Button>
    );
  };
  return (
    <Flex
      py={'8px'}
      w="full"
      alignItems="center"
      justifyContent="center"
      flexWrap={'wrap'}
      gridGap={'8px'}
    >
      <Pagination
        size={'xs'}
        total={pageCount}
        pageSize={1}
        current={currentPage}
        paginationProps={{
          display: "flex",
        }}
        onChange={(currentPage) => {setPageNumber(currentPage!)}}
        pageNeighbours={1}
      />
    </Flex>

  );
};

