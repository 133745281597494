import React, {FC} from "react";
import {SAQuestion2} from "../../Models/Question";
import {
  Box,
  chakra,
  Flex,
  useBreakpointValue,

} from "@chakra-ui/react";
import {QuestionPreviewTag} from "./MCQPreview";

export const SAQPreview: FC<{
  question: SAQuestion2,
}> = ({question}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const ans = question.Answer.split("~")
  const body = question.Body.split("{{b}}")
  const out = body.map((pp, i) => <>

    <chakra.span fontSize={'sm'}>{pp}</chakra.span>
      {
        i !== body.length-1 &&
          <chakra.span fontSize={'sm'} as={'u'}>{ans[i].replaceAll("|", " or ")}</chakra.span>
      }
    </>

    )
  return (
    // <VStack alignItems={'flex-start'} w={'full'}>


    <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'}>
      <Box>
        {
          out
        }
      </Box>



      <QuestionPreviewTag color={'green'} type={'SAQ'}/>
    </Flex>

    // </VStack>
  )
}