import {MCQView} from "./MCQView";
import {
  Box,
  Text,
} from "@chakra-ui/react";
import {Watermark} from '@hirohe/react-watermark';
import {Question2} from "../../Models/Question";
import {SAQView} from "./SAQView";
import {CSQView} from "./CSQView";
import {QLState} from "./QuestionLoader";

export function QLQuestionDisplay(props: {
  noPerms: boolean,
  data?: Question2<any>,
  dataIsLoadingError: boolean,
  bypassServerGrading: boolean,
  tempUsrAns: string | undefined,
  loaderState: QLState,
  savedAns?: string,
  tempUsrAns1: (value: (((prevState: (string | undefined)) => (string | undefined)) | string | undefined)) => void,
  readyForSubmit: (value: (((prevState: boolean) => boolean) | boolean)) => void
}) {
  return <Box w={"full"}>
    {
      props.noPerms &&
        <Text>You do not have the right subscription to view this question.</Text>
    }
    <Watermark text={"propella"} textColor={'gray'} opacity={0.08} rotate={-20} textSize={25} gutter={20}
               fontFamily={"Nunito, sans-serif"}>

      {
        props.dataIsLoadingError && <>
              <Text color={'gray.500'}>Failed to fetch question.</Text>

              <Text color={'gray.500'}>Troubleshooting Tips:</Text>
              <Text color={'gray.500'}>- Check your Internet connection.</Text>
              <Text color={'gray.500'}>- Did you recently switch between wifi and cellular data?</Text>
              <Text color={'gray.500'}>- Are you connected to a company VPN that allows access to whitelist-only
                  websites?</Text>
          </>
      }
      {
        props.data && props.data.QnType === "mcq" &&
          <MCQView

              question={props.data}
              usrHistoricAns={props.bypassServerGrading ? props.tempUsrAns : props.savedAns}
              loaderState={props.loaderState}
              setTempUsrAns={props.tempUsrAns1}
              tempUsrAns={props.tempUsrAns}
              setReadyForSubmit={props.readyForSubmit}
          />
      }
      {
        props.data && props.data.QnType === "saq" &&
          <SAQView
              question={props.data}
              usrHistoricAns={props.bypassServerGrading ? (isString(props.tempUsrAns) ? props.tempUsrAns : undefined) : props.savedAns}
              loaderState={props.loaderState}
              setTempUsrAns={props.tempUsrAns1}
              tempUsrAns={props.tempUsrAns}
              setReadyForSubmit={props.readyForSubmit}
          />

      }
      {
        props.data && props.data.QnType === "csq" &&
          <CSQView
              question={props.data}
              usrHistoricAns={props.bypassServerGrading ? (isString(props.tempUsrAns) ? props.tempUsrAns : undefined) : props.savedAns}
              tempUsrAns={props.tempUsrAns}
              loaderState={props.loaderState}
              setTempUsrAns={props.tempUsrAns1}
              setReadyForSubmit={props.readyForSubmit}
          />
      }
    </Watermark>

  </Box>;
}

// for tempUserAns
function isString(temp: string | number | undefined): temp is string {
  if (temp === undefined) {
    return false
  }
  return (temp as string).split !== undefined
}