import React, {FC, useState} from "react";
import {
  Button,
  Divider,
  Input,
  useToast,
  Heading,
  Box,
  Td,
  Table, Thead, Th, Tbody, Tr
} from "@chakra-ui/react";
import {useMutation, useQuery} from "react-query";
import {ApiClient} from "../../Services/APIClient";
import {AdminUserFeedback} from "../../Models/Anal";
import {AdminService} from "../../Services/AdminService";
// @ts-ignore
import locale from "react-json-editor-ajrm/locale/en";

import {QuestionEditor} from "../Question/QuestionEditor";

export const AdminPanel: FC = ({}) => {
  return (
      <Box mt={'20px'} mx={'20px'}>
        <GiveSub/>
        <Divider my={'20px'}/>
        <GetAllUserFeedback/>
        <Divider my={'20px'}/>
      </Box>


  )
}

const GetAllUserFeedback: FC = () => {

  const getAllUserFeedback = useQuery<unknown, unknown, AdminUserFeedback[]>('allUserFeedback', () => {
    return ApiClient.get('/api/admin/feedbacks').then(resp => resp.data.Response)
  })

  const deleteUserFeedback = useMutation(AdminService.deleteFeedback, {
    onSuccess: () => {
      getAllUserFeedback.refetch()
    }
  })

  const [activeQnID, setActiveQnID] = useState<string|undefined>(undefined)
  const [activeFeedback, setActiveFeedback] = useState<string|undefined>(undefined)
  return (
    <Box>
      <Heading>User Feedback</Heading>
      <Box h={'30vh'} overflowX={'scroll'} overflowY={'scroll'}>
        <Table size={'lg'}>
          <Thead>
            <Tr>
              <Th>Created At</Th>
              <Th>Email</Th>
              <Th>Type</Th>
              <Th>QuestionID</Th>
              <Th>Body</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              getAllUserFeedback.data?.map(f => (
                <Tr onClick={() => {
                  setActiveQnID(f.QuestionID)
                  setActiveFeedback(f.ID)
                }} backgroundColor={
                  activeFeedback===f.ID
                    ? 'green'
                    : undefined}>
                  <Td fontSize={'xs'}> {f.CreatedAt}</Td>
                  <Td fontSize={'xs'}>{f.UserEmail}</Td>
                  <Td fontSize={'xs'}>{f.Type}</Td>
                  <Td fontSize={'xs'}>{f.QuestionID}</Td>
                  <Td>{f.Body}</Td>
                  <Td><Button size={'xs'} colorScheme={'red'} onClick={()=> {deleteUserFeedback.mutate(f.ID)}}>Delete</Button></Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </Box>
      <Divider my={'20px'}/>
      <Heading>Question Editor</Heading>
      {
        activeQnID !== undefined &&
        <QuestionEditor qnID={activeQnID} didEdit={()=>{}}/>

      }
      {
        activeQnID === undefined && <Box h='50vh'/>
      }
    </Box>

  )
}
const GiveSub: FC = () => {
  const toast = useToast()

  const giveSubMutation = useMutation<unknown, any, string>((userID) => {
      return ApiClient.get(`/api/admin/user/${userID}`)
    },
    {
      onSuccess: () => {

        toast({
          position: "top",
          title: "user given 3mth sub",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      },

      onError: () => {
        toast({
          position: "top",
          title: "Admin action failed",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    }
  )

  const [userID, setUserID] = useState('')
  return (
    <Box>
      <Heading>Give Sub</Heading>
      <Input placeholder={"user id"} value={userID} onChange={n => setUserID(n.target.value)}/>
      <Button onClick={() => {
        giveSubMutation.mutate(userID)
      }}>Give 3 mths combined plan</Button>
    </Box>
  )
}