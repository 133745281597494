import {FC, useEffect, useState} from "react";
import {Box, Container, Text, VStack, HStack} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {BlogService} from "../../Services/BlogService";
import {BlogPost} from "../../Models/Blog";
import ReactMarkdown from "react-markdown";
import {useHistory} from "react-router-dom";

export const BlogPage: FC = () => {

const hist = useHistory()
  const blogPostQuery = useQuery('blog-posts', BlogService.getBlogPosts())
  const [activeBlogPost, setActiveBlogPost] = useState<undefined | BlogPost>(undefined)

  useEffect(()=>{
    const newPath = hist.location.pathname.split("/")
    if (newPath.length > 2) {
      console.log(blogPostQuery.data?.find(post => post.Path === newPath[2]))
      setActiveBlogPost(blogPostQuery.data?.find(post => post.Path === newPath[2]))
    }
  },[blogPostQuery.isFetched])
  return (
    <Container maxW={'container.xl'}>
      <HStack>
        <VStack>
          <Box maxW={'400px'}>
            {
              blogPostQuery.data?.map(post => (
                <Box onClick={()=>{
                  setActiveBlogPost(post)
                  hist.push(post.Path)
                }
                }>
                  <Text>{post.ID}</Text>
                </Box>

              ))
            }
          </Box>
        </VStack>
        <Box>
          <ReactMarkdown>{activeBlogPost?.Body ?? ""}</ReactMarkdown>
        </Box>
      </HStack>

    </Container>
  )
}