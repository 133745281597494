import React, {FC, useState} from "react";
import {
  Box,
  Button,
  HStack,
  Text,
  Link,
  useColorMode,
  useBreakpointValue,
  Menu,
  IconButton,
  MenuList,
  MenuButton,
  MenuItem,
  Flex,
  useColorModeValue,
  Heading,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton, ModalBody, Textarea, ModalFooter, useToast, useDisclosure, RadioGroup, Stack, Radio
} from "@chakra-ui/react";
import {Link as RouterLink, useHistory} from "react-router-dom"
import {AiOutlineUser, HiOutlineChatAlt2} from "react-icons/all";
import {HamburgerIcon, Icon} from "@chakra-ui/icons";
import {useAuth} from "./Contexts/AuthContext";
import {useMutation} from "react-query";
import {UserFeedback} from "./Models/Anal";
import {ApiClient} from "./Services/APIClient";
import {GetLatestChargeHistory, ShouldShowAffiliateProgram} from "./Models/Auth";
import {DiscountEndingBanner} from "./Banner";
import {ChatBotButton} from "./Components/ChatBot/ChatBot";

export const Navbar: FC = () => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  return (
    <>
      {/*<NewUserReferralInfoBar/>*/}
      <DiscountEndingBanner/>
      {
        (isMobile ?? false) ?
          <MinimizedNavBar/>
          :
          <FullNavBar/>
      }
    </>
  )
}
const NewUserReferralInfoBar: FC = () => {
  const auth = useAuth()

  const bgColor = useColorModeValue('gray.200', 'brand.800')

  return (
    <>
      {
        GetLatestChargeHistory(auth.user?.SubscriptionInfo) === undefined &&
          <Box w={'full'} h={'30px'} bg={bgColor}>

          </Box>
      }
    </>
  )
}

const BrandLogo: FC = () => {
  return (
    <Box>
      <Heading
        bgGradient="linear(to-l, #264DC8, #FF0080)"
        bgClip="text"
        fontStyle={'normal'}
        fontWeight={'extrabold'}
        fontSize={'2xl'}
        lineHeight={'100%'}
        letterSpacing={'-0.02em'}
      >
        propella
      </Heading>
    </Box>

  )
}

const LoginButton: FC = () => {
  const hist = useHistory()

  return (
    <Button
      bgColor={'brand.700'}
      color={'white'}
      onClick={() => {
        hist.push(`/login?redirect=${hist.location.pathname + hist.location.search}`)
      }}>
      Sign Up / Login
    </Button>
  )
}

export const FullNavBar: FC = () => {
  const hist = useHistory()
  const auth = useAuth()
  // console.log(auth.user)

  const bgColor = useColorModeValue('whiteAlpha.900', 'brand.900')

  return (
    <Flex
      flexDirection={'row'}
      // @ts-ignore
      sx={{position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0',}}
      zIndex={'300'}
      boxShadow={'sm'}
      alignItems={'center'}
      justify-content={'space-between'}
      px={'20px'}
      py={'10px'}
      bg={bgColor}
    >
      <Box
        as={'button'}
        pr='10px'
        pb={'2px'}
        mr={'10px'}
        h={'40px'}
        onClick={() => {
          hist.push("")
        }}
        borderRight={'thin solid'}

        borderColor={'gray.300'}

      >

        <BrandLogo/>


      </Box>
      {
        auth.user !== null ?
          <nav>
            <Link as={RouterLink} className='button' to="/paper/1" px={'5px'} borderRadius={'md'}>Paper 1</Link>
            <Link as={RouterLink} className='button' to="/paper/2" px={'5px'} borderRadius={'md'}>Paper 2</Link>
            <Link as={RouterLink} className='button' to="/saved" px={'5px'} borderRadius={'md'}>Saved</Link>
            <Link as={RouterLink} className='button' to="/discuss" px={'5px'} borderRadius={'md'}>Discuss</Link>
            {/*<Link as={RouterLink} className='button' to="/commons" px={'5px'} borderRadius={'md'}>Commons</Link>*/}

            {
              auth.user.SubscriptionInfo !== undefined && auth.user.SubscriptionInfo.Perm === 0 &&
                <Link as={RouterLink} className='button' to="/pricing" px={'5px'} borderRadius={'md'}>Pricing</Link>

            }
          </nav>
          :
          <nav>
            <Link as={RouterLink} className='button' to="/paper/1" px={'5px'} borderRadius={'md'}>Paper 1</Link>
            <Link as={RouterLink} className='button' to="/paper/2" px={'5px'} borderRadius={'md'}>Paper 2</Link>
            <Link as={RouterLink} className='button' to="/pricing" px={'5px'} borderRadius={'md'}>Pricing</Link>

          </nav>
      }
      <Box flex={'4 4 0'}/>
      <HStack>
        {
          auth.user === null
            ?
            <LoginButton/>
            : <UserButton/>
          // <Button size={'xs'} onClick={() => {
          //   auth.logout()
          // }}>
          //   Logout
          //   {/*<AiOutlineUser/>*/}
          // </Button>

        }
        <ChatBotButton/>
        <PropellaMenuButton/>
      </HStack>

    </Flex>

  )
}


const UserButton: FC = () => {
  const auth = useAuth()
  const hist = useHistory()
  return (
    auth.user !== null ?
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="user-account"
          icon={<AiOutlineUser/>}

          // variant="outline"
        />
        <MenuList>

          <MenuItem
            onClick={() => {
              hist.push('/profile')
            }}>
            Profile ({auth.user.Email})

          </MenuItem>
          <MenuItem onClick={() => {
            auth.logout()
            hist.push("")
          }}>
            {"Logout"}
          </MenuItem>

        </MenuList>
      </Menu>
      : <></>
  )
}

const PropellaMenuButton: FC = () => {
  const {onOpen, onClose, isOpen} = useDisclosure()
  const hist = useHistory()
  const auth = useAuth()
  const {toggleColorMode} = useColorMode()
  // const showProminentFeedbackButton = useBreakpointValue({base: false, md: true})
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon/>}
          // variant="outline"
        />
        <MenuList>
          {
            auth.user !== null ?
              <>
                <MenuItem onClick={() => {
                  hist.push('/saved')
                }}>
                  Saved
                </MenuItem>
                {
                  <MenuItem onClick={() => {
                    hist.push('/discuss')
                  }}>
                    Discuss
                  </MenuItem>
                }

                <MenuItem onClick={onOpen}>
                  Feedback
                </MenuItem>


              </>
              :
              <>
                <MenuItem onClick={() => {
                  hist.push('/paper/1')
                }}>
                  Paper 1
                </MenuItem>
                <MenuItem onClick={() => {
                  hist.push('/paper/2')
                }}>
                  Paper 2
                </MenuItem>


              </>
          }
          <MenuItem onClick={() => {
            hist.push('/pricing')
          }}>
            Pricing
          </MenuItem>
          {
            ShouldShowAffiliateProgram(auth.user?.SubscriptionInfo) &&
              <MenuItem onClick={() => {
                hist.push('/profile')
              }}>
                  Affiliate Program
              </MenuItem>
          }


          <MenuItem onClick={() => {
            hist.push('/updates')
          }}>
            Updates
          </MenuItem>
          <MenuItem onClick={toggleColorMode}>
            Toggle Dark/Light Mode
          </MenuItem>
        </MenuList>
      </Menu>
      <GeneralFeedbackForm isOpen={isOpen} onClose={onClose}/>
      {/*{*/}
      {/*  auth.user !== null && showProminentFeedbackButton &&*/}
      {/*  <IconButton*/}
      {/*      w={'60px'}*/}
      {/*      h={'60px'}*/}
      {/*      p={'12px'}*/}
      {/*      borderRadius={'full'}*/}
      {/*      position={'fixed'}*/}
      {/*      bottom={'200px'}*/}
      {/*      right={'20px'}*/}
      {/*      aria-label={'Give feedback'}*/}
      {/*      as={MdOutlineFeedback}*/}
      {/*      onClick={onOpen}*/}
      {/*  />*/}
      {/*}*/}

    </>
  )
}

export const GeneralFeedbackForm: FC<{
  isOpen: boolean,
  onClose: () => void,

}> = ({isOpen, onClose}) => {
  const hist = useHistory()
  const toast = useToast()
  const sendFeedbackMutation = useMutation((feedback: UserFeedback) => {
      return ApiClient.post('/api/anal/user/feedback', feedback)
    },
    {
      onSuccess: () => {

        toast({
          position: "top",
          title: "Feedback Received",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        onClose()
        setFeedbackBody('')
      },
      onError: () => {
        toast({
          position: "top",
          title: "Feedback Failed",
          description: "An error has occurred ): Thanks for trying though.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    })
  const [feedbackType, setFeedbackType] = useState('bug')


  const handleClick = () => {
    sendFeedbackMutation.mutate(
      {
        Type: feedbackType,
        CurrentURL: hist.location.pathname,
        Browser: '',
        ProblemSetID: '',
        QuestionID: '',
        Body: feedbackBody
      }
    )
  }

  const [feedbackBody, setFeedbackBody] = useState('')


  return (

    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>General Feedback</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <RadioGroup defaultValue="bug" mb={'20px'} onChange={nextValue => setFeedbackType(nextValue)}>
            <Stack spacing={5} direction="row">
              <Radio size={'lg'} colorScheme="red" value="bug">
                Bug
              </Radio>
              <Radio size={'lg'} colorScheme="green" value="improvement">
                Improvement
              </Radio>
            </Stack>
          </RadioGroup>
          <Text fontSize={'sm'} mb={'20px'}>For feedback on specific questions, please use the question's feedback
            button.</Text>
          <Textarea
            size={'lg'}
            onChange={event => {
              setFeedbackBody(event.target.value.slice(0, 1000))
            }
            }
            value={feedbackBody}
          />
          <Text color={'gray.500'}>Max characters: 1000</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={sendFeedbackMutation.isLoading}
            onClick={handleClick}
          >
            Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const MinimizedNavBar: FC = () => {
  const hist = useHistory()
  const auth = useAuth()
  const bgColor = useColorModeValue('whiteAlpha.900', 'brand.900')
  return (
    <Flex
      flexDirection={'row'}
      // @ts-ignore
      sx={{position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0',}}
      zIndex={'300'}
      boxShadow={'sm'}
      alignItems={'center'}
      justify-content={'space-between'}
      px={'20px'}
      py={'10px'}
      bg={bgColor}
    >
      <Box as={'button'} pr='20px' h={'40px'} onClick={() => {
        hist.push("")
      }}>
        <BrandLogo/>


      </Box>

      <Box flex={'4 4 0'}/>
      <HStack spacing={1}>
        {
          <>
            {
              auth.user !== null
                ?
                <>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="paper 1"
                      variant="outline"
                      onClick={() => {
                        hist.push('/paper/1')
                      }}
                    >
                      P1
                    </MenuButton>
                    <MenuButton
                      as={IconButton}
                      aria-label="paper 2"
                      variant="outline"
                      onClick={() => {
                        hist.push('/paper/2')
                      }}
                    >
                      P2
                    </MenuButton>
                    {/*<MenuButton*/}
                    {/*  as={IconButton}*/}
                    {/*  aria-label="discussion"*/}
                    {/*  variant="outline"*/}
                    {/*  onClick={() => {*/}
                    {/*    hist.push('/discuss')*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <Icon fontWeight={'extrabold'} as={HiOutlineChatAlt2}/>*/}
                    {/*</MenuButton>*/}


                  </Menu>
                  <UserButton/>

                </>

                :
                <LoginButton/>
            }
            <ChatBotButton/>
            <PropellaMenuButton/>
          </>
        }
      </HStack>


    </Flex>
  )
}