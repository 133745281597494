import React, {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useMutation} from 'react-query'
import {
  Button,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text, Link, Stack,
} from '@chakra-ui/react'
import {useAuth} from "../../Contexts/AuthContext";
import {AuthService} from "../../Services/AuthService";
import {getApiErrorMessage} from "../../Services/APIClient";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import useLocalStorage from "../../Hooks/use-local-storage";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const RESEND_WAIT_TIME = 60000

interface LoginFormProps {
  email: string
  code?: string
  onLogin: () => void
  reEnterEmail: ()=> void
}

export const LoginForm: FC<LoginFormProps> = ({email, code, onLogin, reEnterEmail}) => {

  const otpSchema = yup.object().shape({
    otp: yup.string().matches(/^\d{6}$/),
  }).required()

  const auth = useAuth()
  const [canResend, setCanResend] = useState(false)
  const [resendTimer, setResendTimer] = useState(RESEND_WAIT_TIME / 1000)
  const {register, handleSubmit, formState: {errors, isValid},clearErrors, } = useForm<{ otp: string }>({
    mode: 'onChange',
    // @ts-ignore
    resolver: yupResolver(otpSchema)
  })
  const sendOtp = useMutation(AuthService.getOTP, {
    onSuccess: (data, variables, context) => {
      setResendTimer(RESEND_WAIT_TIME / 1000)
      setCanResend(false)
    },
  })

  const [resendCount, setResendCount] = useState(0)
  const [referee] = useLocalStorage<string | null>('referee', null)
  useEffect(() => {
    let timeout: NodeJS.Timeout
    let interval: NodeJS.Timeout

    if (!canResend) {
      interval = setInterval(() => {
        setResendTimer((t) => Math.max(t - 1, 0))
      }, 1000)

      timeout = setTimeout(() => {
        setCanResend(true)
        clearInterval(interval)
      }, RESEND_WAIT_TIME)
    }

    return () => {
      timeout && clearTimeout(timeout)
      interval && clearInterval(interval)
    }
  }, [canResend])

  const onSubmit = (data: { otp: string }) => {
    auth.verifyOtp.reset()
    const {otp} = data
    auth.verifyOtp.mutate(
      {email: email, otp: otp, referee: referee !== null ? referee : undefined},
      {
        onSuccess: onLogin,
      }
    )
  }

  const hasError = () =>  auth.verifyOtp.isError

  const getErrorMessage = (): string => {
    const {otp} = errors
    return otp && otp.type === 'required'
      ? 'Please provide a valid OTP'
      : getApiErrorMessage(auth.verifyOtp.error)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="32px" align="stretch">{
        resendCount < 3 ?
          <>
            <FormControl id="email" isInvalid={hasError()}>
              <FormLabel color="#11263C">One-time password</FormLabel>
              <Text color="#6D7580" mb="24px">
                Please enter the OTP sent to <b>{email}</b>. You might have to check your spam mailbox if you do not see the
                email.

                If you do not receive your OTP after 1 minute, please check the email you entered and retry.
                Alternatively, you can sign in with Google Sign In.
              </Text>
              <Input
                h="48px"
                type="text"
                autoFocus={true}
                autoComplete="one-time-code"
                {...register("otp", {required: true})}
                placeholder="e.g. 111111"
              />
              {hasError() && (
                <FormErrorMessage>{getErrorMessage()}</FormErrorMessage>
              )}
            </FormControl>
            <Stack spacing={"10px"}>
              <Button
                size="lg"
                isLoading={auth.verifyOtp.isLoading}
                type="submit"
                disabled={!isValid}
                bgColor={'brand.700'}
                color={'white'}
              >
                Login
              </Button>
              <Button variant="outline" onClick={()=>{
                auth.verifyOtp.reset()
                clearErrors()
                reEnterEmail()
              }}>Re-Enter Email</Button>
              <Button
                variant="outline"
                disabled={!canResend}
                onClick={() => {
                  setResendCount(resendCount + 1)
                  sendOtp.mutate(email)
                  auth.verifyOtp.reset()
                  clearErrors()
                }}
              >
                {canResend ? 'Resend' : `Resend in ${resendTimer}s`}
              </Button>

            </Stack>
          </> :
          <>
            <Text>I see you have tried this multiple times.
              Our servers might be down. Can you tell us about it on telegram?</Text>
            <Link href="https://t.me/propellasg" isExternal>
              Propella Telegram Chat <ExternalLinkIcon mx="2px"/>
            </Link>
          </>
      }

      </VStack>
    </form>
  )
}
