import axios from "axios";

export const GET_API_BASE_URL = () => {
  if (process.env.NODE_ENV === "development") {
    return 'http://localhost:8080'
  } else {
    return 'https://www.propellasg.com'
  }

}
// export const API_BASE_URL = 'https://www.propellasg.com:8080/'
// export const API_BASE_URL = 'http://localhost:8080'

export const getApiErrorMessage = (error: unknown): string => {
  const defaultErrMsg = 'Something went wrong'
  if (!!error && axios.isAxiosError(error)) {

    return (
      error.response?.data.Error ??
      error.response?.statusText ??
      defaultErrMsg
    )
  }

  if (error instanceof Error) {
    return error.message ?? defaultErrMsg
  }

  return defaultErrMsg
}

export const ApiClient = axios.create({
  withCredentials: true,
  baseURL: GET_API_BASE_URL(),
  timeout: 100000, // 100 secs
})