import {Question2} from "../../Models/Question";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Kbd,
  Spacer,
  useBreakpointValue,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {useMutation, useQueryClient} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import {GoCommentDiscussion, IoStatsChart, TiStar, TiStarOutline} from "react-icons/all";
import {QuestionFeedbackButton} from "./QuestionFeedbackButton";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {MCQStats} from "./MCQStats";
import {QuestionDiscussion} from "../Discuss/QuestionDiscussion";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {ChatIcon} from "@chakra-ui/icons";

export function QLAccessoryBar(
  {
    data,
    shouldHidePageControls,
    disableAccessoryButtonHotKeys,
    hideFeedbackButton = false,
    hideDiscussionButton = false,
    hideFavButton = false,
    hideStatsButton = false,
  }: {
    data: Question2<any>,
    disableAccessoryButtonHotKeys: boolean,
    shouldHidePageControls: (shouldHide: boolean) => void,
    hideFeedbackButton?: boolean,
    hideDiscussionButton?: boolean,
    hideFavButton?: boolean,
    hideStatsButton?: boolean,
  }) {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const urlQuery = useURLQuery()
  const [isOpenDiscussionPanel, setIsOpenDiscussionPanel] = useState(urlQuery.get('msgID') !== null)
  const [isOpenStatsPanel, setIsOpenStatsPanel] = useState(false)

  // This is an internal control. It is used to disable the hotkeys when the user is typing in the response box.
  const [enableAccessoryButtonsHotkeys, setEnableAccessoryButtonsHotkeys] = useState(true)

  const accessoryPanelOpenUpHandler = ({key}: { key: string }): void => {
    switch (key) {
      case "d":
        if (!hideDiscussionButton) {

          setIsOpenDiscussionPanel(!isOpenDiscussionPanel)
        }
        break

      case "s":
        if (!hideStatsButton) {
          setIsOpenStatsPanel(!isOpenStatsPanel)

        }
    }
  }

  useEffect(() => {
    if (disableAccessoryButtonHotKeys) {
      return
    }
    if (enableAccessoryButtonsHotkeys) {
      window.addEventListener("keyup", accessoryPanelOpenUpHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keyup", accessoryPanelOpenUpHandler);
      };
    }
  }, [isOpenDiscussionPanel, isOpenStatsPanel, enableAccessoryButtonsHotkeys])

  useEffect(() => {
    shouldHidePageControls(isOpenDiscussionPanel || isOpenStatsPanel)
  }, [isOpenStatsPanel, isOpenDiscussionPanel])

  return <Box w={'full'}>
    {
      <HStack w={'full'}>
        <Spacer/>
        {data && data.QnType === "mcq" && !hideStatsButton &&// if this is an MCQ,

            <Button
                onClick={() => setIsOpenStatsPanel(!isOpenStatsPanel)}
                colorScheme={isOpenStatsPanel ? "brand" : undefined}
            >
                <IoStatsChart/>
              {
                !disableAccessoryButtonHotKeys && !isMobile && enableAccessoryButtonsHotkeys &&
                  <Kbd bg={"rgb(255,255,255,0.2)"} ml={"6px"}>S</Kbd>
              }
            </Button>
        }
        {
          !hideDiscussionButton &&
            <QLDiscussionButton
                disableAccessoryButtonHotKeys={disableAccessoryButtonHotKeys && enableAccessoryButtonsHotkeys}
                isOpen={isOpenDiscussionPanel}
                setIsOpen={setIsOpenDiscussionPanel}/>
        }
        {
          !hideFeedbackButton &&
            <QuestionFeedbackButton qID={data.ID}/>

        }
        {
          !hideFavButton &&
            <QLFavouriteButton data={data}/>
        }
      </HStack>

    }
    {
      <VStack
        py={'10px'}
        w={'full'}>
        {
          isOpenStatsPanel &&
            <MCQStats questionID={data.ID}/>
        }
        {
          isOpenDiscussionPanel &&
            <QuestionDiscussion
                resourceID={data.ID}
                responseBoxActive={val => setEnableAccessoryButtonsHotkeys(!val)}/>
        }
      </VStack>
    }
  </Box>;
}

const QLDiscussionButton = (
  {
    disableAccessoryButtonHotKeys,
    isOpen,
    setIsOpen
  }:
    {
      disableAccessoryButtonHotKeys: boolean,
      isOpen: boolean,
      setIsOpen: Dispatch<SetStateAction<boolean>>,
    }) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})

  return (
    <Button
      aria-label={'discussion'}
      onClick={() => setIsOpen(e => !e)}
      colorScheme={isOpen ? "brand" : undefined}
    >
      <GoCommentDiscussion fontSize={'1.3rem'}/>
      {
        !disableAccessoryButtonHotKeys && !isMobile &&
          <Kbd bg={"rgb(255,255,255,0.2)"} ml={"6px"}>D</Kbd>
      }
    </Button>

  // <IconButton
  //   bg={data?.Saved ? favColor : undefined}
  //   icon={<ChatIcon/>}
  //   onClick={() => {
  //     data?.Saved ?
  //       unFavQuestion.mutate(data.ID)
  //       : favQuestion.mutate(data.ID)
  //   }}
  //   aria-label={"discussion"}
  // />
  )
}

const QLFavouriteButton = ({data}: { data: Question2<any> }) => {

  const queryClient = useQueryClient()
  const favQuestion = useMutation(QuestionService.favouriteQuestion, {
    onSuccess: () => {
      queryClient.setQueryData<Question2<any>>(['qns', data.ID], (oldData) => {
        if (!oldData) {
          return {
            ID: '',
            QnType: '',
            Body: '',
            TenantID: '',
            Answer: '',
            Score: 0,
          };
        }
        return {...oldData, Saved: true};
      });
    }
  })

  const unFavQuestion = useMutation(QuestionService.unFavouriteQuestion, {

    onSuccess: () => {
      queryClient.setQueryData<Question2<any>>(['qns', data.ID], (oldData) => {
        if (!oldData) {
          return {
            ID: '',
            QnType: '',
            Body: '',
            TenantID: '',
            Answer: '',
            Score: 0,
          };
        }
        return {...oldData, Saved: false};
      });
    }
  })

  const favColor = useColorModeValue('pink.100', 'pink.800')

  return (
    <>
      {
        data.QnType !== "csq" &&
          <IconButton
              bg={data?.Saved ? favColor : undefined}
              icon={data?.Saved ? <TiStar/> : <TiStarOutline/>}
              onClick={() => {
                data?.Saved ?
                  unFavQuestion.mutate(data.ID)
                  : favQuestion.mutate(data.ID)
              }}
              aria-label={"favorite"}
          />
      }


    </>
  )
}