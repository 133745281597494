export interface QAttempt {
  UserID: string
  QnID: string
  CreatedAt: string
  UpdatedAt: string
  Answer: string
}

export interface QuestionBase<T> {
  ID: string
  QnType: string
  CreatedAt?: string
  UpdatedAt?: string
  Title?: string
  Body: string
  Difficulty?: number
  TenantID: string
  Answer: string
  Explanation?: string
  Score: number
  Params?: T
}

export interface Question2<T> extends QuestionBase<T>{
  Saved?: boolean
}

export interface MCQuestion2 extends Question2<string[]> {
}

export interface SAQuestion2 extends Question2<undefined> {
}

export interface CSQuestion2 extends Question2<CSSQuestion2[]> {
}

export interface CSSQuestion2 {
  B: string // body
  R: number // rightAns
  E: string // explanation
  A: string[] //answersArr
}

export function GetUpdatedAtDate(question: Question2<any>): string {
  return question.UpdatedAt?? ""
}

// export interface CSSubQuestion {
//   CaseStudyID: string
//   ID: string
//   CreatedAt: string
//   UpdatedAt: string
//   Body: string
//   RightAnsID: number
//   AnswersArr: string[]
//   Explanation: string
//   UserAnswer: number | undefined
//   Saved: boolean
// }

// export interface CSQuestion {
//   CreatedAt: string
//   UpdatedAt: string
//   ID: string
//   PaperID: string
//   Body: string
//   Saved: boolean
//   Questions: CSSubQuestion[]
// }


export interface PaperData {
  Topics: Topic[]
  ProblemSets: ProblemSet[]
}

export interface AnswerHistory {
  AttID: string
  QnID: string
  Ans: string
  Score?: number
}

export enum QuestionType {
  mcq = "mcq",
  saq = "saq",
  csq = "csq",
  css = "css",
}

export interface Topic {
  ID: string
  Name: string
  Paper: number
  ProblemSets?: ProblemSet[]

}
//
// export interface SAQuestion {
//   ID: string
//   CreatedAt: string
//   UpdatedAt: string
//   Body: string
//   Answer: string
//   UserAnswer: string | undefined
//   Explanation: string
//   Saved: boolean
// }
//
// export interface MCQuestion {
//   ID: string
//   CreatedAt: string
//   UpdatedAt: string
//   Body: string
//   RightAnsID: number
//   AnswersArr: string[]
//   Explanation: string
//
//   UserAnswer: number | undefined
//   Saved: boolean
// }


export interface UserAnswer {
  Answer: string
}

// Used to get all answers for the questions in a problem set
export interface SetAnswerResponse {
  ID: string
  Answer: string
}

export enum QnState {
  unanswered,
  answered,
  // correct,
  // wrong,
}

export interface ProblemSet {
  ID: string
  CreatedAt: string
  UpdatedAt: string
  TopicID: string
  Paper: number
  Title: string
  Type: string
  MaxScore: number
  ForFree: boolean
  IsGenerated: boolean
}

export interface ProblemSetWithAttempt {
  ProblemSet: ProblemSet
  Attempts: UserProblemSetAttempt[]
}

export interface UserProblemSetAttempt {
  ID: string
  UserID: string
  ProblemSetID: string
  ProblemSet?: ProblemSet
  CreatedAt: string
  MarkedAt?: Date
  Score: number | null
  MaxScore?: number
  QuestionsDone: number
  DelayMarking: boolean
}

export interface MCQStat {
  ID: string
  Ans1?: string
  Ans2?: string
  Ans3?: string
  Ans4?: string
  Ans1Pct?: string
  Ans2Pct?: string
  Ans3Pct?: string
  Ans4Pct?: string
  Total?: string
  TooLittleData?: boolean
}