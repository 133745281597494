import React, {FC, ReactNode, useState} from "react";
import {useQuery} from "react-query";
import {QuestionService} from "../../Services/QuestionService";
import _ from "lodash";
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  Tooltip,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useToast
} from "@chakra-ui/react";
import {PageControl} from "../PageControl";
import {QuestionSetReviewPageView} from "./QuestionSetReviewView";
import {useAuth} from "../../Contexts/AuthContext";

const PAGE_SIZE = 10

export const SavedQuestionsPage: FC = () => {
  const [pagedQuestions, setPagedQuestions] = useState([] as string[][])
  const [paper, setPaper] = useState<string | undefined>(undefined)
  const favQuestionsQuery = useQuery(['favs', paper], QuestionService.getAllFavQuestions(paper ?? ""), {
    onSuccess: (data => {
      setPagedQuestions(_.chunk(data, PAGE_SIZE))

    }),
  })
  const [page, setPage] = useState(1)
  return (

    <Container maxW={'container.lg'} pt={'20px'}>
      <Heading pt={'20px'}  size={'3xl'}>Saved questions</Heading>
      {/*<PaperSelector setPaper={setPaper}/>*/}
      <NewPaperSelector paper={paper} setPaper={setPaper} setPage={setPage}/>
      {
        pagedQuestions.length > 1 &&
          <PageControl currentPage={page} pageCount={pagedQuestions.length} setPageNumber={setPage}/>
      }
      {

        pagedQuestions.length > 0 &&
          <QuestionSetReviewPageView
              idx={page - 1}
              questionIDs={pagedQuestions[page - 1]}
              totalQnCount={favQuestionsQuery.data?.length ?? 0}
          />
      }
      {
        pagedQuestions.length === 0 &&
          <Text>You have not saved any questions.</Text>
      }
    </Container>
  )

}

const NewPaperSelector: FC<{
  paper: string | undefined,
  setPaper: React.Dispatch<React.SetStateAction<string | undefined>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}> = ({paper, setPaper, setPage}) => {
  const toast = useToast()
  const auth = useAuth()
  return (

    <HStack py={'10px'}>
      <Tooltip label={"Show all saved questions."}>
        <Button
          variant={paper === undefined ? 'solid' : 'outline'}
          colorScheme={'brand'}
          isActive={paper === undefined}
          size={'sm'}
          onClick={() => {
            setPaper(undefined)
            setPage(1)
          }
          }
        >Combined</Button>
      </Tooltip>
      <Tooltip label={"Filter for paper 1"}>
        <Button
          variant={paper === "1" ? 'solid' : 'outline'}
          colorScheme={'brand'}
          isActive={paper === "1"}
          size={'sm'}
          onClick={() => {
            setPaper("1")
            setPage(1)
          }
          }
        >p1</Button>
      </Tooltip>
      <Tooltip label={"Filter for paper 2"}>
        <Button
          variant={paper === "2" ? 'solid' : 'outline'}
          colorScheme={'brand'}
          isActive={paper === "2"}
          size={'sm'}
          onClick={() => {
            setPaper("2")
            setPage(1)
          }}
        >p2</Button>
      </Tooltip>
    </HStack>
  )
}

const RadioCard: FC<{ props: UseRadioProps, children: ReactNode }> =
  ({props, children}) => {
    const {getInputProps, getCheckboxProps} = useRadio(props)
    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          boxShadow='md'
          _checked={{
            bg: 'brand.500',
            color: 'white',
            borderColor: 'brand.500',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          px={5}
          py={'6px'}
        >
          {children}
        </Box>
      </Box>
    )
  }

const PaperSelector: FC<{
  setPaper: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({setPaper}) => {
  const options = [{value: 'combined', id: ''}, {value: 'p1', id: '1'}, {value: 'p2', id: '2'}]
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'paper',
    defaultValue: '',
    onChange: (nextValue: string) => {
      setPaper(nextValue)
    },
  })

  const group = getRootProps()

  return (

    <HStack {...group} my={'10px'}>
      {options.map((value) => {
        const radio = getRadioProps({value: (value.id)})
        return (
          <RadioCard props={radio} key={value.value}>
            {value.value}
          </RadioCard>
        )
      })}
    </HStack>
  )
}