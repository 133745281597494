import {FC, useEffect, useState} from "react"
import {useHistory} from "react-router-dom";
import {useURLQuery} from "../../Hooks/useURLQuery";
import {Container, Text, Button, Heading, VStack, useToast} from "@chakra-ui/react";
import {SubscriptionService} from "../../Services/SubscriptionService";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

export const UnsubPromoPage:FC<{}> = ({}) => {
  // @ts-ignore
  let urlQuery = useURLQuery()
  let hist = useHistory()
  let toast = useToast()
  let [_email, setEmail] = useState("")
  let [unsubbed,setUnsubbed] = useState(false)
  let unsubPromoNewsletterMutation = useMutation(SubscriptionService.unsubPromoNewsletter(_email), {
    onSuccess: data => {
      setUnsubbed(true)
    },
    onError: (error:AxiosError) => {
      toast({title:'unsub failed', status:'error', description: error.response?.data.Error,})
    }
  })

  useEffect(()=>{
    const ee = urlQuery.get("email")
    if( ee === null || ee === '') {
      hist.push('')
    } else {
      setEmail(urlQuery.get('email') ?? '')
      hist.push('/unsub-promo')
    }

  },[])

    return (
      <Container p={'10px'}>
        <VStack w={'full'} alignItems={'flex-start'}>
          <Heading>Unsubscribe Promotional Newsletter</Heading>

          {
            unsubbed ?
              <Text>{_email} unsubscribed from promotional emails.</Text>

              :
              <>
                <Text fontSize={'lg'}>{_email}</Text>
                <Button
                  isDisabled={unsubbed}
                  isLoading={unsubPromoNewsletterMutation.isLoading}
                  onClick={()=>{unsubPromoNewsletterMutation.mutate()}}>Confirm Unsubscribe</Button>
              </>


          }
        </VStack>

      </Container>
    )
}