import {ApiClient} from "./APIClient";
import {QuestionBase} from "../Models/Question";

const updateQuestion = async (props: { question: QuestionBase<any>,resetStat?:string}): Promise<QuestionBase<any>> => {
  return await ApiClient.put(`/api/admin/questions/${props.question.ID}?resetStat=${props.resetStat ?? ''}`, props.question).then(resp => {
      return resp.data.Response
    }
  )
}

const getQuestion = (qnID: string): () => Promise<QuestionBase<any>> => {
  return async () => await ApiClient.get("/api/admin/questions/" + qnID).then(resp => resp.data.Response)
}

const deleteFeedback = async (feedbackID: string): Promise<undefined> => {
  return await ApiClient.delete(`/api/admin/feedbacks/${feedbackID}`).then(resp => resp.data.Response)
}

export const AdminService = {
  updateQuestion,
  getQuestion,
  deleteFeedback
}