import {AnswerHistory, QAttempt, Question2, QuestionBase, UserAnswer} from "../Models/Question";
import {ApiClient} from "./APIClient";
import {QueryFunctionContext} from "react-query";

const createQuestion = async (props: { question: QuestionBase<any> }): Promise<QuestionBase<any>> => {
  return await ApiClient.post(`/api/userquestions`, props.question).then(resp => resp.data.Response)
}

type UserQuestionReviewRequestProps = {
  QnID: string
  ApproveOrReject: string
  Reason: string
}

const reviewUserQuestion = async (props: { reviewRequestProps: UserQuestionReviewRequestProps }): Promise<void> => {
  return await ApiClient.post(`/api/userquestions/review`, props.reviewRequestProps)
}

const getUserQuestions = async ({queryKey: [_]}: QueryFunctionContext<string[]>): Promise<string[]> => {
  return await ApiClient.get(`/api/userquestions`).then(resp => resp.data.Response)
}

const getUserQuestionsAsOwner = async ({queryKey: [_]}: QueryFunctionContext<string[]>): Promise<string[]> => {
  return await ApiClient.get(`/api/userquestions/owner`).then(resp => resp.data.Response)
}

const getUserQuestionsUnderReview = async ({queryKey: [_]}: QueryFunctionContext<string[]>): Promise<string[]> => {
  return await ApiClient.get(`/api/userquestions/review`).then(resp => resp.data.Response)
}

const getQAttempts = async ({queryKey: [_]}: QueryFunctionContext<string[]>): Promise<QAttempt[]> => {
  return await ApiClient.get(`/api/userquestions/attempts`).then(resp => resp.data.Response)
}

const upsertQAttempt = async (props: { questionID: string, answer: string }): Promise<QAttempt> => {
  return await ApiClient
    .put(`/api/userquestions/${props.questionID}/attempt`, {answer: props.answer})
    .then(resp => {
        return resp.data.Response
      }
    )
}

/*
UQFeedbackType
ENUM(
UQNull = 0
UQGrammarSpellingError
UQExplanation
UQOutdated
UQWrongAns
UQOther
UQDeadLink
)
 */
export type UQFeedbackType = "UQNull" | "UQGrammarSpellingError" | "UQExplanation" | "UQOutdated" | "UQWrongAns" | "UQOther" | "UQDeadLink"

const upsertUQFeedback = async (props: { questionID: string, vote: number,type: UQFeedbackType, feedback: string }): Promise<void> => {
  return await ApiClient
    .put(`/api/userquestions/feedback/${props.questionID}`, {vote: props.vote, type: props.type, feedback: props.feedback})
    .then(resp => {
        return resp.data.Response
      }
    )
}


export const UserQuestionService = {
  createQuestion,
  getUserQuestions,
  getUserQuestionsAsOwner,
  getUserQuestionsUnderReview,
  reviewUserQuestion,
  upsertQAttempt,
  getQAttempts,
  upsertUQFeedback
}