import {FC} from "react";
import {ProblemSet, UserProblemSetAttempt} from "../../Models/Question";
import {useHistory} from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue, Wrap
} from "@chakra-ui/react";
import {ProblemSetAttemptStatusIndicator} from "./ProblemSetAttemptStatusIndicator";
import {useAuth} from "../../Contexts/AuthContext";
import {MdOutlineLock} from "react-icons/all";

export const ProblemSetTable: FC<{ pSets: ProblemSet[], attempts: UserProblemSetAttempt[] }> = ({pSets, attempts}) => {
  const hist = useHistory()
  const auth = useAuth()
  const rowColor = useColorModeValue("blue.100", "blue.800")
  return (
    <Table variant={'simple'}>

      <Thead>
        <Tr>
          <Th>Problem Set</Th>
          <Th>Last Attempt Score</Th>
          <Th isNumeric={true}>Status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          pSets
            .map((pSet, i) => {
              const completedAttempts = attempts.filter(a => a.ProblemSetID === pSet.ID)?.filter(att => att.Score !== null)
              let k = completedAttempts?.[completedAttempts.length - 1]?.Score ?? 0
              let pSetAttempts = attempts.filter(a => a.ProblemSetID === pSet.ID)
              return (
                <Tr
                  key={pSet.ID + k?.toString()}
                  _hover={{
                    cursor: 'pointer',
                    bg: rowColor,
                  }}
                  onClick={() => {
                    hist.push(`/set/${pSet.ID}`)
                  }}>
                  <Td>
                    <Wrap>
                      <Text>{pSet.Title}</Text>
                      {
                        pSet.ForFree && <Tag size={'sm'} colorScheme={'brand2'}>FREE</Tag>
                      }
                    </Wrap>
                  </Td>
                  <Td>
                    <HStack>
                      {
                        pSetAttempts.length > 0 ?
                          completedAttempts.length > 0 ?
                            <>

                              <Text>{completedAttempts[completedAttempts.length - 1].Score + "/" +
                              pSet.MaxScore}</Text>

                            </>
                            :
                            <>

                              <Text>In Progress</Text>
                            </>
                          :
                          <>

                            <Text color={'gray.500'} fontSize={'xs'}>-</Text>
                          </>
                      }
                    </HStack>
                  </Td>
                  <Td>
                    <Flex flexDirection={'row-reverse'}>{

                      (!pSet.ForFree && !auth.testAuth(pSet.Paper)) ?
                        <Box mr={'5px'}><MdOutlineLock/> </Box>:
                        <ProblemSetAttemptStatusIndicator state={
                          pSetAttempts.length > 0 ?
                            completedAttempts.length > 0 ?
                              'done' :
                              'ongoing' :
                            'not started'
                        } pSetTitle={pSet.Title}/>


                    }


                    </Flex>
                  </Td>
                </Tr>


              )
            })

        }
      </Tbody>
    </Table>
  )
}

