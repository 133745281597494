import {ChangeEvent, ChangeEventHandler, FC, useEffect, useState} from "react";
import {MCQuestion2} from "../../Models/Question";
import {
  Textarea,
  VStack,
  Text,
  HStack,
  Input,
  Button,
  Spinner,
  useToast,
  Checkbox,
  Spacer,
  Heading
} from "@chakra-ui/react";
import {useMutation, useQueryClient} from "react-query";
import {AdminService} from "../../Services/AdminService";
import {AxiosError} from "axios";
import {QnMgrService} from "../../Services/QnMgrService";

const miniHeader = (title: string) => {
  return (
    <Heading mt={'10px'} ml={'5px'} size={'sm'}>{title}</Heading>
  )
}
export const MCQEditView: FC<{
  question: MCQuestion2
  didSave: () => void
  onDelete?: () => void

}> = ({question, didSave, onDelete}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const updateQnMutation = useMutation(AdminService.updateQuestion, {
    onSuccess: data => {
      didSave()
      queryClient.invalidateQueries(['mcq-stats', question.ID])
    },
    onError: (error: AxiosError) => {
      toast({
        position: "top",
        title: "saving failed",
        description: error.response?.data.Error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  })

  const deleteQnMutation = useMutation(QnMgrService.DeleteQuestion, {
    onSuccess: data => {
      console.log(data)
      toast({
        position: "top",
        title: "question deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    }
  })


  const [qn, setQn] = useState(question)
  useEffect(() => {
    setQn(question)
  }, [question])
  const [resetStats, setResetStats] = useState(false)
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name === 'Answer') {

      setQn(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))

    } else if (event.target.name === "ansArr") {
      setQn(prevState => ({
        ...prevState,
        Params: prevState.Params?.map((ans, i) => {
          return i.toString() === event.target.id
            ? event.target.value
            : ans
        })
      }))
    } else if (event.target.name === 'Body' ||
      event.target.name === 'Explanation') {

      setQn(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))

    }
  }

  return (
    <VStack alignItems={'flex-start'}>{
      deleteQnMutation.isSuccess ?
        <Text>Question deleted.</Text> :
        (updateQnMutation.isLoading || deleteQnMutation.isLoading) ?
          <Spinner/> :
          <>
            {miniHeader('ID')}
            <Text>{qn.ID}</Text>

            {miniHeader('Created at')}
            <Text>{qn.CreatedAt}</Text>

            {miniHeader('Updated at')}
            <Text>{qn.UpdatedAt}</Text>
            <McqEditBody qn={qn} handleChange={handleChange}/>
            <Checkbox fontWeight={'bold'} onChange={val => {
              setResetStats(val.target.checked)
            }
            }>Reset MCQ Answer Statistics</Checkbox>
            <HStack w={'100%'} pt={'40px'}>

              <Button
                isDisabled={JSON.stringify(question) === JSON.stringify(qn) && !resetStats}
                onClick={() => {
                  updateQnMutation.mutate({question: qn, resetStat: resetStats ? "true" : ""})
                  setResetStats(false)
                }}>Save</Button>
              <Spacer/>
              {
                !!onDelete &&
                  <Button onClick={() => {
                    deleteQnMutation.mutate({tenantID: "propella", questionID: qn.ID})
                  }
                  }>Delete</Button>

              }
            </HStack>

          </>
    }

    </VStack>
  )
}

export const McqEditBody = (props: { qn: MCQuestion2, handleChange: ChangeEventHandler, deleteCSSQ?: () => void }) => {
  return (
    <>
      {
        props.deleteCSSQ === undefined ?
          miniHeader('MCQ body')
          :
          miniHeader('CSSQ body')
      }

      <Textarea mb={'20px'} name={'Body'} onChange={props.handleChange} value={props.qn.Body}/>
      {
        props.qn.Params?.map((ans, i) =>
          <HStack w={'full'} key={i}>
            <Text fontWeight={'bold'}>{i + 1}</Text>
            <Textarea
              name={"ansArr"}
              id={i.toString()}
              onChange={props.handleChange}
              backgroundColor={String(i + 1) === props.qn.Answer ? 'green.500' : undefined}
              value={ans}/>
          </HStack>
        )
      }
      <HStack mb={'20px'}>
        <Text fontWeight={'bold'}>Answer</Text>
        <Input type={'number'} name={"Answer"} onChange={props.handleChange} value={props.qn.Answer}/>
      </HStack>
      <Text fontWeight={'bold'}>Explanation</Text>
      <Textarea name={'Explanation'} onChange={props.handleChange} value={props.qn.Explanation}/>

    </>
  )
}