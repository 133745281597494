import {FC, useState} from "react";
import {Flex} from "@chakra-ui/react";
import {QuestionViewer} from "./QuestionViewer";
import {PaperSelector} from "./PaperSelector";
import {ProblemSetSelector} from "./ProblemSetSelector";

export const QuestionManager: FC<{}> = ({}) => {
  let [paper, setPaper] = useState('')
  return (
    // <BatchQuestionUploader/>
    <Flex alignItems={'flex-start'} overflowY={'scroll'} h={'80vh'}>
      <PaperSelector selectedPaper={paper} setSelectedPaper={paperID => setPaper(paperID)}/>
      {
        !!paper && paper !== '$unassigned$' &&
        <ProblemSetSelector selectedPaper={paper}/>

      }
      <QuestionViewer/>
    </Flex>

  )
}
