import React, {FC} from "react";

import {Box, chakra, Container, Text, useColorModeValue, VStack} from "@chakra-ui/react";

export const Commendation2: FC = () => {
  const bgGrad = useColorModeValue("linear(180deg,rgba(0,0,0, 0),rgba(38,77,200, 0.1))"
    , "linear(180deg,rgba(0,0,0, 0),rgba(38,77,200, 0.1))")
  return (
    <Box
      py={'80px'}
      bgGradient={bgGrad}
    >

      <Container maxW={'container.xl'} px={10}>
        <VStack alignItems={'flex-start'}>
          <chakra.span
            fontSize={['lg', '3xl']}
          >"Propella is really good for those who are looking for more practice questions to do. Hands down
            <chakra.span color={'brand2.400'} fontWeight={700}>&nbsp;one of the best and most convenient platforms to
              practise on</chakra.span>
            , which I feel helped me a lot in
            <chakra.span color={'brand2.400'} fontWeight={700}>&nbsp;passing on the first try</chakra.span>.
            Best feature they have is the mock exams with timer. You can use that to gauge your performance before your
            exams."
          </chakra.span>

          {/*<Button*/}
          {/*  size={'sm'}*/}
          {/*  colorScheme={'brand'}*/}
          {/*  variant={'outline'}*/}
          {/*  mt={'20px'}*/}
          {/*  cursor={'pointer'} onClick={() => {*/}
          {/*  openInNewTab('https://imgur.com/a/qAMoqPg')*/}
          {/*}}*/}
          {/*>Feedback Screenshot</Button>*/}

          <Text
            mt={'20px'}
            fontWeight={'600'}
          >Terence</Text>

          <Text
            color={'gray.500'}
            fontSize={'sm'}
          >
            Real Estate Agent that passed the April 2022 CES exam on the 1st try with Propella.</Text>
        </VStack>
      </Container>
    </Box>

  )
}

