import {ChangeEvent, FC, useState} from "react";
import {SAQuestion2} from "../../Models/Question";
import {useMutation} from "react-query";
import {AdminService} from "../../Services/AdminService";
import {Button, HStack, Spacer, Spinner, Text, Textarea, useToast, VStack} from "@chakra-ui/react";
import {QnMgrService} from "../../Services/QnMgrService";

export const SAQEditView: FC<{
  question: SAQuestion2
  didSave: () => void
  onDelete?: () => void
}> = ({question, didSave, onDelete}) => {
  const toast = useToast()

  const updateQnMutation = useMutation(AdminService.updateQuestion, {
    onSuccess: data => {
      didSave()
    }
  })

  const deleteQnMutation = useMutation(QnMgrService.DeleteQuestion, {
    onSuccess: data => {
      console.log(data)
      if (onDelete) {
        onDelete()
      }

      toast({
        position: "top",
        title: "question deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    }
  })

  const [qn, setQn] = useState(question)
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.name === 'Answer') {

      setQn(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))

    } else if (event.target.name === 'Body' ||
      event.target.name === 'Explanation') {

      setQn(prevState => ({
        ...prevState,
        [event.target.name]: event.target.value
      }))

    }
  }


  return (
    <VStack alignItems={'flex-start'}>{
      deleteQnMutation.isSuccess ?
        <Text>Question deleted.</Text> :
      (updateQnMutation.isLoading || deleteQnMutation.isLoading) ?
        <Spinner/> :
        <>
          <Text fontWeight={'bold'}>SAQ Body</Text>
          <Text>{'Use {{b}} to indicate a blank'}</Text>
          <Textarea mb={'20px'} name={'Body'} onChange={handleChange} value={qn.Body}/>
          <Text fontWeight={'bold'}>Answer</Text>
          <Text>Use ~ to separate answers for different blanks</Text>
          <Text>Use | to separate different possible answers for the same blank</Text>
          <Textarea mb={'20px'} name={'Answer'} onChange={handleChange} value={qn.Answer}/>
          <Text fontWeight={'bold'}>Explanation</Text>
          <Textarea name={'Explanation'} onChange={handleChange} value={qn.Explanation}/>
          <HStack w={'100%'} pt={'40px'}>
            <Button onClick={() => {
              updateQnMutation.mutate({question: qn})
            }}>Save</Button>
            <Spacer/>
            {
              !!onDelete &&
                <Button onClick={() => {
                  deleteQnMutation.mutate({tenantID: "propella", questionID: qn.ID})
                }
                }>Delete</Button>

            }

          </HStack>

        </>
    }

    </VStack>
  )
}