import React, {FC} from "react";
import {
  Box,
  Container, Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr, useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import {IoCheckmarkSharp, IoCloseSharp} from "react-icons/all";

export const Compare: FC<{}> = ({}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const validChoiceColor = useColorModeValue('green.100', 'green.900')
  const badChoiceColor = useColorModeValue('red.100', 'red.900')
  const maybeChoiceColor = useColorModeValue('orange.100', 'orange.900')
  return (
    <Box
      pt={'80px'}
      pb={'80px'}
      bg={useColorModeValue('gray.100', 'gray.800')}
      // bgGradient={bgGrad}

    >

      <Container maxW={'container.lg'} px={isMobile ? '6px': '10px'}>
        <Heading
          textAlign={'center'}
          fontSize={'4xl'}
          mb={'20px'}
        >
          A better way to prep for your RES exam.
        </Heading>
        <TableContainer
          bg={useColorModeValue('white','black')}
          overflow={'scroll'}
          borderWidth={isMobile ? '0px':'1px'}
          borderRadius={'12px'} p={'8px'}
        >
          <Table>
            {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
            <Thead>
              <Tr>
                <Th>Features</Th>
                <Th>Competitor X</Th>
                <Th>Competitor Y</Th>
                <Th bgGradient="linear(to-l, #264DC8, #FF0080)"
                    bgClip="text"
                    fontStyle={'normal'}
                    fontWeight={'extrabold'}>Propella</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Th>Delivery</Th>
                <Td>Printed Book</Td>
                <Td>PDF file</Td>
                <Td>Online</Td>
              </Tr>
              <Tr>
                <Th>{'>1000 questions'}</Th>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>Explanations</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}>55% of questions</Td>
              </Tr>
              <Tr>
                <Th>Marking Answers</Th>
                <Td bg={badChoiceColor}>Manual</Td>
                <Td bg={badChoiceColor}>Manual</Td>
                <Td bg={validChoiceColor}>Automated</Td>
              </Tr>
              <Tr>
                <Th>Reviewing Answers</Th>
                <Td bg={badChoiceColor}>Troublesome</Td>
                <Td bg={badChoiceColor}>Troublesome</Td>
                <Td bg={validChoiceColor}>Easy</Td>
              </Tr>
              <Tr>
                <Th>Reattempts</Th>
                <Td bg={maybeChoiceColor}>Depends</Td>
                <Td bg={maybeChoiceColor}>Depends</Td>
                <Td bg={validChoiceColor}>Easy</Td>
              </Tr>
              <Tr>
                <Th>Resaleability</Th>
                <Td bg={maybeChoiceColor}>Depends</Td>
                <Td bg={maybeChoiceColor}>Depends</Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>New Govt Policies</Th>
                <Td bg={badChoiceColor}>book becomes<br/> outdated</Td>
                <Td bg={badChoiceColor}>pdf becomes<br/> outdated</Td>
                <Td bg={validChoiceColor}>questions<br/> are updated</Td>
              </Tr>
              <Tr>
                <Th>Dynamic Tests</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>Mobile friendly</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>Question Statistics</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>Saving Questions</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>
              <Tr>
                <Th>Discussion</Th>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={badChoiceColor}><IoCloseSharp/></Td>
                <Td bg={validChoiceColor}><IoCheckmarkSharp/></Td>
              </Tr>


            </Tbody>
            <Tfoot>
              {/*<Tr>*/}
              {/*  <Th>To convert</Th>*/}
              {/*  <Th>into</Th>*/}
              {/*  <Th isNumeric>multiply by</Th>*/}
              {/*</Tr>*/}
            </Tfoot>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  )
}