import {useCallback, useEffect, useState} from "react";
import copy from "copy-to-clipboard";
import {UseClipboardOptions} from "@chakra-ui/hooks/src/use-clipboard";

export function useClipboard(
  optionsOrTimeout: number | UseClipboardOptions = {},
) {
  const [hasCopied, setHasCopied] = useState(false)

  const {timeout = 1500, ...copyOptions} =
    typeof optionsOrTimeout === "number"
      ? {timeout: optionsOrTimeout}
      : optionsOrTimeout

  const onCopy = useCallback((text: string) => {
    const didCopy = copy(text, copyOptions)
    setHasCopied(didCopy)
  }, [copyOptions])

  useEffect(() => {
    let timeoutId: number | null = null

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [timeout, hasCopied])

  return {onCopy, hasCopied}
}
