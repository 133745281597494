import React, {FC, useState} from "react";
import { MCQuestion2} from "../../Models/Question";
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Tag,
  Text,
  useBreakpointValue,
  useColorModeValue, VStack,
  chakra,
} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {IoCheckmarkSharp} from "react-icons/all";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";

export const QuestionPreviewTag = (props: { color: string, type: string }) => {
  return <HStack flexDirection={"row-reverse"} alignItems={"flex-end"} flexGrow={1}>
    <Tag colorScheme={props.color} ml={"10px"}>{props.type}</Tag>
  </HStack>;
}

export const MCQPreview: FC<{
  question: MCQuestion2,
}> = ({question}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      {
        <Flex cursor={"pointer"} flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'}
              onClick={() => {

                setIsExpanded(!isExpanded)
              }}>
          {
            isExpanded ? <>
                <Box flexGrow={3}>
                  <Text fontSize={'sm'}>{question.Body}</Text>
                  <VStack alignItems={'flex-start'} pt={'5px'} pb={'5px'}>
                    {
                      question.Params?.map((ans, i) =>
                        <MCQuestionOptionPreview idx={i} optionText={ans} isRightAnswer={String(i + 1) === question.Answer}/>
                      )
                    }
                  </VStack>
                  <chakra.span
                    fontWeight={'semibold'}
                    fontSize={'sm'}
                  >
                    Explanation:&nbsp;
                    <chakra.span
                      fontWeight={'normal'}
                      fontSize={'sm'}
                    >
                      {

                        question.Explanation?.split('\n').map(t =>
                          <ReactMarkdown
                            linkTarget={'_blank'}
                            className={'markdown-body'} key={t}>{t}</ReactMarkdown>)
                      }
                    </chakra.span>
                  </chakra.span>


                </Box>


              </> :
              <>
                <Text noOfLines={2} fontSize={'sm'} flexGrow={3}>{question.Body.split('\n')[0]}</Text>


              </>
          }
          <QuestionPreviewTag color={'red'} type={'MCQ'}/>

        </Flex>

      }
    </>
  )
}


export const MCQuestionOptionPreview: FC<{
  idx: number,
  optionText: string,
  isRightAnswer?: boolean,
}> = ({idx, optionText, isRightAnswer}) => {
  let light = 'gray.100'
  let dark = 'gray.700'
  if (isRightAnswer === true) {
    light = 'green.300'
    dark = 'green.700'
  }

  const hoverColor = useColorModeValue("blue.100", "blue.900")
  const clickColor = useColorModeValue("blue.300", "blue.600")
  return (
    <Box
      _active={isRightAnswer === undefined ? {
        bg: clickColor,
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      } : {}}
      rounded={"md"}
      bg={useColorModeValue(light, dark)}
      w={"auto"}
      px={'10px'}
      py={'2px'}
    >
      <HStack alignItems={'center'}>

        <Text fontSize={'xs'}>
          {optionText}
        </Text>
        <Spacer/>
        {
          isRightAnswer === true &&
          <Icon as={IoCheckmarkSharp} w={'14px'} h={'14px'}/>
        }
      </HStack>

    </Box>
  )
}