import React, {ChangeEvent, FC, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {ChatService} from "../../Services/ChatService";
import {
  Box,
  Button,
  Flex,
  HStack,
  Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text,
  Textarea,
  Tooltip, useBreakpointValue,
  useClipboard,
  useColorModeValue, useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import {RMessage} from "../../Models/Chat";
import dayjs from "dayjs";
import {AxiosError} from "axios";
import {QuestionService} from "../../Services/QuestionService";
import {Icon} from "@chakra-ui/icons";
import {
  BiMessageSquareDetail,
  BsCheckAll,
  HiOutlineExternalLink,
  HiReply,
  IoShareOutline,
  MdOutlineWatchLater, RiDeleteBin5Line
} from "react-icons/all";
import {GetUpdatedAtDate, Question2} from "../../Models/Question";

import {QuestionPreview} from "./QuestionPreview";
import {GetNameDatePlate, MessageResponsesBox} from "./MessageResponses";
import {useAuth} from "../../Contexts/AuthContext";
import {VoteControl} from "./VoteControl";

const SendMessageBox: FC<{
  qnID: string,
  replyTo: string,
  onSendSuccess: () => void
  responseBoxActive: (isActive: boolean) => void
}> = ({qnID, replyTo, onSendSuccess, responseBoxActive}) => {
  const [value, setValue] = useState('')
  const toast = useToast()
  const auth = useAuth()
  const postCommentMutation = useMutation(ChatService.createNewMessage(qnID), {
    onSuccess: data => {
      setValue('')
      onSendSuccess()
    },
    onError: (error: AxiosError) => {
      toast({
        position: "top",
        title: "Failed to send message.",
        description: error.response?.data.Error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  })

  let handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = e.target.value
    setValue(inputValue)
  }
  return (
    <VStack alignItems={'flex-end'}>
      {
        (auth.testAuth(1) || auth.testAuth(2)) ?
          <>
            <Textarea
              onFocus={() => responseBoxActive(true)}
              onBlur={() => responseBoxActive(false)}
              variant={'outline'} value={value} onChange={handleInputChange}/>

            <Button size={'sm'}
                    isDisabled={value.length < 5}
                    onClick={() => {
                      postCommentMutation.mutate({ReplyToMsgID: replyTo, Body: value})
                    }}>Reply</Button>
          </>
          : <Text color={'gray.500'} p={'1px'} fontSize={'sm'}> You need to be subscribed to participate in
            discussions.</Text>
      }
    </VStack>
  )
}

export const DeleteMessageButton: FC<{
  onClick: () => void,
  isLoading: boolean | undefined,
}> = ({onClick, isLoading}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  return (
    <>

      <Tooltip label={'admin delete'}>

        <Button
          onClick={onOpen}
          variant={'outline'}
          px={'10px'}
          size={'xs'}
          mx={'2px'}
          isLoading={isLoading}
        >
          <Icon as={RiDeleteBin5Line}/>
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Deletion Warning!</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            Are you sure you want this deleted?
          </ModalBody>

          <ModalFooter>
            <Button variant={"outline"} mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='solid' colorScheme={'red'} onClick={onClick}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const DiscussionPageListItem: FC<{
  message: RMessage
  resourceID: string
  onReply: () => void
  hideQnPreview: boolean
  hideBottomBorder?: boolean
  hideOpenLink?: boolean
  highlight?: string
  responseBoxActive: (isActive: boolean) => void

}> = ({
        message,
        onReply,
        resourceID,
        hideQnPreview,
        hideBottomBorder = false,
        hideOpenLink = false,
        highlight,
        responseBoxActive
      }) => {
  const borderColor = useColorModeValue('1px solid gray', '1px solid rgb(60,60,100)')
  const dimFontColor = useColorModeValue("gray.400", "gray.600")
  const [replyActive, setReplyActive] = useState(false)
  const [responsesActive, setResponsesActive] = useState(false)
  const [forceRefetchReplies, setForceRefetchReplies] = useState(false)
  const createdAtDate = dayjs(message.CreatedAt)
  const {hasCopied, onCopy} = useClipboard(`${window.location.origin}/question/${resourceID}?msgID=${message.ID}`)
  const usrInfoQuery = useQuery(['user', message.UserID], ChatService.getUserPublicInfo, {
    staleTime: 1e3 * 60 * 60 * 24,
    cacheTime: 1e3 * 60 * 60 * 24,
  })
  const auth = useAuth()
  const toast = useToast()
  const [isExpanded, setIsExpanded] = useState(false)
  const isExpandable = message.Body.length > 200
  const collapsedText = isExpandable ? message.Body.substr(0, 200) + '...' : ''
  const expandedText = message.Body
  const qnQuery = useQuery<any, AxiosError, Question2<any>, any>(
    ['qns', resourceID],
    QuestionService.getQuestion(resourceID),
    {
      staleTime: 1000 * 60 * 1440, // 1 day
      cacheTime: 1000 * 60 * 1440,
      enabled: !!resourceID,
      onError: (err) => {
        console.log(err.response?.status)
      }

    })

  const archiveQnMutation = useMutation(ChatService.archiveComment(message.ID), {
    onSuccess: data => {
      onReply()
      toast({
        position: "top",
        title: "user comment archived",
        description: "This message will no longer show up in the Discuss page",
        status: "success",
        duration: 5000,
        isClosable: true,
      })
    }
  })

  const deleteQnMutation = useMutation(ChatService.deleteMessage(message.ID), {
    onSuccess: data => {
      onReply()
      toast({
        position: "top",
        title: "user comment delete",
        description: "This message has been deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    }
  })
  const hideVoteControlOnLeft = useBreakpointValue({base: true, md: false})
  const boxShadow = auth.user?.ID === message.UserID ? 'inset rgba(79, 114, 205, 0.5) 0 0 40px 0px' : highlight
  return (

    <Box
      flexGrow={2}
      py={{base: '10px'}}
      px={{base: '10px'}}
      boxShadow={boxShadow}
      borderBottom={hideBottomBorder ? 'none' : borderColor}
    >
      <HStack w={'100%'} alignItems={'flex-start'}>
        {!hideVoteControlOnLeft && !hideQnPreview &&

            <VoteControl
                onSuccess={onReply}
                messageID={message.ID}
                voteCount={message.VoteCount}
                userVote={message.UserVote ?? 0}
                isHorizontal={false}
            />
        }
        <VStack flexGrow={3} alignItems={'stretch'} spacing={'3px'}>
          {
            !hideQnPreview && message.CHThread?.ResourceID &&
              <QuestionPreview qnQuery={qnQuery}/>
          }

          <Text wordBreak="break-word" overflowWrap="break-word">
            {isExpandable && !isExpanded ? collapsedText.split('\n')[0] : expandedText}
          </Text>

          <Flex mt={'10px'}
                alignItems={{base: 'flex-end', md: 'flex-start'}}
                flexDirection={{base: 'column', md: 'row'}}
          >
            <HStack spacing={'4px'} w={'100%'} alignItems={'flex-start'}>
              {
                (hideVoteControlOnLeft || hideQnPreview) &&
                  <VoteControl
                      onSuccess={onReply}
                      messageID={message.ID}
                      voteCount={message.VoteCount}
                      userVote={message.UserVote ?? 0}
                  />
              }
              <Spacer/>
              {
                isExpandable &&
                  <Button size={'xs'} variant={'ghost'} onClick={() => {
                    setIsExpanded(!isExpanded)
                  }}>{
                    isExpanded ? 'Collapse' : 'Expand'
                  }</Button>
              }

              {// highlight that the question has been updated after this message was created
                qnQuery.data && dayjs(GetUpdatedAtDate(qnQuery.data)).isAfter(message.CreatedAt) &&
                  <Tooltip
                      fontSize={'xs'}
                      label={'This message was posted before the question was last updated.'}>
                <span><Icon as={MdOutlineWatchLater} onClick={() => {
                  auth.isAdmin() && archiveQnMutation.mutate()
                }
                }/></span>

                  </Tooltip>
              }

              {
                auth.isAdmin() &&
                  <>
                      <DeleteMessageButton
                          onClick={deleteQnMutation.mutate}
                          isLoading={deleteQnMutation.isLoading}
                      />
                  </>

              }


              <Tooltip label={'reply'}>

                <Button
                  onClick={() => {
                    setReplyActive(!replyActive)
                  }}
                  variant={'outline'}
                  px={'10px'}
                  size={'xs'}
                  mx={'2px'}
                  isActive={replyActive}
                >
                  <Icon as={HiReply}/>
                </Button>
              </Tooltip>
              <Tooltip label={'read replies'}>

                <Button
                  isDisabled={message.MsgCount < 1}
                  onClick={() => {
                    setResponsesActive(!responsesActive)
                  }}
                  isActive={responsesActive}
                  variant={'outline'}
                  px={'10px'}
                  size={'xs'}
                  mx={'2px'}
                >
                  <Text>{message.MsgCount}&nbsp;</Text>
                  <Icon as={BiMessageSquareDetail}/>
                </Button>
              </Tooltip>

              {
                hideOpenLink === false &&
                  <Tooltip label={'open question in new tab'}>
                      <Link href={'/question/' + message.CHThread?.ResourceID} isExternal>
                          <Button
                              aria-label={'open question in new tab'}

                              variant={'outline'}
                              px={'10px'}
                              size={'xs'}
                              mx={'2px'}
                          >
                              <Icon as={HiOutlineExternalLink}/>
                          </Button>
                      </Link>

                  </Tooltip>
              }

              <Tooltip label={'copy share link'}>

                <Button
                  onClick={onCopy}
                  variant={'outline'}
                  px={'10px'}
                  bg={hasCopied ? 'brand2.500' : undefined}
                  color={hasCopied ? 'white' : undefined}
                  size={'xs'}
                  mx={'2px'}
                >
                  {
                    hasCopied ? <Icon as={BsCheckAll}/> : <Icon as={IoShareOutline}/>
                  }

                </Button>
              </Tooltip>
            </HStack>
            <Box w={'10px'}/>
            {
              usrInfoQuery.data !== undefined &&
                <GetNameDatePlate
                    usrInfo={usrInfoQuery.data}
                    isLoggedInUser={auth.user?.ID === message.UserID}
                    createdAtDate={createdAtDate}
                    foldable={false}
                />
            }

          </Flex>
          {/* reply form */}
          {
            replyActive && !!resourceID && <Box mt={'15px'}>
                  <SendMessageBox qnID={resourceID} replyTo={message.ID} onSendSuccess={() => {
                    setForceRefetchReplies(true)
                    onReply()
                    setReplyActive(false)
                  }
                  }
                                  responseBoxActive={responseBoxActive}
                  />
              </Box>
          }

          {
            responsesActive &&

              <MessageResponsesBox
                  messageID={message.ID}
                  forceRefetchReplies={forceRefetchReplies}
                  setForceRefetchReplies={setForceRefetchReplies}
              />
          }


        </VStack>
      </HStack>

    </Box>
  )
}

