import React, {FC, useState} from "react";
import {CSQuestion2} from "../../Models/Question";
import {
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {QuestionPreviewTag} from "./MCQPreview";

export const CSQPreview: FC<{
  question: CSQuestion2,
}> = ({question}) => {
  const isMobile = useBreakpointValue({base: true, sm: true, md: false})
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    // <VStack alignItems={'flex-start'} w={'full'}>

    <Flex flexDirection={isMobile?'column':'row'} justifyContent={'space-between'} onClick={()=>{setIsExpanded(!isExpanded)}}>
      {
        isExpanded
          ? <Text fontSize={'sm'}>{question.Body}</Text>
          : <Text noOfLines={2} flexGrow={3}>{question.Body.split('\n')[0]}</Text>
      }


      <QuestionPreviewTag color={'blue'} type={'CSQ'}/>
    </Flex>

    // </VStack>
  )
}