import React, {FC} from "react";
import {Box, useColorModeValue} from "@chakra-ui/react";
import {Redirect, Route, Switch} from "react-router-dom";
import {PaperPage} from "./Components/Pages/PaperPage";
import {TopicInfoView} from "./Components/ProblemSet/TopicInfoView";
import {QuestionSetReviewLoader} from "./Components/Pages/QuestionSetReviewView";
import {DoProblemSetPage} from "./Components/Pages/DoProblemSetPage";
import {ProblemSetInfoView} from "./Components/ProblemSet/ProblemSetInfoView";
import {SavedQuestionsPage} from "./Components/Pages/SavedQuestionsPage";
import {PricingPage} from "./Components/Pages/PricingPage";
import {Login} from "./Components/Auth/Login";
import {ProfilePage} from "./Components/Pages/ProfilePage";
import {LandingPage} from "./Components/Landing/LandingPage";
import {PrivacyPolicy} from "./Components/Pages/PrivacyPolicy";
import {TOS} from "./Components/Pages/TOS";
import {AdminPanel} from "./Components/Pages/AdminPanel";
import {NewFeaturesPage} from "./Components/Pages/NewFeaturesPage";
import {QuestionManager} from "./Components/QuestionManager/QuestionManager";
import {AffiliatePage} from "./Components/Pages/AffiliatePage";
import {BlogPage} from "./Components/Pages/BlogPage";
import {QuestionPage} from "./Components/Pages/QuestionPage";
import {useAuth} from "./Contexts/AuthContext";
import {DiscussionPage} from "./Components/Pages/DiscussionPage";
import {UnsubPromoPage} from "./Components/Pages/UnsubPromoPage";
import {UpdatedQns} from "./Components/Pages/UpdatedQns";

export const MainBox: FC = ({}) => {
  const auth = useAuth()
  const bgColor = useColorModeValue('white', 'gray.900')
  return (
    <Box
      minH={'90vh'}
      bg={bgColor}
    >
      <Switch>
        <Route path="/paper/:paperID/updated" component={UpdatedQns}/>
        <Route path="/paper/:paperID" component={PaperPage}/>
        <Route path="/topic/:topicID" component={TopicInfoView}/>
        <Route path="/unsub-promo" component={UnsubPromoPage}/>
        <Route path="/set/:setID/review" component={QuestionSetReviewLoader}/>
        <Route path="/set/:setID/do" component={DoProblemSetPage}/>
        <Route path="/set/:setID" component={ProblemSetInfoView}/>
        <Route path="/saved" component={SavedQuestionsPage}/>
        <Route path="/adadminmin" component={AdminPanel}/>
        <Route path="/question-manager" component={QuestionManager}/>
        {/*<Route path="/set" component={TopicPickerView}/>*/}
        <Route path="/pricing" component={PricingPage}/>
        <Route path="/privacy" component={PrivacyPolicy}/>
        <Route path="/tos" component={TOS}/>
        <Route path="/login" component={Login}/>
        <Route path="/profile" component={ProfilePage}/>
        <Route path="/updates" component={NewFeaturesPage}/>
        <Route path="/affiliate" component={AffiliatePage}/>
        <Route path="/blog" component={BlogPage}/>
        <Route path="/discuss" component={DiscussionPage}/>
        <Route path="/question/:questionID" component={QuestionPage}/>
        <Route path="" component={LandingPage}/>
      </Switch>

    </Box>
  )
}


// @ts-ignore
function PrivateRoute({children, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={
        ({location}) => (
          isAuthenticated
            ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login?redirect='+rest.location.pathname,
                }}
              />
            ))
      }
    />
  );
}