import dayjs from "dayjs";

export interface UserInfo {
  ID: string
  Email: string
  DisplayName: string
  SubscriptionInfo?: UserSubscriptionInfo
  IsAffiliate: boolean
  ReferralID?: string
  CommsRate?: number
  CustDiscRate?: number
  TenantAdmin?: TenantAdmin[]
  Rep: number
}

interface TenantAdmin {
  TenantID: string
  Role: string // member, moderator, admin
}

export const ShouldShowAffiliateProgram = (info?: UserSubscriptionInfo) => {
  return info?.LatestChargeID !== '' &&
    dayjs(GetLatestChargeHistory(info)?.SucceededAt).add(3, 'days').isBefore(dayjs()) &&
    dayjs(GetLatestChargeHistory(info)?.SucceededAt).isAfter(dayjs('2020', 'YYYY'))
}

export interface UserSubscriptionInfo {
  TrialUsed: boolean
  LatestChargeID: string
  Perm: number
  History: ChargeHistory[]

}

export const GetLatestChargeHistory = (info?: UserSubscriptionInfo): ChargeHistory | undefined => {
  return info?.History.find(x => x.ID === info.LatestChargeID)
}

// returns trial, captured, canceled, or none
export const SubscriptionStatus = (info: UserSubscriptionInfo) => {
  const ch = GetLatestChargeHistory(info)
  if (ch?.Status === 'captured') {
    return 'paid'
  }
  return ch?.Status ?? 'none'
}

export interface ChargeHistory {
  ID: string
  UserID: string
  CreatedAt: string
  UpdatedAt: string
  SucceededAt: string
  TransferredAt: string
  TrialHours: number
  Status: string
  SubscriptionMonths: number
  Perm: number

}