import React, {FC, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {useMutation} from "react-query";
import {UserFeedback} from "../../Models/Anal";
import {ApiClient} from "../../Services/APIClient";


export const QuestionFeedbackButton: FC<{ qID: string }> = ({qID}) => {
  const hist = useHistory()
  const toast = useToast()
  const {onOpen, onClose, isOpen} = useDisclosure()
  const sendFeedbackMutation = useMutation((feedback: UserFeedback) => {
      return ApiClient.post('/api/anal/user/feedback', feedback)
    },
    {
      onSuccess: () => {

        toast({
          position: "top",
          title: "Feedback Received",
          description: "Thank you for your feedback!",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          position: "top",
          title: "Feedback Failed",
          description: "An error has occurred ): Thanks for trying though.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    })

  const handleSend = ({target}: any) => {
    sendFeedbackMutation.mutate(
      {
        Type: feedbackType,
        CurrentURL: hist.location.pathname,
        Browser: '',
        ProblemSetID: '',
        QuestionID: qID,
        Body: feedbackBody
      }
    )
  }
  const handleClick = (type: string, prompt: string) => {
    return () => {
      setFeedbackPrompt(prompt)
      setFeedbackType(type)
      onOpen()

    }

  }

  const throwRedirectionMsgToDiscussion = (hint: string) => {
    toast({
      position: "top",
      title: "Use Discussion!",
      description: hint,
      status: "warning",
      duration: 5000,
      isClosable: true,
    })
  }

  const [feedbackType, setFeedbackType] = useState('')
  const [feedbackPrompt, setFeedbackPrompt] = useState('')
  const [feedbackBody, setFeedbackBody] = useState('')
  return (
    <>
      <Menu isLazy>
        <MenuButton
          as={Button}

        >Feedback</MenuButton>
        <MenuList>
          {/* MenuItems are not rendered unless Menu is open */}
          {/*<MenuItem*/}
          {/*  onClick={*/}
          {/*    handleClick(*/}
          {/*      'QnGoodQuestion',*/}
          {/*      'Thank you! 🤗 What is good about this question?')}>*/}
          {/*  Good question*/}
          {/*</MenuItem>*/}
          <MenuItem
            onClick={
              handleClick(
                'QnDeadLink',
                'Optional: Can you help us by suggesting a new link?')}>
            There are dead links
          </MenuItem>
          <MenuItem
            onClick={
              handleClick(
                'QnGrammarSpellingError',
                'Where is the error? And what should it be?')}>
            There is a grammatical or spelling error
          </MenuItem>
          <MenuItem
            onClick={()=>{throwRedirectionMsgToDiscussion("Start a new discussion! Include as much detail as possible so that others can better help you!")}}>
            Request for better explanation
          </MenuItem>
          <MenuItem
            onClick={
              handleClick(
                'QnExplanation',
                'You think you can explain this better? Do let us know how! Thank you!')}>
            Suggest a better explanation
          </MenuItem>

          <MenuItem
            onClick={
              handleClick(
                'QnOutdated',
                'Which part is outdated? What is the latest information? What is your source?')}>
            Question is outdated
          </MenuItem>
          <MenuItem
            onClick={()=>{throwRedirectionMsgToDiscussion("Start a new discussion! Detail down what is wrong and what you think the right answer is! Include sources.")}}>
            Answer is wrong
          </MenuItem>
          <MenuItem
            onClick={
              handleClick(
                'QnOther',
                'What are you suggesting? 🧐')}>
            Other
          </MenuItem>


        </MenuList>
      </Menu>

      <FeedbackModalForm isOpen={isOpen} onClose={onClose} feedbackBody={feedbackBody}
                         feedbackPrompt={feedbackPrompt}
                         setFeedbackBody={setFeedbackBody} handleSubmit={handleSend}/>
    </>
  )
}

const FeedbackModalForm: FC<{
  isOpen: boolean,
  onClose: () => void,
  setFeedbackBody: React.Dispatch<React.SetStateAction<string>>,
  feedbackPrompt: string,
  feedbackBody: string,
  handleSubmit: (arg0: any) => void
}> = ({isOpen, onClose, setFeedbackBody, feedbackBody, feedbackPrompt, handleSubmit}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Question Feedback</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text mb={'20px'}>{feedbackPrompt}</Text>
          <Textarea
            onChange={event => {
              setFeedbackBody(event.target.value.slice(0, 255))
            }
            }
            value={feedbackBody}
          />
          <Text color={'gray.500'}>Max characters: 255</Text>
        </ModalBody>
        <ModalFooter>
          <Button value={'QnOther'} onClick={
            event => {
              handleSubmit(event)
              onClose()
            }


          }>Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}