import {FC, useState} from "react";
import {useQuery} from "react-query";
import {QnMgrService} from "../../Services/QnMgrService";
import {Box, Button, Text} from "@chakra-ui/react";
import {Topic} from "../../Models/Question";

export const ProblemSetSelector: FC<{
  selectedPaper: undefined | string,
}> = ({selectedPaper}) => {
  let [hideGenerated, setHideGenerated] = useState(false)

  const papersQuery = useQuery(["tenant", "papers", selectedPaper, hideGenerated],
    QnMgrService.GetPaperData("propella", selectedPaper ?? "", hideGenerated), {
      enabled: !!selectedPaper && selectedPaper !== "$unassigned$"
    })

  return (

    <Box
      h={'full'}
      overflowY={'scroll'}
      flexShrink={0}
    >
      <Button
        size={'xs'}
        colorScheme={hideGenerated ? 'blue' : undefined}
        onClick={() => {
          setHideGenerated(!hideGenerated)
        }}>
        Hide Generated
      </Button>
      {
        papersQuery.data?.Topics.map(topic => {
          return <TopicBox key={topic.ID} topic={topic}/>
        })
      }
      <TopicBox key={'$unassigned$'}
                topic={{ID: "$unassigned$",Name: "unassigned", Paper:0, ProblemSets: papersQuery.data?.ProblemSets}}/>
    </Box>)
}

const TopicBox:FC<{topic: Topic}> = ({topic}) => {
  let [isExpanded, setIsExpanded] = useState(false)
 return (<Box>
   <Text fontWeight={700} onClick={()=>{setIsExpanded(!isExpanded)}}>{`${topic.Name} (${topic.ProblemSets?.length})`}</Text>
   { isExpanded &&
     topic.ProblemSets?.map(pSet => {
         return <Box key={pSet.ID}>
           <Text pl={'40px'} pr={'10px'}>{pSet.Title}</Text>


         </Box>
       }
     )
   }
 </Box>)
}