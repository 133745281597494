import React, {FC, ReactElement} from "react";
import {
  Container,
  Flex,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {
  BiRecycle,
  CgDarkMode,
  FaChalkboardTeacher,
  IoInfiniteSharp,
  IoMedalOutline,
  IoPhonePortraitOutline, IoRibbonOutline, IoSparklesOutline, IoStarOutline, IoStatsChart,
  MdOutlineTipsAndUpdates, RiQuestionAnswerLine
} from "react-icons/all";

export const Features: FC = () => {
  const {colorMode, toggleColorMode} = useColorMode()
  return (
    // <Box
    //   pt={{base: '10px', md: '80px'}}
    //   pb={'80px'}
    //   bgGradient="linear(180deg, rgba(255, 255, 255, 0), rgba(15, 40, 232, 0.1))"
    //
    // >
    <Container maxW={'container.xl'} px={'20px'}>
      <SimpleGrid columns={{base: 1, md: 3}} spacing={10}>
        <Feature
          icon={<Icon as={IoMedalOutline} w={10} h={10}/>}
          title={'1500+ Questions'}
          text={
            'Work through MCQs and case study questions (CSQs) and mark your answers automatically.'
          }
        />
        <Feature
          icon={<Icon as={FaChalkboardTeacher} w={10} h={10}/>}
          title={'Explanations'}
          text={
            'The more difficult questions (>55%) come with explanations to aid you in your understanding.'
          }
        />
        <Feature
          icon={<Icon as={IoInfiniteSharp} w={10} h={10}/>}
          title={'Unlimited Attempts'}
          text={
            'Work through problem sets as many times as you need and track your progress.'
          }
        />
        <Feature
          icon={<Icon as={MdOutlineTipsAndUpdates} w={10} h={10}/>}
          title={'Latest Curriculum'}
          text={
            'Since Propella is online, our questions are always updated and in line with the latest syllabus.'
          }
        />
        <Feature
          icon={<Icon as={IoSparklesOutline} w={10} h={10}/>}
          title={'Dynamic Mock Tests'}
          text={
            'Generate new problems sets with questions from our large question bank.'
          }
        />
        <Feature
          icon={<Icon as={IoRibbonOutline} w={10} h={10}/>}
          title={'Full Mock Papers'}
          text={
            'Practice with never-before-seen questions right before your exams to prepare effectively.'
          }
        />
        <Feature
          icon={<Icon as={IoPhonePortraitOutline} w={10} h={10}/>}
          title={'Mobile Screen Friendly'}
          text={
            'Knock out a few questions or a whole problem set on your train ride home, or in bed before you go to sleep.'
          }
        />
        <Feature
          icon={<Icon as={CgDarkMode} w={10} h={10}/>}
          title={'Dark Mode'}
          onClick={() => toggleColorMode()}
          text={
            'Protect your eyes when you are propelling your skills right before bedtime with dark mode.'
          }
        />
        <Feature
          icon={<Icon as={IoStarOutline} w={10} h={10}/>}
          title={'Bookmark'}
          text={
            'Save questions that you like and easily refer to them again during your revision.'
          }
        />
        <Feature
          icon={<Icon as={IoStatsChart} w={10} h={10}/>}
          title={'Question Statistics'}
          text={
            'Unsure if our answers are correct? Open up the stats panel to see how others have answered.'
          }
        />
        <Feature
          icon={<Icon as={RiQuestionAnswerLine} w={10} h={10}/>}
          title={'Discuss'}
          text={
            'Join the members-only discussion board and discuss your problems to clear all doubts.'
          }
        />
        <Feature
          icon={<Icon as={BiRecycle} w={10} h={10}/>}
          title={'Transferable Subscriptions'}
          text={
            'Subscribe and use for as long as you need, then pass it on when you\'re done.'
          }
        />

      </SimpleGrid>
    </Container>
    // </Box>

  )
}

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
  onClick?: () => void;
  isNew?: boolean;
}

const Feature = ({title, text, icon, onClick, isNew}: FeatureProps) => {
  const bodyColor = useColorModeValue("gray.700", "gray.400")


  return (
    <Stack alignItems={'flex-start'}>
      <Flex
        w={10}
        h={10}
        align={'center'}
        justify={'center'}
        color={useColorModeValue('gray.700', 'gray.100')}
        rounded={'full'}
        mb={1}

      >
        {icon}
      </Flex>

      <Text
        fontWeight={600}
        onClick={onClick}
        _hover={{
          'cursor': 'pointer'
        }}
      >{title}</Text>
      {
        !!isNew && <Tag variant={'outline'} colorScheme={'brand'}>NEW</Tag>
      }

      <Text color={bodyColor}>{text}</Text>
    </Stack>
  );
};
